import { Component, OnInit  } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StudentService } from '../../shared/services/student.service';
import { Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../shared/services/master.service';
import { environment } from '../../../environments/environment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-studentexportconsolidatedreportcard',
  templateUrl: './studentexportconsolidatedreportcard.component.html',
  styleUrls: ['./studentexportconsolidatedreportcard.component.scss'],
  providers: [DateFormatPipe, DatePipe]
})
export class StudentExportConsolidatedReportCardComponent implements OnInit {
  public userclassList: any = [];
  public usersectionList: any = [];
  public usersubjectList: any[] = [];

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public studentListForm: FormGroup;
  public p: number = 1; 
  public message: string = null;
  // public stPhotoURL: string;
  xlsxData : any = [];
  constructor(private router: Router, private stService: StudentService, private masterService: MasterService,
    private formBuilder: FormBuilder, private toast: ToastrService) {
    this.stService.getMasterList();
  }
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  ngOnInit() {
    
    this.studentListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: ['']
    }); 
   
    this.stService.masterList.subscribe(m => { this.staticList = m; }) 
    // this.loadStudent();
    // this.stPhotoURL= environment.appUrl;
  }


  loadStudent1and2() {
    //this.stService.getStudentList(this.studentListForm.controls['stClass'].value, 
    this.stService.GetConsolidatedReportCardExport1and2(this.studentListForm.controls['stClass'].value, 
    this.studentListForm.controls['stSection'].value,this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
        if (m && m.length > 0) {
          this.studentListForm.controls['stSession'].setValue(m[0].stSession);
        }
      });
      // this.exportAsXLSX()
  }
  loadStudent3To9() {
    //this.stService.getStudentList(this.studentListForm.controls['stClass'].value, 
    this.stService.GetConsolidatedReportCardExport3To9(this.studentListForm.controls['stClass'].value, 
    this.studentListForm.controls['stSection'].value,this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
        if (m && m.length > 0) {
          this.studentListForm.controls['stSession'].setValue(m[0].stSession);
        }
      });
     // this.exportAsXLSX()
  }

  loadStudent10() {
    //this.stService.getStudentList(this.studentListForm.controls['stClass'].value, 
    this.stService.GetConsolidatedReportCardExport10(this.studentListForm.controls['stClass'].value, 
    this.studentListForm.controls['stSection'].value,this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
        if (m && m.length > 0) {
          this.studentListForm.controls['stSession'].setValue(m[0].stSession);
        }
      });
    //  this.exportAsXLSX()
  }

  loadStudent11() {
    //this.stService.getStudentList(this.studentListForm.controls['stClass'].value, 
    this.stService.GetConsolidatedReportCardExport11(this.studentListForm.controls['stClass'].value, 
    this.studentListForm.controls['stSection'].value,this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
        if (m && m.length > 0) {
          this.studentListForm.controls['stSession'].setValue(m[0].stSession);
        }
      });
    //  this.exportAsXLSX()
  }

  loadStudent12() {
    //this.stService.getStudentList(this.studentListForm.controls['stClass'].value, 
    this.stService.GetConsolidatedReportCardExport12(this.studentListForm.controls['stClass'].value, 
    this.studentListForm.controls['stSection'].value,this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
        if (m && m.length > 0) {
          this.studentListForm.controls['stSession'].setValue(m[0].stSession);
        }
      });
     // this.exportAsXLSX()
  }

  loadclass() {
    this.masterService.getUserWiseClassList().subscribe(lst => {this.userclassList = lst; });
  }
  loadsection() {
    this.masterService.getUserWiseSectionList().subscribe(lst => {this.usersectionList = lst; });
  }

  loadsubject() {
    this.masterService.getUserWiseSubjectList().subscribe(lst => {this.usersubjectList = lst; });
  }


  searchStudent() {
    if (this.studentListForm.controls['stClass'].value.length == 0) {
      this.toast.error("Please select class then section");

    } else {
      if (this.studentListForm.controls['stClass'].value && this.studentListForm.controls['stClass'].value.length > 0) {
        if (this.studentListForm.controls['stSection'].value.length > 0) {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value
            );
          this.orgList = this.stList;
        } else {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value);
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  } 
 
  exportExcel(jason: any[], excelfilename:string ): void{    
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jason);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, 'CONSOLIDATED_REPORT_CARD');
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  exportAsXLSX():void {
    this.exportExcel(this.stList, 'CONSOLIDATED_REPORT_CARD');
  }
}
