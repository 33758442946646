
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { first } from 'rxjs/operators';
import { User } from '../shared/model/user.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'dpskaluahiregistrationlogin.component.html'
})
export class DPSKaluahiRegistrationLoginComponent implements OnInit {
    dpskaluahiregistrationloginForm: FormGroup;
    showError: Boolean = false;
    errorMsg: string = null;
    returnUrl: string;
    returnUrlregistration: string;    
    public role: string;
    user: string = null;
    pass: string = null;
    constructor(public router: Router, private formBuilder: FormBuilder,
        private loginService: LoginService, private route: ActivatedRoute,private toast:ToastrService
    ) { }

    ngOnInit() {
        this.dpskaluahiregistrationloginForm = this.formBuilder.group({
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardregistration';

    }
    onSubmit() {
        if (this.dpskaluahiregistrationloginForm.invalid) {
            return;
        }
            this.user ="dpskaluahionlineregistration"
            this.pass = "847408"

        // this.loginService.login(this.dpskaluahiregistrationloginForm.controls.email.value, 
        //     this.dpskaluahiregistrationloginForm.controls.password.value)
            this.loginService.login(this.user, this.pass)    

        .pipe(first())
            .subscribe(
                (data: User) => {
                    if (data.access_token) {
                        localStorage.setItem('isLoggedin', 'true');
                        localStorage.setItem('authToken', data.access_token);

                        this.role = this.loginService.currentUserValue.roles;
                        if (this.role === "OnlineRegistration") {
                            this.router.navigate(['/onlineregistration']);
                        }
                    } else {
                        this.toast.error('Registration Is Over',"Error");
                    }
                },
                error => {
                    this.toast.error('Registration Is Over',"Error");
                });
    }
}
