import {Component, OnInit} from '@angular/core';
import { navItems } from '../../_nav';
import { NavbarService, LoginService } from '../../shared/services';

import { GroupByPipe } from '../../shared/pipes/group.pipe';
import { INavData } from '@coreui/angular';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent   {
  public sidebarMinimized = false;
  public navItems:INavData[] ;
  public userName:string;
  public logo={};
  public slogo={};
  public schoolName:string; 
  public schoolAddress: string;
  
  public dashboardlink: string;   
  public role: string;

  constructor(  private navService: NavbarService,private loginService:LoginService) {
      this.navService.getNavItems().subscribe(m=>{
      let navData  = new GroupByPipe().transform(m,"ParentName");
      this.navItems=navData.map(element => {
        let navItm:INavData ={};
        navItm.name = element.key;
        navItm.icon = "icon-speedometer";
        navItm.url =  '/'+element.key;
        navItm.children =[];
        element.value.map(ch => {
          let chitm:INavData={};
          chitm.name = ch.MenuName;
          chitm.icon = "icon-pencil";
          chitm.url = ch.PageURL;
         // chitm.class ="space8"
          navItm.children.push(chitm);
        });
        return navItm;
      });
      //this.navItems= navItms;
    })
  }
  ngOnInit() {
    this.userName = this.loginService.currentUserSubject.value.userName;

    this.role = this.loginService.currentUserValue.roles;
    if (this.role === 'Registration') {
        this.dashboardlink = '/dashboardregistration';
    } else if (this.role === 'ClassTeacher' || this.role === 'ClassTeacher1To2' || this.role === 'ClassTeacher3To10' || this.role === 'ClassTeacher11To12' || this.role === 'ExamIncharge' || this.role === 'ExamIncharge1') {
        this.dashboardlink = '/dashboardclassteacher';
    } else if (this.role === 'Teacher' || this.role === 'Teacher1To2' || this.role === 'Teacher3To10' || this.role === 'Teacher11To12') {
      this.dashboardlink = '/dashboardteacher';
    } else if (this.role === 'Student') {
      this.dashboardlink = '/dashboardstudent';
    } else {
        this.dashboardlink = '/dashboard';
    }

    // this.logo = environment.appUrl + this.loginService.currentUserSubject.value.logoPath;
    this.logo = {src: environment.appUrl + this.loginService.currentUserSubject.value.logoPath , width: 60, height: 60, alt: 'School Logo'};
    this.slogo = {src: environment.appUrl + this.loginService.currentUserSubject.value.logoPath , width: 30, height: 30, alt: 'School Logo'};
    this.schoolName = this.loginService.currentUserSubject.value.SchoolName;
    this.schoolAddress = this.loginService.currentUserSubject.value.SchoolAddress;
}
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logOut() {
    this.loginService.logout();
  }
}
