import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend , HttpErrorResponse} from '@angular/common/http';
import { Observable, BehaviorSubject , throwError} from 'rxjs';
import { User } from '../model/user.model';
import { Router } from '@angular/router';
import { map , catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Location } from '@angular/common';
// import { MasterService } from './master.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })

};
const apiUrl = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public redirectUrl:string;
  public refreshInprogress:boolean = true;
  constructor(private httpClient: HttpClient, private router: Router,private location: Location,
     public handle: HttpBackend, public spinService: SpinnerVisibilityService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public login = function (usid: string, pwd: string): Observable<User> {
    const body = 'userName=' + usid + '&password=' + pwd + '&grant_type=password';
    return this.httpClient.post(apiUrl + '/token', body, httpOptions)
      .pipe(map((user: User) => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  };

  public refreshToken = function() {
    if(this.refreshInprogress){
      this.refreshInprogress =false;
      const cuser: User = this.currentUserSubject.value;
      const body = 'refresh_token=' + cuser.refresh_token +  '&grant_type=refresh_token';
      const httpClnt = new HttpClient(this.handle);
      httpClnt.post(apiUrl + '/token', body, httpOptions)
      .subscribe(
        (user: User) => { if (user && user.access_token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.refreshInprogress =true;
          this.currentUserSubject.next(user);
          localStorage.setItem('authToken', user.access_token);
          this.spinService.hide();
          window.location.reload();
        }},
        error => {
          this.currentUser = null;
          this.refreshInprogress =true;

          alert('Session Timeout. Please login again.');
          this.spinService.hide();
          this.logout();
          
        }
      );
      
    }
  };
  public logout = function () {
    this.currentUser = null;
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('authToken');
    localStorage.removeItem('currentUser');
           //  this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  };
  // public getschoolList = function( ) {
  //   return this.httpClient.get(apiUrl + '/api/registration/GetSchoolList');
  // };

  forgotpassword(UserId) {
    return this.httpClient.post(apiUrl + '/api/User/ValidateUserforforgotpassword?usid=' + UserId , httpOptions );
  }

  studentforgotpassword(UserId) {
    return this.httpClient.post(apiUrl + '/api/User/ValidateStudentUserforforgotpassword?usid=' + UserId , httpOptions );
  }
}
