import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'app-registrationlistpointwise',
  templateUrl: './registrationlistpointwise.component.html',
  styleUrls: ['./registrationlistpointwise.component.scss'],
  
})
export class RegistrationlistPointWiseComponent implements OnInit {
  // @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  // title = 'Excel';
  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public UserId:number;
  public message: string = null;
  public registrationlistpointwiseListForm: FormGroup;
  public p:number =1;
  masterSelected: boolean;
  checkedList: any;
  public IsAllChecked: boolean = false;

  constructor(private router: Router, private stService: StudentService,
      private formBuilder: FormBuilder,private toast:ToastrService) {
        this.stService.getMasterList();
        this.UserId = JSON.parse(localStorage.getItem('currentUser'))["userId"];
      }
    
  ngOnInit() {
    this.registrationlistpointwiseListForm = this.formBuilder.group({
      stClass: [''],
      // stSection: [''],
      stSession: ['']
    });
    
    
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
   // this.loadStudent(true);
  }

  loadStudent(chk) {
    const data = { stSession: this.registrationlistpointwiseListForm.controls['stSession'].value?
                    this.registrationlistpointwiseListForm.controls['stSession'].value : 0,

                    stClass: this.registrationlistpointwiseListForm.controls['stClass'].value?
                  this.registrationlistpointwiseListForm.controls['stClass'].value : 0,

                  // stSection: this.registrationlistpointwiseListForm.controls['stSection'].value?
                  // this.registrationlistpointwiseListForm.controls['stSection'].value : 0,

                  UserId: this.UserId

                  };
    this.stService.getregistartionpointwiseList(data.stClass, data.stSession, data.UserId).
          subscribe( m => {
            this.stList = m;
            this.stOrgList = m;
            // this.p = 1;
            // if (chk && m && m.length > 0 ) {
            //   this.registrationlistpointwiseListForm.controls['stSession'].setValue(m[0].stSession);
            // }
          });
  }
  searchStudent() {
    // if(this.registrationlistpointwiseListForm.controls['stSection'].value .length > 0 &&
    // this.registrationlistpointwiseListForm.controls['stClass'].value.length === 0 ){
      if(this.registrationlistpointwiseListForm.controls['stClass'].value.length === 0 ){
        this.toast.error("Please select class");

    } else {
      if (this.registrationlistpointwiseListForm.controls['stClass'].value &&
      this.registrationlistpointwiseListForm.controls['stClass'].value .length > 0 ) {
        // if (this.registrationlistpointwiseListForm.controls['stSection'].value &&
        //     this.registrationlistpointwiseListForm.controls['stSection'].value .length > 0)  {
        //   if (this.registrationlistpointwiseListForm.controls['stSection'].value &&
        //   this.registrationlistpointwiseListForm.controls['stSection'].value .length > 0)  { 
        // this.stList = this.stOrgList.filter(m => m.stClass == this.registrationlistpointwiseListForm.controls['stClass'].value
        //                     && m.stSection == this.registrationlistpointwiseListForm.controls['stSection'].value );
        //       this.orgList = this.stList;
        // } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.registrationlistpointwiseListForm.controls['stClass'].value );
        // }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported'+ EXCEL_EXTENSION);
  }
  exportExcel(){
    
    this.exportAsExcelFile(this.stList, 'StudentPointWiseList');
  }
 
}
