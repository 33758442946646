import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UpdateFeeReceipt } from '../../shared/model/UpdateFeeReceipt.model';
import { UpdateFeeReceiptStatus } from '../../shared/model/UpdateFeeReceiptStatus.model';
import { StudentService } from '../../shared/services/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { MasterService } from '../../shared/services/master.service';
import { NgWizardService, NgWizardConfig, THEME, StepChangedArgs, TOOLBAR_BUTTON_POSITION, TOOLBAR_POSITION } from 'ng-wizard';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../shared/services/login.service';
import { User } from '../../shared/model/user.model';
import { FeeReceiptService } from '../../shared/services/feereceipt.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-fee-receipt-status',
  templateUrl: './fee-receipt-status.component.html',
  styleUrls: ['./fee-receipt-status.component.scss'],
  providers: [DateFormatPipe, DatePipe, UpdateFeeReceipt ]
})
export class FeeReceiptStatusComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public feeReceiptForm: FormGroup;
  public p: number = 1;
  public toDate: any;
  public fromDate: any;
  public paymentMode: any;
  public ClassId: any;
  public SessionId: any;
  public SectionId: any;
  public message: string = null;
  public stSection: any;
  public stClass: any;
  public PaymentMode: any;
  public UpdateModel: UpdateFeeReceipt;
  public FeModel: UpdateFeeReceiptStatus;
  public FeeList: any[];
  public Roles: any;
  public RoleId = 0;
  public domain: string = null;
  public userId:number;
  masterSelected: boolean;

  public FeeReceiptList= [({
  cs_csname: '',
  fr_Sessionid: 0,
  fr_Totpaidamt: 0,
  fr_active: false,
  fr_chqbounced: false,
  fr_chqcleared: false,
  fr_chqtranno: '',
  fr_frid: 0,
  fr_modeofpayment: '',
  fr_rctno: '',
  fr_recdt: '',
  fr_rmrks: '',
  fr_type: '',
  sd_sdname: '',
  su_oldsuid: '',
  su_suid: 0,
  su_suname: '',
  sud_rollno: 0,
  IsChecked: false 
})]

  constructor(private router: Router, private stService: StudentService, private feereceiptService: FeeReceiptService,
    private formBuilder: FormBuilder, private toast: ToastrService, private datePipe: DatePipe, private loginService: LoginService,
    @Inject(DOCUMENT) private document: any) {
    this.UpdateModel = new UpdateFeeReceipt();
    this.UpdateModel.UpdateFeeReceiptStatus = [];
    this.FeModel = new UpdateFeeReceiptStatus();
    this.userId = (<User>this.loginService.currentUserSubject.value ).userId;
    this.stService.getMasterList();
    this.loginService.currentUserSubject.subscribe(m => {
      debugger;
      this.Roles = m;
      if (this.Roles.roleid == 2 || this.Roles.roleid == 1) {
        this.RoleId = 1;
      }
      console.log(this.RoleId);
    })

  }

  ngOnInit() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const startyear = Number(this.datePipe.transform(firstDay, 'yyyy'));
    const startMonth = Number(this.datePipe.transform(firstDay, 'MM'));
    const startDay = Number(this.datePipe.transform(firstDay, 'dd'));
    const endYear = Number(this.datePipe.transform(lastDay, 'yyyy'));
    const endMonth = Number(this.datePipe.transform(lastDay, 'MM'));
    const endDay = Number(this.datePipe.transform(lastDay, 'dd'));
    this.feeReceiptForm = this.formBuilder.group({
      toDate: [{ year: endYear, month: endMonth, day: endDay }],
      fromDate: [{ year: startyear, month: startMonth, day: startDay }],
      SessionId:[''],
      SectionId: [''],
      stSection: [''],
      stClass: [''],
      PaymentMode: [''],
   
    });

    this.stService.masterList.subscribe(m => {
      this.staticList = m;
      console.log(m);
    })
  
  }

  loadFeeReceiptList() {
    this.stList = [];
    this.FeeReceiptList = [];
    this.FeeList = [];
    
    var fromDate = this.feeReceiptForm.controls['fromDate'].value;
    var toDate = this.feeReceiptForm.controls['toDate'].value;
    var startDate = fromDate.year + '/' + fromDate.month + '/' + fromDate.day;
    var endDate = toDate.year + '/' + toDate.month + '/' + toDate.day;
    this.stService.getStudentFeeReceiptList(startDate,endDate,this.feeReceiptForm.controls['SessionId'].value).
      subscribe(m => {      
        this.FeeList = m;
        for (let data of m) {
          this.FeeReceiptList.push({
            cs_csname: data.cs_csname,
            fr_Sessionid: data.fr_Sessionid,
            fr_Totpaidamt: data.fr_Totpaidamt,
            fr_active: data.fr_active,
            fr_chqbounced: data.fr_chqbounced,
            fr_chqcleared: data.fr_chqcleared,
            fr_chqtranno: data.fr_chqtranno,
            fr_frid: data.fr_frid,
            fr_modeofpayment: data.fr_modeofpayment,
            fr_rctno: data.fr_rctno,
            fr_recdt: data.fr_recdt,
            fr_rmrks: data.fr_rmrks,
            fr_type: data.fr_type,
            sd_sdname: data.sd_sdname,
            su_oldsuid: data.su_oldsuid,
            su_suid: data.su_suid,
            su_suname: data.su_suname,
            sud_rollno: data.sud_rollno,
            IsChecked: data.fr_active == true ? true : data.fr_chqbounced == true ? true: data.fr_chqcleared ==true?true:false      
          });
        }
       // this.FeeReceiptList.shift();        
        this.stList = this.FeeReceiptList;
        console.log(this.FeeReceiptList);
      });
  //  [disabled] = "(( RoleId!=1) && (itm.fr_chqbounced||itm.fr_chqcleared||itm.fr_active))"
  }
  checkBouncedBox(itm, i, value) {    
     if (itm.fr_active  || itm.fr_chqcleared) { 
     itm.fr_active = false;
     itm.fr_chqcleared = false;

    }    
    itm.IsChecked = false;
    itm.fr_chqbounced = value.target.checked;
    itm.fr_active = false;
    this.stList[i] = itm;   

  }
  checkCancelBox(itm, i, value) {
    if (itm.fr_chqbounced || itm.fr_chqcleared) {
      itm.fr_chqbounced = false;
      itm.fr_chqcleared = false;
    }
    
    itm.fr_active = value.target.checked;
    itm.IsChecked = false;
    this.stList[i] = itm;

  }
 
  checkClearedBox(itm, i, value) {
    if (itm.fr_chqbounced || itm.fr_active) {
      itm.fr_chqbounced = false;
     // itm.fr_active = false;
    }
    itm.IsChecked = false;
    itm.fr_chqcleared = value.target.checked;
    this.stList[i] = itm;
  }
  searchByClass() {
    this.stList = this.FeeList;
    this.stList = this.stList.filter(obj => obj.sd_sdname == this.feeReceiptForm.controls['stClass'].value);

  }

  searchBySection() {
    this.stList = this.FeeList;
    this.stList = this.stList.filter(obj => obj.cs_csname == this.feeReceiptForm.controls['stSection'].value );

  }
  searchByPayment() {
    this.stList = this.FeeList;
    this.stList = this.stList.filter(obj => obj.fr_modeofpayment == this.feeReceiptForm.controls['PaymentMode'].value);

  }

  Save(itm) {
    this.FeModel.fr_Sessionid = itm.fr_Sessionid;
    this.FeModel.fr_active = itm.fr_active;
    this.FeModel.fr_chqbounced = itm.fr_chqbounced;
    this.FeModel.fr_chqcleared = itm.fr_chqcleared;
    this.FeModel.fr_frid = itm.fr_frid;
    this.FeModel.fr_rmrks = "";
    this.UpdateModel.UpdateFeeReceiptStatus.push(this.FeModel);  
    this.stService.updateFeeReceiptStatus(this.UpdateModel).subscribe(m => {
      this.toast.success(m.message, "Success");
      window.location.reload();
    });
  
  }
 PrintfeeReceipt(itm) {
  this.domain = this.document.location.hostname;  
  // for (let itm of this.stList) {
  //   if (itm.fr_active === true) {  
  //     let f= itm.fr_frid;
  //     let st = itm.su_oldsuid;
      this.feereceiptService.getFeeReceiptStatusPDF(itm.fr_frid, itm.su_oldsuid,
        this.feeReceiptForm.controls['SessionId'].value, this.domain, this.userId).subscribe((response: any) => {
          const pdfWindow = window.open("")
          pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
          encodeURI(response) + "'></iframe>");
           });
  //   }
  // }
 }
}

