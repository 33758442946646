import { Injectable } from '@angular/core';
import {LoginService} from './login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ItemType, ItemGroup, GSTGroup, HSNSAC, Item,AccountType, AcVoucherType,AcNarration,AccountGroup,Account } from '../../shared/model/index';
import { SharedDataService } from './shareddata.service';
import { User } from '../model/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvAccSetting } from '../model/invaccsetting.model';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})

};
const apiUrl = environment.appUrl;

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  public issuereceivetype = null;
  public suid = null;
  public vnid = null;
  public issuereceiveid=null;
  public poid=null;
  public schoolId: number;
  public currentUserId = (<User>this.loginService.currentUserSubject.value ).userId;
  
  constructor(private loginService: LoginService, private httpClient: HttpClient,
    public sharedData: SharedDataService) {
    this.loginService.currentUserSubject.subscribe(m=>{
      this.schoolId = m?m.schoolId:0;
    })
  }
  public getItemTypeList = function( ) {    
    return this.httpClient.get(apiUrl + '/api/Inventory/GetItemTypeList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageItemType = function (itemtype: ItemType) {
    itemtype.userId = this.loginService.currentUserSubject.value.userId;
    itemtype.schId=this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Inventory/ManageItemType', itemtype, httpOptions);
  
}
public getItemGroupList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemGroupList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public manageItemGroup = function (itemgroup: ItemGroup) {
  itemgroup.userId = this.loginService.currentUserSubject.value.userId;
  itemgroup.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/ManageItemGroup', itemgroup, httpOptions);

}
public getGSTGroupList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Inventory/GetGSTGroupList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public manageGSTGroup = function (gstgroup: GSTGroup) {
  gstgroup.userId = this.loginService.currentUserSubject.value.userId;
  gstgroup.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/ManageGSTGroup', gstgroup, httpOptions);

}
public getHSNSACList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Inventory/GetHSNSACList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public ManageHSNSAC = function (hsnsac: HSNSAC) {
  hsnsac.userId = this.loginService.currentUserSubject.value.userId;
  hsnsac.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/ManageHSNSAC', hsnsac, httpOptions);

}
public getItemList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public ManageItem = function (item: Item) {
  item.userId = this.loginService.currentUserSubject.value.userId;
  item.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/ManageItem', item, httpOptions);

}
// public GetItemOpening = function(enddate){
//   return this.httpClient.get(apiUrl + '/api/Inventory/GetItemOpeningStock?enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
// };
public GetItemStock = function(enddate,entrytype,externaldoc){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemStock?enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&externaldoc=' + externaldoc + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};
public GetItemStockItemJournal = function(enddate,entrytype,externaldoc){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemStock?enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&externaldoc=' + externaldoc + '&userid=' + this.currentUserId + '&mode=' + 2, httpOptions);
};
public GetItemStockItemJournalList = function(enddate,entrytype,externaldoc){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemStock?enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&externaldoc=' + externaldoc + '&userid=' + this.currentUserId + '&mode=' + 3, httpOptions);
};
// public SaveItemOpeningStock = function(ItemViewWithDetail: any) {
//   ItemViewWithDetail.userId = this.loginService.currentUserSubject.value.userId;
//   ItemViewWithDetail.SchoolId = this.schoolId;
//   return this.httpClient.post(apiUrl + '/api/Inventory/SaveItemOpeningStock', ItemViewWithDetail, httpOptions);
// };
public SaveItemStock = function(ItemViewWithDetail: any) {
  ItemViewWithDetail.UserId = this.loginService.currentUserSubject.value.userId;
  ItemViewWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/SaveItemStock', ItemViewWithDetail, httpOptions);
};
public SaveItemStockPO = function(ItemPOViewList: any) {
  ItemPOViewList.UserId = this.loginService.currentUserSubject.value.userId;
  ItemPOViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/SaveItemStockPO', ItemPOViewList, httpOptions);
};
public getInvAccSettingList = function(): Observable<any> {
  return this.httpClient.get(apiUrl + '/api/Inventory/GetInvAccSettingList?schid=' + this.schoolId.toString())
  .pipe(map((scList: any) => {
   this.sharedData.invaccsettingList = scList ;
   return scList;
  }));
};
public manageInvAccSetting = function (invaccsetting: InvAccSetting) {
  // school.userId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post(apiUrl + '/api/Inventory/AddInvAccSetting', invaccsetting, httpOptions);
};
public GetItemFavourite = function(stdid){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemFavourite?schId=' + this.schoolId.toString()+ '&stdid=' + stdid + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};

public SaveItemFavourite = function(ItemFavouriteViewWithDetail: any) {
  ItemFavouriteViewWithDetail.UserId = this.loginService.currentUserSubject.value.userId;
  ItemFavouriteViewWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/SaveItemFavourite', ItemFavouriteViewWithDetail, httpOptions);
};

public GetItemFavouriteForStudent = function(stdid){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemFavourite?schId=' + this.schoolId.toString()+ '&stdid=' + stdid + '&userid=' + this.currentUserId + '&mode=' + 2, httpOptions);
};

getPDF(domainName){
  return this.httpClient.post<Blob>(apiUrl + '/api/Inventory/PrintStudentIssueReceivePDF?qry='+ this.issuereceivetype +'&stId='+ this.suid 
  +'&schId='+ this.schoolId.toString() +'&domain='+domainName 
  +'&userid='+ this.currentUserId, {  responseType: 'blob' as 'json', httpOptions });
}

Mail(domainName){
  return this.httpClient.post(apiUrl + '/api/Inventory/GetItemIssueReceiveMailDetail?qry='+ this.issuereceivetype +'&issuereceiveid='+ this.issuereceiveid +'&stId='+ this.suid
  +'&schId='+ this.schoolId.toString() +'&userid='+ this.currentUserId + '&domain=' + domainName, httpOptions );
}

public getStudentItemIssueReceiptList = function (fromDate,toDate, sessionId) {
  return this.httpClient.get(apiUrl + '/api/inventory/GetStudentItemIssueReceiptList?schid=' + this.schoolId.toString() +
    '&stSession=' + sessionId + '&fromdate=' + fromDate + '&todate=' + toDate, httpOptions);
};
getPDFstatus(qry,domainName){
  return this.httpClient.post<Blob>(apiUrl + '/api/Inventory/PrintStudentIssueReceiveStatusPDF?qry='+ qry +'&stId='+ this.suid 
  +'&schId='+ this.schoolId.toString() +'&domain='+domainName 
  +'&userid='+ this.currentUserId, {  responseType: 'blob' as 'json', httpOptions });
}


public getAccountTypeList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountTypeList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public getAccountTypeListForBank = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountTypeList?schId=' + this.schoolId.toString()+'&mode='+3, httpOptions);
};
public getAccountTypeListForBankVoucher = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountTypeList?schId=' + this.schoolId.toString()+'&mode='+2, httpOptions);
};
public getAccountTypeListForCash = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountTypeList?schId=' + this.schoolId.toString()+'&mode='+4, httpOptions);
};
public manageAccountType = function (accounttype: AccountType) {
  accounttype.userId = this.loginService.currentUserSubject.value.userId;
  accounttype.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Accounts/ManageAccountType', accounttype, httpOptions);

}
public getVoucherTypeList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetVoucherTypeList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public manageVoucherType = function (vouchertype: AcVoucherType) {
  vouchertype.userId = this.loginService.currentUserSubject.value.userId;
  vouchertype.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Accounts/ManageVoucherType', vouchertype, httpOptions);

}
public getNarrationList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetNarrationList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public manageNarration = function (narration: AcNarration) {
  narration.userId = this.loginService.currentUserSubject.value.userId;
  narration.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Accounts/ManageNarration', narration, httpOptions);

}
public getAccountGroupList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountGroupList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public manageAccountGroup = function (accountgroup: AccountGroup) {
  accountgroup.userId = this.loginService.currentUserSubject.value.userId;
  accountgroup.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Accounts/ManageAccountGroup', accountgroup, httpOptions);

}
public getAccountList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public getCashAccountList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountList?schId=' + this.schoolId.toString()+'&mode='+7, httpOptions);
};
public getAccountListForBank = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountList?schId=' + this.schoolId.toString()+'&mode='+3, httpOptions);
};
public getAccountListForVendor = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountList?schId=' + this.schoolId.toString()+'&mode='+4, httpOptions);
};
public getAccountListForCustomer = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountList?schId=' + this.schoolId.toString()+'&mode='+6, httpOptions);
};
public getAccountListForOpening = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountList?schId=' + this.schoolId.toString()+'&mode='+5, httpOptions);
};
public getAccountListGroupWise = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Accounts/GetAccountList?schId=' + this.schoolId.toString()+'&mode='+2, httpOptions);
};
public manageAccount = function (account: Account) {
  account.userId = this.loginService.currentUserSubject.value.userId;
  account.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Accounts/ManageAccount', account, httpOptions);

}
public getVendorList = function(vnid ) {    
  return this.httpClient.get(apiUrl + '/api/Purchase/GetVendorList?schId=' + this.schoolId.toString()+'&vnid='+vnid +'&mode='+1, httpOptions);
};
public getsingleVendorList = function(vnid ) {    
  return this.httpClient.get(apiUrl + '/api/Purchase/GetVendorList?schId=' + this.schoolId.toString()+'&vnid='+vnid +'&mode='+2, httpOptions);
};
public manageVendor = function(vendorData : any, ){   
  return this.httpClient.post(apiUrl + '/api/Purchase/SaveVendor', vendorData, httpOptions);
};
public getCustomerList = function(cuid ) {    
  return this.httpClient.get(apiUrl + '/api/Sale/GetCustomerList?schId=' + this.schoolId.toString()+'&cuid='+cuid +'&mode='+1, httpOptions);
};
public getsingleCustomerList = function(cuid ) {    
  return this.httpClient.get(apiUrl + '/api/Sale/GetCustomerList?schId=' + this.schoolId.toString()+'&cuid='+cuid +'&mode='+2, httpOptions);
};
public manageCustomer = function(customerData : any, ){   
  return this.httpClient.post(apiUrl + '/api/Sale/SaveCustomer', customerData, httpOptions);
};

public GetLedgerGLOpening = function(docno,sessionid,startdate,enddate,entrytype){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetLedger?docno=' + docno + '&sessionId=' + sessionid+ '&startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};
public GetLedgerBankOpening = function(docno,sessionid,startdate,enddate,entrytype){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetLedger?docno=' + docno + '&sessionId=' + sessionid+ '&startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&userid=' + this.currentUserId + '&mode=' + 2, httpOptions);
};
public GetLedgerVendorOpening = function(docno,sessionid,startdate,enddate,entrytype){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetLedger?docno=' + docno + '&sessionId=' + sessionid+ '&startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&userid=' + this.currentUserId + '&mode=' + 3, httpOptions);
};
public GetLedgerDateWise = function(docno,sessionid,startdate,enddate,entrytype){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetLedger?docno=' + docno + '&sessionId=' + sessionid+ '&startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&userid=' + this.currentUserId + '&mode=' + 4, httpOptions);
};

public SaveLedger = function(LedgerViewList: any) {
  LedgerViewList.userId = this.loginService.currentUserSubject.value.userId;
  LedgerViewList.SchoolId = this.schoolId;  
  return this.httpClient.post(apiUrl + '/api/Accounts/SaveLedger', LedgerViewList, httpOptions);
};

public getPurchaseOrderList = function(stSession,vnid ) {    
  return this.httpClient.get(apiUrl + '/api/Purchase/GetPOList?schId=' + this.schoolId.toString()+'&stSession='+stSession +'&vnid='+ vnid +'&mode='+1, httpOptions);
};
public getPurchaseOrderListForPI = function(stSession,vnid ) {    
  return this.httpClient.get(apiUrl + '/api/Purchase/GetPOList?schId=' + this.schoolId.toString()+'&stSession='+stSession +'&vnid='+ vnid +'&mode='+2, httpOptions);
};

public SavePO = function(POViewList: any) {
  POViewList.UserId = this.loginService.currentUserSubject.value.userId;
  POViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Purchase/SavePO', POViewList, httpOptions);
};
public GetPOListDetailForPO = function(poid){
  return this.httpClient.get(apiUrl + '/api/Purchase/GetPOListDetail?poId=' + poid + '&schId=' + this.schoolId.toString()+ '&mode=' + 5, httpOptions);
};
public GetPOListDetail = function(poid){
  return this.httpClient.get(apiUrl + '/api/Purchase/GetPOListDetail?poId=' + poid + '&schId=' + this.schoolId.toString()+ '&mode=' + 1, httpOptions);
};
public GetWOListDetail = function(poid){
  return this.httpClient.get(apiUrl + '/api/Purchase/GetPOListDetail?poId=' + poid + '&schId=' + this.schoolId.toString()+ '&mode=' + 2, httpOptions);
};
public GetPOListDetailForPI = function(poid){
  return this.httpClient.get(apiUrl + '/api/Purchase/GetPOListDetail?poId=' + poid + '&schId=' + this.schoolId.toString()+ '&mode=' + 3, httpOptions);
};
public GetWOListDetailForPI = function(poid){
  return this.httpClient.get(apiUrl + '/api/Purchase/GetPOListDetail?poId=' + poid + '&schId=' + this.schoolId.toString()+ '&mode=' + 4, httpOptions);
};
public SavePI = function(PIViewList: any) {
  PIViewList.UserId = this.loginService.currentUserSubject.value.userId;
  PIViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Accounts/SavePI', PIViewList, httpOptions);
};
public GetPIListDetail = function(piid){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetPIListDetail?poId=' + piid + '&schId=' + this.schoolId.toString()+ '&mode=' + 1, httpOptions);
};
public GetWIListDetail = function(piid){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetPOListDetail?piId=' + piid + '&schId=' + this.schoolId.toString()+ '&mode=' + 2, httpOptions);
};
public GetPIListDetailAfterSave = function(piid){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetPIListDetail?poId=' + piid + '&schId=' + this.schoolId.toString()+ '&mode=' + 3, httpOptions);
};
public GetWIListDetailAfterSave = function(piid){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetPOListDetail?piId=' + piid + '&schId=' + this.schoolId.toString()+ '&mode=' + 4, httpOptions);
};
getPOPDF(poid,domainName){
  return this.httpClient.post<Blob>(apiUrl + '/api/Inventory/PrintPOPDF?poId='+ poid 
  +'&schId='+ this.schoolId.toString() +'&domain='+domainName 
  +'&userid='+ this.currentUserId + '&mode=' + 1, {  responseType: 'blob' as 'json', httpOptions });
}
PrintVoucherPDF(DocNo,Session,domainName){
  return this.httpClient.post<Blob>(apiUrl + '/api/Accounts/PrintVoucherPDF?DocNo='+ DocNo 
    +'&Session='+ Session +'&schId='+ this.schoolId.toString() +'&domain='+domainName 
  + '&mode=' + 1, {  responseType: 'blob' as 'json', httpOptions });
}
public GetTrialBalance = function(startdate,enddate,domain){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetTrialBalance?startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&domain=' + domain, httpOptions);
};
public GetLedger = function(acId,startdate,enddate,domain){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetGLLedger?acId=' + acId + '&startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&userid=' + this.currentUserId + '&domain=' + domain, httpOptions);
};
public GetBankLedger = function(bkId,startdate,enddate,domain){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetBankLedger?bkId=' + bkId + '&startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&userid=' + this.currentUserId + '&domain=' + domain, httpOptions);
};
public GetVendorLedger = function(vnId,startdate,enddate,domain){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetVendorLedger?vnId=' + vnId + '&startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&userid=' + this.currentUserId + '&domain=' + domain, httpOptions);
};
public GetIncomeExpenditure = function(startdate,enddate,domain){
  return this.httpClient.get(apiUrl + '/api/Accounts/GetIncomeExpenditure?startdate=' + startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&domain=' + domain, httpOptions);
};
GetLedgerPrintPDF(acid,startdate,enddate,domain){
  return this.httpClient.post<Blob>(apiUrl + '/api/Accounts/PrintGLLedgerPDF?schId=' + this.schoolId.toString() 
  + '&acId=' + acid + '&startdate=' + startdate + '&enddate=' + enddate + '&userid=' + this.currentUserId 
  + '&domain=' + domain, {  responseType: 'blob' as 'json', httpOptions });
}
GetBankLedgerPrintPDF(bkId,startdate,enddate,domain){
  return this.httpClient.post<Blob>(apiUrl + '/api/Accounts/PrintBankLedgerPDF?schId=' + this.schoolId.toString() 
  + '&bkId=' + bkId + '&startdate=' + startdate + '&enddate=' + enddate + '&userid=' + this.currentUserId 
  + '&domain=' + domain, {  responseType: 'blob' as 'json', httpOptions });
}
GetVendorLedgerPrintPDF(vnId,startdate,enddate,domain){
  return this.httpClient.post<Blob>(apiUrl + '/api/Accounts/PrintVendorLedgerPDF?schId=' + this.schoolId.toString() 
  + '&vnId=' + vnId + '&startdate=' + startdate + '&enddate=' + enddate + '&userid=' + this.currentUserId 
  + '&domain=' + domain, {  responseType: 'blob' as 'json', httpOptions });
}
PrintIncomeExpenditurePDF(startdate,enddate,domain){
  return this.httpClient.post<Blob>(apiUrl + '/api/Accounts/PrintIncomeExpenditurePDF?schId=' + this.schoolId.toString() 
  + '&startdate=' + startdate + '&enddate=' + enddate + '&userid=' + this.currentUserId 
  + '&domain=' + domain, {  responseType: 'blob' as 'json', httpOptions });
}

}
