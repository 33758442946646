import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService  } from '../../shared/services/registration.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {LoginService} from '../../shared/services/login.service';
import { User } from '../../shared/model/user.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-registrationlist',
  templateUrl: './registrationlist.component.html',
  styleUrls: ['./registrationlist.component.scss']
  
})
export class RegistrationlistComponent implements OnInit {
  studentInfo: any;
  public stRegistraionList: any = [];
  public staticList: any = {};
  public registrationListForm: FormGroup;
  public schid:number;
  public txtFilter:  any = '';
  public userFilter: any ;
  constructor(private router: Router, 
             private stService: RegistrationService,  
             private registrationService:RegistrationService,
      private formBuilder: FormBuilder,private loginService:LoginService,private toast:ToastrService) {
        this.stService.getMasterList();
      }

  ngOnInit() {
    this.registrationService.stid = 0;
    this.schid = (<User>this.loginService.currentUserSubject.value ).schoolId;
    this.registrationService.currentMessage.subscribe(message => this.studentInfo = message)
    this.registrationListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: [''],
      fromdate:[''],
      todate:['']
    });
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
    this.loadRegistrationList();
   
  }
  loadRegistrationList() {
        let FromDate = this.registrationListForm.controls['fromdate'].value;
        if(FromDate !=null){
        let startDate = FromDate.year + '/' + FromDate.month + '/' + FromDate.day;
        this.registrationListForm.controls['fromdate'].setValue(startDate);
        }
        let ToDate = this.registrationListForm.controls['todate'].value;
        if(ToDate !=null){
        let endDate = ToDate.year + '/' + ToDate.month + '/' + ToDate.day;
        this.registrationListForm.controls['todate'].setValue(endDate);
        }

      this.registrationService.getRegistrationList(this.registrationListForm.controls['stClass'].value, this.registrationListForm.controls['stSession'].value,this.schid).
            subscribe( m => this.stRegistraionList = m);           
                this.stRegistraionList.rf_dob=moment(this.stRegistraionList.rf_dob,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
          
  }
  addStudent() {
      this.router.navigate(['/schoolregistration']);
  }
  editStudent(itm) {
    if (itm.rfId > 0) {     
      if(itm.rfClassName ==='NURSERY') {
        this.router.navigate(['/nurseryregistration', {id: itm.rfId}]);
      } else {       
          this.router.navigate(['/schoolregistration', {id: itm.rfId}]);
      }
      // this.router.navigate(['/registration', itm.rfId]);
    }
  }

  verifyRegistration(itm) {
    if (itm.rfId > 0) {
      this.router.navigate(['/verifyregistration', {id: itm.rfId}]);
    }
  }

  confirmStudent(item){
    if(item.rfStudent){
      this.toast.warning("Already registered as a student.");
      return;
    }
    this.registrationService.changeMessage(item);
    this.router.navigate(['/student']);
  }
}
