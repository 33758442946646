
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { first } from 'rxjs/operators';
import { User } from '../shared/model/user.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'registrationloginall.component.html'
})
export class RegistrationLoginAllComponent implements OnInit {
    registrationloginallForm: FormGroup;
    showError: Boolean = false;
    errorMsg: string = null;
    returnUrl: string;
    returnUrlregistration: string;
    public role: string;
    public schoolId: number;
    user: string = null;
    pass: string = null;
    constructor(public router: Router, private formBuilder: FormBuilder,
        private loginService: LoginService, private route: ActivatedRoute,private toast:ToastrService
    ) { }

    ngOnInit() {
        this.registrationloginallForm = this.formBuilder.group({
            SchId: ['', [Validators.required]]

        });
        // if (this.loginService.currentUserValue) {
        //     this.router.navigate(['/dashboard']);
        // }

       // if (this.role !== 'Nursery' ||  'Registration') {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardregistration';
      //  } else {
      //       this.returnUrlregistration = this.route.snapshot.queryParams['returnUrlregistration'] || '/dashboardregistration';
      //  }

    }
    onSubmit() {
        if (this.registrationloginallForm.invalid) {
            return;
        }
         this.schoolId = this.registrationloginallForm.controls['SchId'].value;        

        if (this.registrationloginallForm.controls['SchId'].value === "1" ) {
            this.user ="demoregistrationonline"
            this.pass = "2021"           
        }
        if (this.registrationloginallForm.controls['SchId'].value === "2" ) {
            this.user ="ramjasonlineregistration"
            this.pass = "110022"           
        }
        if (this.registrationloginallForm.controls['SchId'].value === "3" ) {
            this.user ="vpsonlineregistration"
            this.pass = "#vpsonline#"           
        }
        // this.loginService.login(this.registrationloginallForm.controls.email.value, this.registrationloginallForm.controls.password.value)
        this.loginService.login(this.user, this.pass)
        .pipe(first())
            .subscribe(
                (data: User) => {
                    if (data.access_token) {
                        localStorage.setItem('isLoggedin', 'true');
                        localStorage.setItem('authToken', data.access_token);

                        this.role = this.loginService.currentUserValue.roles;
                        if (this.role === 'OnlineRegistration') {
                            this.router.navigate(['/onlineregistration']);
                        }
                        // if (this.role !== 'Registration') {
                        //     this.router.navigate(['/onlineregistration']);

                        // } else {
                        //     this.router.navigate([this.returnUrl]);
                        // }

                    } else {
                        this.toast.error('Registration Is Over',"Error");
                    }
                },
                error => {
                    this.toast.error('Registration Is Over',"Error");
                });
    }
}
