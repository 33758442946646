import { Component, OnInit  } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StudentService } from '../../shared/services/student.service';
import { Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LoginService} from '../../shared/services/login.service';

@Component({
  selector: 'app-updatestudentself',
  templateUrl: './updatestudentself.component.html',
  styleUrls: ['./updatestudentself.component.scss'],
  providers: [DateFormatPipe, DatePipe]
})
export class UpdateStudentSelfComponent implements OnInit {
  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public studentUpdateSelfForm: FormGroup;
  public p: number = 1;
  public  HighlightRow: Number; 
  public isupdate = false;
  public message: string = null;
  public oldsuid: string = '';
  public userid: number = 0;

  public UpdateListData = ({
       stId: 0,
     
      
      IsUpdate:false
       
     });
  public UpdateMobileList = [({
    stId: 0,


    IsUpdate: false

  })];
  public studentUpdateModel = ({
    StudentId: 0,
    SchoolId: 0,
    SessionId: 0,
    IsUpdated:false,
    UpdateStudentInfoView: ({ stRollNo: 0, stMobileNo: '', stEmail: '' }),
    UpdateStudentPrevAmountView:({
      stprevamount:0
    }),
    UpdateStudentMonthFeeDiscountView: [],
    UserId:0
  })


  public updateModel = ({
    stRollNo: 0,
    stMobileNo: '',
    stEmail: '',
  
  })

  public updateModelList = ({
    stRollNo: 0,
    stMobileNo: '',
    stEmail: '',

  })

  constructor(private router: Router, private stService: StudentService,
    private formBuilder: FormBuilder, private toast: ToastrService,private loginService: LoginService) {
    // this.stService.getMasterList();
    this.userid = this.loginService.currentUserValue.userId;
    this.stService.GetStudentOldAdmissionNo(this.userid).subscribe(st => {
      this.oldsuid = st[0]['stAdmissionNo'];
     });
  }

  ngOnInit() {
    
    this.studentUpdateSelfForm = this.formBuilder.group({
      // stClass: [''],
      // stSection: [''],
      // stSession: ['']
    });
    // this.stService.masterList.subscribe(m => { this.staticList = m; })
    this.loadStudent();
  }

  loadStudent() {
    this.stService.GetStudentForSingleForStudentLogin(this.userid, 0).    
    subscribe(m => {        
        this.stList = m;
        this.stOrgList = m;
        // this.p = 1;
        // if (m && m.length > 0) {
        //   this.studentUpdateSelfForm.controls['stSession'].setValue(m[0].stSession);
        // }
      });
  }
 
 
  editStudent(itm, index) {
   
    debugger;
        
    this.studentUpdateModel.StudentId = itm.stId;
    this.studentUpdateModel.SchoolId = itm.stClass;
    this.studentUpdateModel.SessionId = itm.stSession;
    this.updateModel.stEmail = itm.stEmail;
    this.updateModel.stRollNo = itm.stRollNo;
    this.updateModel.stMobileNo = itm.stMobileNo;
    this.studentUpdateModel.UpdateStudentInfoView = this.updateModel;

    if (itm.stId > 0) {
      this.stService.updateStudentReg(this.studentUpdateModel).subscribe(m => {

        this.studentUpdateModel.IsUpdated = true;
        this.message = m.message;
        this.HighlightRow = index;
        this.toast.success(m.message, "Success")
      });
      itm.isEditable = false;
    }
  }
}
