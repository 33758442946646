import { Component, OnInit,Inject  } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StudentService } from '../../shared/services/student.service';
import { Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../shared/services/master.service';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-printexam1To2result',
  templateUrl: './printexam1To2result.component.html',
  styleUrls: ['./printexam1To2result.component.scss'],
  providers: [DateFormatPipe, DatePipe]
})
export class PrintExam1To2ResultComponent implements OnInit {
  public userclassList: any = [];
  public usersectionList: any = [];
  public usersubjectList: any[] = [];

  public exstseq: number;
  public domain = '';
  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public studentListForm: FormGroup;
  public p: number = 1; 
  public message: string = null;
  // public stPhotoURL: string;
  public examsubtypeList: any[] = [];
  isBtnUpload: boolean = false;  
  masterSelected: boolean;
  checkedList: any;
  public exstid: number;
  public sdid = 0;
  public csid = 0;
  public clsteacher = false;
  public allsection = false;

  constructor(private router: Router, private stService: StudentService, private masterService: MasterService,
    private formBuilder: FormBuilder, private toast: ToastrService, @Inject(DOCUMENT) private document: any) {
    this.stService.getMasterList();
  }

  ngOnInit() {
    this.domain = this.document.location.hostname;
    console.log(this.domain);
    this.masterService.GetExamSubTypelist_Reports().subscribe(lst => { this.examsubtypeList = lst; });
    this.studentListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: [''],
      examsubtype: [''],
      email: ['']
    }); 
   
    this.stService.masterList.subscribe(m => { this.staticList = m; }) 
    // this.stPhotoURL= environment.appUrl;
    // this.masterService.GetEmployeeNo().subscribe(lst=>{
    //   this.sdid = lst[0]['em_sdid'];
    //   this.csid = lst[0]['em_csid'];
    //   this.clsteacher = lst[0]['em_clsteacher'];
    //   this.allsection = lst[0]['em_allsection'];           
    
    // });  
  }


  loadStudent() {
    //this.stService.getStudentList(this.studentListForm.controls['stClass'].value, 
    this.stService.GetActiveStudentList(this.studentListForm.controls['stClass'].value, 
    this.studentListForm.controls['stSection'].value, this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
        // if (m && m.length > 0) {
        //   this.studentListForm.controls['stSession'].setValue(m[0].stSession);
        // }
      });
  }

  loadclass() {
    this.masterService.getUserWiseClassList().subscribe(lst => {this.userclassList = lst; });
  }
  loadsection() {
    var classId = 0;   
    var classId = Number(this.studentListForm.controls['stClass'].value);    
    this.masterService.getUserWiseSectionList(classId,0).subscribe(lst => {this.usersectionList = lst; });
  }

  loadsubject() {
    var classId = 0;
    var sectionId = 0;
    var classId = Number(this.studentListForm.controls['stClass'].value);
    var sectionId = Number(this.studentListForm.controls['stSection'].value);
    this.masterService.getUserWiseSubjectList(classId,sectionId).subscribe(lst => {this.usersubjectList = lst; });
  }


  searchStudent() {
    if (this.studentListForm.controls['stSection'].value.length > 0 && this.studentListForm.controls['stClass'].value.length == 0) {
      this.toast.error("Please select class then section");

    } else {
      if (this.studentListForm.controls['stClass'].value && this.studentListForm.controls['stClass'].value.length > 0) {
        if (this.studentListForm.controls['stSection'].value &&
          this.studentListForm.controls['stSection'].value.length > 0) {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value
            && m.stSection == this.studentListForm.controls['stSection'].value);
          this.orgList = this.stList;
        } else {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value);
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  } 

  checkChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected;
      if (property === true){
        this.stList[i].is_edit = false ;
      } else {
        this.stList[i].is_edit = true;
      }
    }
    this.getCheckedItemList();

  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.stList.length; i++) {
      if (this.stList[i].isSelected) {
        this.checkedList.push(this.stList[i]);
      }
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }

  editAllStudentMail(i) {  
    const data = {
      stSession: this.studentListForm.controls['stSession'].value ?
        this.studentListForm.controls['stSession'].value : 0,

      stClass: this.studentListForm.controls['stClass'].value ?
        this.studentListForm.controls['stClass'].value : 0,

       stSection: this.studentListForm.controls['stSection'].value ?
         this.studentListForm.controls['stSection'].value : 0,

      //  stSubject: this.studentListForm.controls['stSubject'].value ?
      //  this.studentListForm.controls['stSubject'].value : 0  
      stExamSubType: this.studentListForm.controls['examsubtype'].value ?
         this.studentListForm.controls['examsubtype'].value : 0,
         
         UserId : 0       

    };    
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.domain = this.document.location.hostname;
        if (this.exstseq==1){
          this.stService.MailUnit1ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==2){
          this.stService.MailClass1and2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==3){
          this.stService.MailUnit2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==4){
          this.stService.MailTerm2Class1and2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }
               
      }
    }      
  }

  isAllSelected(id: number, property: boolean, event: any, data) {
    this.masterSelected = false;
    this.masterSelected = this.stList.every(function (item: any) {
      return item.isSelected === true;
    })
    if (property === false) {
      const editField = event.target.textContent;
      data.is_edit = true;
      this.stList[id] = data;
    } else {
      const editField = event.target.textContent;
      data.is_edit = false;
      this.stList[id] = data;
    }
    this.getCheckedItemList();
  }

  editStudentMail(itm, i) {  
    const data = {
      stSession: this.studentListForm.controls['stSession'].value ?
        this.studentListForm.controls['stSession'].value : 0,

      stClass: this.studentListForm.controls['stClass'].value ?
        this.studentListForm.controls['stClass'].value : 0,

       stSection: this.studentListForm.controls['stSection'].value ?
         this.studentListForm.controls['stSection'].value : 0,

      //  stSubject: this.studentListForm.controls['stSubject'].value ?
      //  this.studentListForm.controls['stSubject'].value : 0  
      stExamSubType: this.studentListForm.controls['examsubtype'].value ?
         this.studentListForm.controls['examsubtype'].value : 0,
         
         UserId : 0       

    }; 
    if (itm.stId > 0) {
      if (itm.is_edit == false) {
        this.domain = this.document.location.hostname;
        if (this.exstseq==1){
          this.stService.MailUnit1ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==2){
          this.stService.MailClass1and2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==3){
          this.stService.MailUnit2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==4){
          this.stService.MailTerm2Class1and2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }
      //   this.stService.MailClass1and2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
      //   if (response) {
      //     itm.is_Updated = true;
      //     itm.Action = response;
      //     this.stList[i] = itm;
      //     this.toast.success("Sent Mail");
      //   } else {
      //     itm.is_Updated = false;
      //     itm.Action = response;
      //     this.toast.success("Not Sent Mail");
      //     // alert('Message not sent');
      //   }
      // });        
    
    }
    }
    // if (this.clsteacher == true) {
     
    //   if (this.sdid == data.stClass && this.csid == data.stSection){

    //     if (itm.stId > 0) {
    //       if (itm.is_edit == false) {
    //         this.domain = this.document.location.hostname;
    //         this.stService.MailClass1and2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
    //         if (response) {
    //           itm.is_Updated = true;
    //           itm.Action = response;
    //           this.stList[i] = itm;
    //           this.toast.success(response);
    //         } else {
    //           itm.is_Updated = false;
    //           itm.Action = response;
    //           // alert('Message not sent');
    //         }
    //       });        
        
    //     }
    //     }

    //   }


    // } else {
    //     if (this.allsection == true){
    //       if (itm.stId > 0) {
    //         if (itm.is_edit == false) {
    //           this.domain = this.document.location.hostname;
    //           this.stService.MailClass1and2ReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
    //           if (response) {
    //             itm.is_Updated = true;
    //             itm.Action = response;
    //             this.stList[i] = itm;
    //             this.toast.success(response);
    //           } else {
    //             itm.is_Updated = false;
    //             itm.Action = response;
    //             // alert('Message not sent');
    //           }
    //         });        
          
    //       }
    //       }

    //     } else {
    //       this.toast.error("You are not Class teacher, Only Class teacher can mail ");
    //     }      
    // } 
  
  }

  // mail(itm) {
  //   const data = {
  //     stSession: this.studentListForm.controls['stSession'].value ?
  //       this.studentListForm.controls['stSession'].value : 0,

  //     stClass: this.studentListForm.controls['stClass'].value ?
  //       this.studentListForm.controls['stClass'].value : 0,

  //      stSection: this.studentListForm.controls['stSection'].value ?
  //        this.studentListForm.controls['stSection'].value : 0,

  //     //  stSubject: this.studentListForm.controls['stSubject'].value ?
  //     //  this.studentListForm.controls['stSubject'].value : 0  
  //     stExamSubType: this.studentListForm.controls['examsubtype'].value ?
  //        this.studentListForm.controls['examsubtype'].value : 0,
         
  //        UserId : 0       

  //   };
  //   this.domain = this.document.location.hostname;
  //   this.stService.MailReportCard(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{
  //     if (response) {
  //       this.toast.success('e-mail has been sent on registered mail id.');
  //       // this.SMS();
  //     } else {
  //       alert('Mail not sent');
  //     }
  //   });
  // }
 
  Print(itm) {
    const data = {
      stSession: this.studentListForm.controls['stSession'].value ?
        this.studentListForm.controls['stSession'].value : 0,

      stClass: this.studentListForm.controls['stClass'].value ?
        this.studentListForm.controls['stClass'].value : 0,

       stSection: this.studentListForm.controls['stSection'].value ?
         this.studentListForm.controls['stSection'].value : 0,

      //  stSubject: this.studentListForm.controls['stSubject'].value ?
      //  this.studentListForm.controls['stSubject'].value : 0  
      stExamSubType: this.studentListForm.controls['examsubtype'].value ?
         this.studentListForm.controls['examsubtype'].value : 0,
         
         UserId : 0       

    };   
    if (this.exstseq==1){
         this.stService.printunit1PDF(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{ 
            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
              encodeURI(response) + "'></iframe>");            
          })
    }else if (this.exstseq==2){
         this.stService.printterm1PDF(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{ 
            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
              encodeURI(response) + "'></iframe>");            
          })
    }else if (this.exstseq==3){
        this.stService.printunit2PDF(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{       
          let pdfWindow = window.open("")
          pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
            encodeURI(response) + "'></iframe>");            
        })
    }else if (this.exstseq==4){
        this.stService.printterm2PDF(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{ 
          let pdfWindow = window.open("")
          pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
            encodeURI(response) + "'></iframe>");            
        })
    }
  
  }  

  // Print3To10(itm) {
  //   const data = {
  //     stSession: this.studentListForm.controls['stSession'].value ?
  //       this.studentListForm.controls['stSession'].value : 0,

  //     stClass: this.studentListForm.controls['stClass'].value ?
  //       this.studentListForm.controls['stClass'].value : 0,

  //      stSection: this.studentListForm.controls['stSection'].value ?
  //        this.studentListForm.controls['stSection'].value : 0,

  //     //  stSubject: this.studentListForm.controls['stSubject'].value ?
  //     //  this.studentListForm.controls['stSubject'].value : 0  
  //     stExamSubType: this.studentListForm.controls['examsubtype'].value ?
  //        this.studentListForm.controls['examsubtype'].value : 0,
         
  //        UserId : 0       

  //   };   
  //   this.stService.printterm1Class3To10PDF(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{      
  //     let pdfWindow = window.open("")
  //     pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
  //       encodeURI(response) + "'></iframe>");          
      
  //   })
  // }  

  // Print11To12(itm) {
  //   const data = {
  //     stSession: this.studentListForm.controls['stSession'].value ?
  //       this.studentListForm.controls['stSession'].value : 0,

  //     stClass: this.studentListForm.controls['stClass'].value ?
  //       this.studentListForm.controls['stClass'].value : 0,

  //      stSection: this.studentListForm.controls['stSection'].value ?
  //        this.studentListForm.controls['stSection'].value : 0,

  //     //  stSubject: this.studentListForm.controls['stSubject'].value ?
  //     //  this.studentListForm.controls['stSubject'].value : 0  
  //     stExamSubType: this.studentListForm.controls['examsubtype'].value ?
  //        this.studentListForm.controls['examsubtype'].value : 0,
         
  //        UserId : 0       

  //   };   
  //   this.stService.printterm1Class11To12PDF(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain).subscribe((response:any)=>{      
  //     let pdfWindow = window.open("")
  //     pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
  //       encodeURI(response) + "'></iframe>");          
      
  //   })
  // }  

  onChangeSelectExamSubType($event){
    this.exstid = $event.target.value;
    this.masterService.GetExamSubTypeSequenceNo_Reports(this.exstid).subscribe(lst=>{        
      this.exstseq = lst[0]['exst_seq'];  
  });     
  }

  onChangeSelect($event){
    var email = $event.target.value;
    if (email != "") {
      this.isBtnUpload = true;
      //this.toast.error("e-mail is blank");
    }    
  }
  sentmail() {  
    const data = {
      stSession: this.studentListForm.controls['stSession'].value ?
        this.studentListForm.controls['stSession'].value : 0,

      stClass: this.studentListForm.controls['stClass'].value ?
        this.studentListForm.controls['stClass'].value : 0,

       stSection: this.studentListForm.controls['stSection'].value ?
         this.studentListForm.controls['stSection'].value : 0,

      //  stSubject: this.studentListForm.controls['stSubject'].value ?
      //  this.studentListForm.controls['stSubject'].value : 0  
      stExamSubType: this.studentListForm.controls['examsubtype'].value ?
         this.studentListForm.controls['examsubtype'].value : 0,
         
         UserId : 0,

      stemail: this.studentListForm.controls['email'].value ?
          this.studentListForm.controls['email'].value : 0 

    };    
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.domain = this.document.location.hostname;
        if (this.exstseq==1){
          this.stService.MailUnit1ReportCardOnSingleMailID(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain,data.stemail).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              //this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==2){
          this.stService.MailClass1and2ReportCardOnSingleMailID(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain,data.stemail).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              //this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==3){
          this.stService.MailUnit2ReportCardOnSingleMailID(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain,data.stemail).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              //this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }else if (this.exstseq==4){
          this.stService.MailTerm2Class1and2ReportCardOnSingleMailID(itm.stId,data.stSession,data.stExamSubType,data.UserId,this.domain,data.stemail).subscribe((response:any)=>{
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              //this.stList[i] = itm;
              this.toast.success("Sent Mail");
            } else {
              itm.is_Updated = false;
              itm.Action = response;
              this.toast.success("Not Sent Mail");
              // alert('Message not sent');
            }
          });   
        }
               
      }
    }      
  }
  
}
