import { Injectable } from '@angular/core';
import {LoginService} from './login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { feehead } from '../model/index';
import { JsonPipe } from '@angular/common';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { User } from '../model/user.model';
import { FeeReceipt} from '../../shared/model/fee-receipt.model';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})

};
const apiUrl = environment.appUrl;


@Injectable({
  providedIn: 'root'
})
export class FeeReceiptService {
  public stid = 0;
  public frid = null;
  public oldsuid = null;
  public schid = (<User>this.loginService.currentUserSubject.value ).schoolId;
  constructor(private loginService: LoginService, private httpClient: HttpClient, public spinService: SpinnerVisibilityService) {

  }

  public checkRegistration = function( qry: string, sessionId: number) {
    return this.httpClient.get(apiUrl + '/api/Student/CheckRegistration?qry=' + qry + '&schId=' + this.schid + '&sessionId=' + sessionId);
  };

  public getMasterList = function( ) {
    return this.httpClient.get(apiUrl + '/api/Student/GetMaster?schId=' + this.schid);
  };
  
  public getStudentList = function(  stclass: number, stSection: number = 0, stSession: number= 0) {
    stSection = stSection ? stSection : 0;
    stSession = stSession ? stSession : 0;
     return this.httpClient.get(apiUrl + '/api/Student/GetList?schId=' + this.schid + '&stClass=' + stclass
     + '&stSection=' + stSection + '&stSession=' + stSession   );
  };

  public saveFeeHead = function (FeeHeadReg: feehead) {
    const fhModel = {StudentId: this.stid, SchoolId: this.schid,
      FeeHeadReg: JSON.stringify(FeeHeadReg), UserId : this.loginService.currentUserSubject.SchoolId };
    return this.httpClient.post(apiUrl + '/api/FeeHead/SaveFeeHead', fhModel, httpOptions);
  };

  public GetStudentOldAdmissionNo = function(UserId) {
     return this.httpClient.get(apiUrl + '/api/Fee/GetStudentOldAdmissionNo?stid=' + UserId);
  };

  public getStudentFeecalculation = function(uid: string, sessionid: any){
    return this.httpClient.get(apiUrl + '/api/Fee/GetStudentFeeCalculation?schid='+
    this.schid+'&oldsuid='+uid+'&stSession='+sessionid, httpOptions); 
  };
  public getPendingfromprevioussession = function(uid: string, sessionId: any){
    return this.httpClient.get(apiUrl+'/api/Fee/GetStudentPendingAmountPrevSession?schid='+
    this.schid+'&oldsuid='+uid+'&stSession='+sessionId, httpOptions);
  };

  public getPendingfromcurrentsession = function(uid: string, sessionId: any){
    return this.httpClient.get(apiUrl+'/api/Fee/GetStudentPendingAmountCurrentSession?schid='+
    this.schid+'&oldsuid='+uid+'&stSession='+sessionId, httpOptions);
  };

  public GetStudentPendingAmountMonthWiseList = function(uid: string, sessionid: any){
    return this.httpClient.get(apiUrl + '/api/fee/GetStudentPendingAmountMonthWiseList?oldsuid='+
    uid+'&stSession='+sessionid, httpOptions); 
  };

  public saveStudentFeecForm = function(FeeReceiptModel : FeeReceipt ){
    return this.httpClient.post(apiUrl + '/api/Fee/SaveFeeReceipt', JSON.stringify(FeeReceiptModel), 
    httpOptions);
  };
  getPDF(sessionid: any, domainName, UserId){
    return this.httpClient.post<Blob>(apiUrl + '/api/Fee/PrintFeeReceiptPDF?qry='+ this.frid +'&oldsuid='+ this.oldsuid 
    +'&schId='+ this.schid +'&sessionid='+ sessionid +'&domain='+domainName 
    +'&userid='+ UserId, {  responseType: 'blob' as 'json', httpOptions });
  }

  getFeeReceiptStatusPDF(fr: any, suid: any, sessionid: any, domainName, UserId){
    return this.httpClient.post<Blob>(apiUrl + '/api/Fee/PrintFeeReceiptPDF?qry='+ fr +'&oldsuid='+ suid 
    +'&schId='+ this.schid +'&sessionid='+ sessionid +'&domain='+domainName 
    +'&userid='+ UserId, {  responseType: 'blob' as 'json', httpOptions });

    // return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
    //   + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
    //   + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
    //   data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate,
    //   {  responseType: 'blob' as 'json', httpOptions });
  }

  SMS(){
    return this.httpClient.post(apiUrl + '/api/Fee/GetFeeReceiptMessageDetail?schId='+ this.schid +'&qry='+ this.frid, httpOptions);
  }
  Mail(UserId, domainName){
    return this.httpClient.post(apiUrl + '/api/Fee/GetFeeReceiptMailDetail?qry='+ this.frid 
    +'&schId='+ this.schid +'&userid='+ UserId + '&domain=' + domainName, httpOptions );
  }

}
