import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-studentforgotpwd',
  templateUrl: './studentforgotpwd.component.html',
  styleUrls: ['./studentforgotpwd.component.scss'],
  
})
export class StudentForgotpwdComponent implements OnInit {
  studentforgotForm: FormGroup;

  constructor(private loginService: LoginService, private formBuilder: FormBuilder,private toastr:ToastrService) { }

  ngOnInit() {
    this.studentforgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(4)]]
  });
  }

  onSubmit() {
    if (this.studentforgotForm.invalid) {
        return;
    }

      this.loginService.studentforgotpassword(this.studentforgotForm.controls.email.value).subscribe((response:any)=>{
        if (response === true) {
          this.toastr.success("New Password has been sent on your registered mail id.");
        } else {
          alert('User does not exist');
        }
      });

  }
}
