import { Component, OnInit  } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StudentService } from '../../shared/services/student.service';
import { Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../shared/services/master.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-printstudent',
  templateUrl: './printstudent.component.html',
  styleUrls: ['./printstudent.component.scss'],
  providers: [DateFormatPipe, DatePipe]
})
export class PrintStudentComponent implements OnInit {
  public userclassList: any = [];
  public usersectionList: any = [];
  public usersubjectList: any[] = [];

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public studentListForm: FormGroup;
  public p: number = 1; 
  public message: string = null;
  // public stPhotoURL: string;
  
  constructor(private router: Router, private stService: StudentService, private masterService: MasterService,
    private formBuilder: FormBuilder, private toast: ToastrService) {
    this.stService.getMasterList();
  }

  ngOnInit() {
    
    this.studentListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: ['']
    }); 
   
    this.stService.masterList.subscribe(m => { this.staticList = m; }) 
    // this.stPhotoURL= environment.appUrl;
  }


  loadStudent() {
    //this.stService.getStudentList(this.studentListForm.controls['stClass'].value, 
    this.stService.GetActiveStudentList(this.studentListForm.controls['stClass'].value, 
    this.studentListForm.controls['stSection'].value, this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
        // if (m && m.length > 0) {
        //   this.studentListForm.controls['stSession'].setValue(m[0].stSession);
        // }
      });
  }

  loadclass() {
    this.masterService.getUserWiseClassList().subscribe(lst => {this.userclassList = lst; });
  }
  loadsection() {
    var classId = 0;   
    var classId = Number(this.studentListForm.controls['stClass'].value); 
    this.masterService.getUserWiseSectionList(classId,0).subscribe(lst => {this.usersectionList = lst; });
  }

  loadsubject() {
    var classId = 0;
    var sectionId = 0;
    var classId = Number(this.studentListForm.controls['stClass'].value);
    var sectionId = Number(this.studentListForm.controls['stSection'].value);
    this.masterService.getUserWiseSubjectList(classId,sectionId).subscribe(lst => {this.usersubjectList = lst; });
  }


  searchStudent() {
    if (this.studentListForm.controls['stSection'].value.length > 0 && this.studentListForm.controls['stClass'].value.length == 0) {
      this.toast.error("Please select class then section");

    } else {
      if (this.studentListForm.controls['stClass'].value && this.studentListForm.controls['stClass'].value.length > 0) {
        if (this.studentListForm.controls['stSection'].value &&
          this.studentListForm.controls['stSection'].value.length > 0) {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value
            && m.stSection == this.studentListForm.controls['stSection'].value);
          this.orgList = this.stList;
        } else {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value);
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  } 
 
  PrintICard(itm) {
    this.stService.getICardPDF(itm.stId).subscribe((response:any)=>{      
      let pdfWindow = window.open("")
      pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
        encodeURI(response) + "'></iframe>");          
      
    })
  }  

  PrintICardLandScape(itm) {
    this.stService.getICardLandScapePDF(itm.stId).subscribe((response:any)=>{      
      let pdfWindow = window.open("")
      pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
        encodeURI(response) + "'></iframe>");          
      
    })
  }  
}
