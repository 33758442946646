import {Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from "moment";

@Pipe({
  name: 'dateformat'
})
export class DateFormatPipe implements PipeTransform {

    constructor(private datePipe: DatePipe){

    }
  public transform(term: string,format:string) {
      
      if(term != '' && term !== undefined && term !== null){
        const date = moment(term, format);
        
        let Year =  Number(this.datePipe.transform(date, 'yyyy'));
        let Month =  Number(this.datePipe.transform(date , 'MM'));
        let Day =  Number(this.datePipe.transform(date , 'dd'));
        
        return {
            year: Year,
            month: Month,
            day: Day
        };
      } else {
          return term;
      }
    
  }

  public transformDate(termdate: string,format:string) {
      
    if(termdate != '' && termdate !== undefined && termdate !== null){
      let term = new Date(termdate);
      const date = moment(term, format);
      
      let Year =  Number(this.datePipe.transform(date, 'yyyy'));
      let Month =  Number(this.datePipe.transform(date , 'MM'));
      let Day =  Number(this.datePipe.transform(date , 'dd'));
      
      return {
          year: Year,
          month: Month,
          day: Day
      };
    } else {
        return termdate;
    }
  
}

// public dateDiff(startingDate, endingDate) {
//   let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
//   if (!endingDate) {
//     endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
//   }
//   let endDate = new Date(endingDate);
//   if (startDate > endDate) {
//     const swap = startDate;
//     startDate = endDate;
//     endDate = swap;
//   }
//   const startYear = startDate.getFullYear();
//   const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
//   const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

//   let yearDiff = endDate.getFullYear() - startYear;
//   let monthDiff = endDate.getMonth() - startDate.getMonth();
//   if (monthDiff < 0) {
//     yearDiff--;
//     monthDiff += 12;
//   }
//   let dayDiff = endDate.getDate() - startDate.getDate();
//   if (dayDiff < 0) {
//     if (monthDiff > 0) {
//       monthDiff--;
//     } else {
//       yearDiff--;
//       monthDiff = 11;
//     }
//     dayDiff += daysInMonth[startDate.getMonth()];
//   }

//   return yearDiff + 'Years ' + monthDiff + 'Months ' + dayDiff + 'Days';
// }

}