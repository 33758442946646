import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-studentlist',
  templateUrl: './studentlist.component.html',
  styleUrls: ['./studentlist.component.scss'],
  
})
export class StudentlistComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public studentListForm: FormGroup;
  public p:number =1;
  constructor(private router: Router, private stService: StudentService,
      private formBuilder: FormBuilder,private toast:ToastrService) {
        this.stService.getMasterList();
      }

  ngOnInit() {
    this.studentListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: ['']
    });
    /* this.stList.push({'id': 3, 'name': 'sujit', session: '2018-19'},
                    {'id': 4, 'name': 'sujit1', session: '2018-19'},
                    {'id': 5, 'name': 'sujit2', session: '2018-19'}); */
    
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
    this.loadStudent(true);
  }

  loadStudent(chk) {
    this.stService.getStudentList(0, 0, this.studentListForm.controls['stSession'].value).
          subscribe( m => {
            this.stList = m;
            this.stOrgList = m;
            this.p=1;
            if (chk && m && m.length > 0 ) {
              this.studentListForm.controls['stSession'].setValue(m[0].stSession);
            }
          });
  }
  searchStudent() {
    if(this.studentListForm.controls['stSection'].value .length > 0 && this.studentListForm.controls['stClass'].value.length ==0 ){
        this.toast.error("Please select class then section");
        
    }else{
      if (this.studentListForm.controls['stClass'].value && this.studentListForm.controls['stClass'].value .length > 0 ) {
        if (this.studentListForm.controls['stSection'].value &&
            this.studentListForm.controls['stSection'].value .length > 0)  {
              this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value
                            && m.stSection == this.studentListForm.controls['stSection'].value );
              this.orgList = this.stList;
        } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value );
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
  addStudent() {
    this.router.navigate(['/student']);
  }
  editStudent(itm) {
    if (itm.stId > 0) {
      this.stService.stid = 0;
      this.router.navigate(['/student', {id:itm.stId,ssid:this.studentListForm.controls['stSession'].value}]);
    }
  }

}
