import { Component, OnInit } from '@angular/core';
import { RegistrationService  } from '../../shared/services/registration.service';
import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {LoginService} from '../../shared/services/login.service';
import { User } from '../../shared/model/user.model';

@Component({
  selector: 'app-registrationsms',
  templateUrl: './registrationsms.component.html',
  styleUrls: ['./registrationsms.component.scss'],
  
})
export class RegistrationSMSComponent implements OnInit {
  public notificationtypeList: any[] = [];
  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public UserId:number;
  public message: string = null;
  public registrationsmsForm: FormGroup;
  public p:number =1;
  masterSelected: boolean;
  checkedList: any;
  public IsAllChecked: boolean = false;
  public schid:number;
  selectedType: string = '';
  constructor(private router: Router, private stService: StudentService, 
    private registrationService:RegistrationService,private loginService:LoginService,
      private formBuilder: FormBuilder,private toast:ToastrService) {
        this.registrationService.getMasterList();
        this.UserId = JSON.parse(localStorage.getItem('currentUser'))["userId"];
      }

  ngOnInit() {
    this.registrationService.getNotificationTypeRegistrationList().subscribe(lst => { this.notificationtypeList = lst; });
    
    this.schid = (<User>this.loginService.currentUserSubject.value ).schoolId;
    this.registrationsmsForm = this.formBuilder.group({
      stClass: [''],
      // stSection: [''],
       stSession: [''],
      // remarks: [''],
      nmtid: ['']
    });
    
    
    this.registrationService.masterList.subscribe(m=>{this.staticList=m;})
   // this.loadStudent(true);
  }

  loadStudent() {
    const data = { 
                    stSession: this.registrationsmsForm.controls['stSession'].value?
                    this.registrationsmsForm.controls['stSession'].value : 0,
                    
                    stClass: this.registrationsmsForm.controls['stClass'].value?
                  this.registrationsmsForm.controls['stClass'].value : 0,

                  // stSection: this.registrationsmsForm.controls['stSection'].value?
                  // this.registrationsmsForm.controls['stSection'].value : 0,

                  UserId: this.UserId,

                  nmtid: this.registrationsmsForm.controls['nmtid'].value?
                  this.registrationsmsForm.controls['nmtid'].value : 0,
                  // remarks: this.registrationsmsForm.controls['remarks'].value?
                  // this.registrationsmsForm.controls['remarks'].value : ''
                  };


    this.registrationService.getRegistrationList(data.stClass, data.stSession, this.schid).
          subscribe( m => {
            this.stList = m;
            this.stOrgList = m;
            // this.p = 1;
            // if (chk && m && m.length > 0 ) {
            //   this.registrationsmsForm.controls['stSession'].setValue(m[0].stSession);
            // }
          });
  }
  searchStudent() {
    // if(this.registrationsmsForm.controls['stSection'].value .length > 0 &&
    // this.registrationsmsForm.controls['stClass'].value.length === 0 ){
      if(this.registrationsmsForm.controls['stClass'].value.length === 0 ){
        this.toast.error("Please select class then section");

    } else {
      if (this.registrationsmsForm.controls['stClass'].value && this.registrationsmsForm.controls['stClass'].value .length > 0 ) {
        // if (this.registrationsmsForm.controls['stSection'].value &&
        //     this.registrationsmsForm.controls['stSection'].value .length > 0)  {
        //       this.stList = this.stOrgList.filter(m => m.stClass == this.registrationsmsForm.controls['stClass'].value
        //                     && m.stSection == this.registrationsmsForm.controls['stSection'].value );
        //       this.orgList = this.stList;
        // } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.registrationsmsForm.controls['stClass'].value );
        // }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
 
  editStudent(itm, i) {
    if (itm.rfId > 0) {
      if (itm.is_edit == false) {
        this.registrationService.GetGeneralRegistrationSMS(this.selectedType, itm.rfNewRegistrationNo).subscribe((response:any)=>{
        if (response) {
          itm.is_Updated = true;
          itm.Action = response;
          this.stList[i] = itm;
          this.toast.success(response);
        } else {
          itm.is_Updated = false;
          itm.Action = response;
          // alert('Message not sent');
        }
      });
    }
    }
  }
    
   
  editStudentMail(itm, i) {    
    if (itm.rfId > 0) {
      if (itm.is_edit == false) {
        this.registrationService.GetGeneralRegistrationEMail(this.selectedType, itm.rfNewRegistrationNo).subscribe((response:any)=>{
        if (response) {
          itm.is_Updated = true;
          itm.Action = response;
          this.stList[i] = itm;
          this.toast.success(response);
        } else {
          itm.is_Updated = false;
          itm.Action = response;
          // alert('Message not sent');
        }
      });
     }
    }
  }

  editAllStudent(i) {   
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.registrationService.GetGeneralRegistrationSMS(this.selectedType, itm.rfNewRegistrationNo).subscribe((response:any)=>{
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;
            // alert('Message not sent');
          }
        });
      
      }
    }
  }

  editAllStudentMail(i) {   
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.registrationService.GetGeneralRegistrationEMail(this.selectedType, itm.rfNewRegistrationNo).subscribe((response:any)=>{
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;
            // alert('Message not sent');
          }
        });
      
      }
    }
  }

  onChangeTypeDDL(event) {    
    this.selectedType = event.target.options[event.target.selectedIndex].text;
  }

  isAllSelected(id: number, property: boolean, event: any, data) {
    this.masterSelected = false;
    this.masterSelected = this.stList.every(function (item: any) {
      return item.isSelected === true;
    })
    if (property === false) {
      const editField = event.target.textContent;
      data.is_edit = true;
      this.stList[id] = data;
    } else {
      const editField = event.target.textContent;
      data.is_edit = false;
      this.stList[id] = data;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.stList.length; i++) {
      if (this.stList[i].isSelected) {
        this.checkedList.push(this.stList[i]);
      }
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }
  checkUncheckAll() {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected; 
    }
  }

  checkChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected;
      if (property === true){
        this.stList[i].is_edit = false ;
      } else {
        this.stList[i].is_edit = true;
      }
    }
    this.getCheckedItemList();

  }

}
