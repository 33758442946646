import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
   } from '@angular/common/http';
   import { Observable, throwError } from 'rxjs';
   import { retry, catchError } from 'rxjs/operators';
   import { LoginService } from '../services';
   import { Injectable } from '@angular/core';

   @Injectable()
   export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private login: LoginService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.getItem('authToken')) {
            request = request.clone({
                setHeaders: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`
                }
            });
        }
        return ( next.handle(request) )
        .pipe(
            catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              if (error.status === 401 && error.statusText === 'Unauthorized') {
                  
                  this.login.refreshToken();
              } else {
                  errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                // window.alert(errorMessage);
                  return throwError(errorMessage);
              }
            }
          })
        );
    }
   }
