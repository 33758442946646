import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatorComponent } from '../validation/showerror.component';
import { NumberDirective } from '../validation/number.directive';
import { TwoDigitDecimaNumberDirective } from '../validation/decimal.directive';
import { InputRestrictionDirective } from '../validation/nospecialchar.directive';


@NgModule({
  declarations: [ValidatorComponent, NumberDirective,
    TwoDigitDecimaNumberDirective, InputRestrictionDirective

  ],
  imports: [
    CommonModule
  ],
  exports: [ValidatorComponent, NumberDirective, TwoDigitDecimaNumberDirective, InputRestrictionDirective]
})
export class SharedModule { }
