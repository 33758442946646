import { Injectable } from '@angular/core';
import {LoginService} from './login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { User } from '../model/user.model';
import { RegistrationAmount } from '../model/registrationamount.model';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})

};
const apiUrl = environment.appUrl;


@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  public messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  public stid = 0;
  public masterList: BehaviorSubject<any>=new BehaviorSubject<any>({});
  public schid = (<User>this.loginService.currentUserSubject.value ).schoolId;
  public studentInfo = null;
  constructor(private loginService: LoginService, private httpClient: HttpClient, public spinService: SpinnerVisibilityService) {
    this.loginService.currentUserSubject.subscribe(m=>{
      this.schid =m?m.schoolId:0;
    })  

  }
  changeMessage(studentInfo) {
    this.messageSource.next(studentInfo);
  }
  

  public searchStudentRegistration = function( qry: string) {
    return this.httpClient.get(apiUrl + '/api/Registration/Getregistrationlistforstudent?RegistrationId=' + qry + '&schId=' + this.schid ).
      pipe(map( m => { this.studentInfo = m;
        console.log(this.studentInfo);
        return m;
      },
        error => {
        console.log(error);
        this.studentInfo = null;
      }));
  };

  
  public checkRegistration = function( qry: string, sessionId: number) {
    return this.httpClient.get(apiUrl + '/api/Registration/CheckRegistration?qry=' + qry + '&schId=' + 
    this.schid + '&sessionId=' + sessionId);
  };
  public getMasterList = function( ) {
    return this.httpClient.get(apiUrl + '/api/Registration/GetMaster?schId=' + this.schid).subscribe(m=>{
      this.masterList.next(m);
    })
  };

  public GetDistrictCityLocalityList = function(mode, stateid,districtid, cityid){
    return this.httpClient.get(apiUrl + '/api/Registration/GetDistrictCityLocalityList?mode=' + mode
    + '&StateId=' + stateid+'&DistrictId='+districtid +'&CityId='+cityid +'&schId='+this.schid, httpOptions);
  };
  
  
  public autoComplete = function(type: string, qry: string, ctid: number= 0, stid: number= 0) {
   // this.spinService.hide();
    this.spinService.visibility$.source.next(false);
    const param = '?type=' + type + '&qry=' + qry + '&ctid=' + ctid + '&stid=' + stid + '&schid=' + this.schid;
    return this.httpClient.get(apiUrl + '/api/AutoComplete/GetAutoComplete' + param);
    // this.spinService.show();
  };
  
  public getRegistrationList= function ( stclass: number, stSession: number= 0,stSchoolId: number= 0) {
    stSession=stSession?stSession:0;
    stclass = stclass?stclass:0
  
    return this.httpClient.get(apiUrl+'/api/Registration/GetRegistrationList?schId='+stSchoolId
    +'&stClass='+stclass+'&stSession='+stSession,httpOptions)
  };
  public getPointsDesciptionList= function (schoolid:number) {
    
      return this.httpClient.get(apiUrl+'/api/Registration/GetPointDescriptionList?schId='+schoolid,httpOptions)
  };
  public saveRegistraionwithPoints = function( registrationWithPoints:any,stSchoolId: number=0,userId:number=0) {
    let stModel = {
      "Registration" : registrationWithPoints.registration,
      "Point": registrationWithPoints.points,
      "SchoolId": stSchoolId,
      "UserId" : userId
      };
     
     // console.log(stModel);
    return this.httpClient.post(apiUrl + '/api/Registration/SaveRegistration', stModel, httpOptions);
 };

 public saveRegistraionwithPointsImport = function( registrationWithPoints:any,stSchoolId: number=0,userId:number=0) {
  let stModel = {
    "Registration" : registrationWithPoints.registration,
    "Point": registrationWithPoints.points,
    "SchoolId": stSchoolId,
    "UserId" : userId
    };
   
   // console.log(stModel);
  return this.httpClient.post(apiUrl + '/api/Registration/SaveRegistrationImport', stModel, httpOptions);
};

//  public saveRegistrationDocument= function(regUploadDocs:any){
    
//   return this.httpClient.post(apiUrl+'/api/Registration/SaveRegistrationDoc', regUploadDocs, httpOptions);
// }
 public getStudentDetails= function(RegistrationId:number){
  return this.httpClient.get(apiUrl+'/api/Registration/GetRegistrationDetailsList?RegistrationId='+
  RegistrationId+'&schid='+this.schid,httpOptions)
 }


//  public searchStudentforRegisteration = function( qry: string) {
//   return this.httpClient.get(apiUrl + '/api/registration/registrationsiblingsearchforstudent?AdmissionNo=' + qry + '&schId=' + this.schid).
//   pipe(map( m => { this.studentInfo = m;
//       if (this.studentInfo.stLeavingDate == '1900-01-01T00:00:00') {
//         this.studentInfo.stLeavingDate = null;
//       }
//     return m;
//   },
//     error => {
//       this.studentInfo = null;
//     }));
// };

public searchStudentforRegisteration = function( qry: string) {
  return this.httpClient.get(apiUrl + '/api/registration/registrationsiblingsearchforstudent?schId=' + this.schid + '&AdmissionNo=' + qry, httpOptions)
};

getPDF(studentid, domainName){
  let SchoolId =  this.schid;
  return this.httpClient.post<Blob>(apiUrl + '/api/Registration/SaveRegistrationProfilePDF?mode=1&qry='+studentid+'&schId='+ SchoolId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

SMS(studentid){
  return this.httpClient.post(apiUrl + '/api/Registration/GetRegistrationMessageDetail?schId=' + 
  this.schid +'&registrationid='+ studentid, httpOptions );
}
Mail( studentid, domainName){
  return this.httpClient.post(apiUrl + '/api/Registration/GetRegistrationMailDetail?schId=' +
  this.schid +'&registrationid='+ studentid+'&domain='+domainName, httpOptions );
}
MailNursery( studentid, domainName){
  return this.httpClient.post(apiUrl + '/api/Registration/GetNurseryRegistrationMailDetail?schId=' +
  this.schid +'&registrationid='+ studentid+'&domain='+domainName, httpOptions );
}
getPDFNursery(studentid, domainName){
  let SchoolId =  this.schid;
  return this.httpClient.post<Blob>(apiUrl + '/api/Registration/SaveNurseryRegistrationProfilePDF?mode=1&qry='
  +studentid+'&schId='+ SchoolId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailOnline(studentid, domainName){
  return this.httpClient.post(apiUrl + '/api/Registration/GetOnlineRegistrationMailDetail?schId=' +
  this.schid +'&registrationid='+ studentid + '&domain=' + domainName, httpOptions );
}
getPDFOnline(studentid, domainName){
  let SchoolId =  this.schid;
  return this.httpClient.post<Blob>(apiUrl + '/api/Registration/SaveOnlineRegistrationProfilePDF?mode=1&qry='
  +studentid+'&schId='+ SchoolId +'&domain=' + domainName, {  responseType: 'blob' as 'json', httpOptions });
}

getPDFAdmissionForm(studentid, domainName){
  let SchoolId =  this.schid;
  return this.httpClient.post<Blob>(apiUrl + '/api/Registration/SaveAdmissionFormProfilePDF?mode=1&qry='
  +studentid+'&schId='+ SchoolId +'&domain=' + domainName, {  responseType: 'blob' as 'json', httpOptions });
}

public getNotificationTypeRegistrationList = function( ) {
  return this.httpClient.get(apiUrl + '/api/setting/GetNotificationTypeRegistration');
};

public GetGeneralRegistrationEMail = function( msgtype: string,qry: string) {
  return this.httpClient.post(apiUrl + '/api/Registration/GetGeneralRegistrationEMail?schId=' + 
  this.schid + '&msgtype=' + msgtype + '&qry=' + qry);
};

public GetGeneralRegistrationSMS = function( msgtype: string,qry: string) {
  return this.httpClient.get(apiUrl + '/api/Registration/GetGeneralRegistrationSMS?schId=' + 
  this.schid + '&msgtype=' + msgtype + '&qry=' + qry);
};
public getRegSessionListCurrent = function( ) {
  return this.httpClient.get(apiUrl + '/api/Registration/GetRegSessionListCurrent?schId=' + this.schid+'&mode='+2, httpOptions);
};
public UpdateRegistrationAmount = function (registrationamount: RegistrationAmount) {
  
  // registrationamount.schId = this.schid;
  return this.httpClient.post(apiUrl + '/api/Registration/UpdateRegistrationAmount', registrationamount, httpOptions);
};
RegistrationFeeReceipt(rfId, domainName){
  let SchoolId =  this.schid;
  return this.httpClient.post<Blob>(apiUrl + '/api/Registration/RegistrationFeeReceiptPDF?mode=1&qry='+rfId+'&schId='+ SchoolId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
MailRegistrationFeeReceipt( studentid, domainName){
  return this.httpClient.post(apiUrl + '/api/Registration/GetRegistrationFeeReceiptMailDetail?schId=' +
  this.schid +'&registrationid='+ studentid+'&domain='+domainName, httpOptions );
}
}
