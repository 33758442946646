import { NgModule, Component, ContentChild, OnInit, AfterViewInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControlName, NgForm } from '@angular/forms';

@Component({
  selector: '[validator]',
  template: `
    <ng-content></ng-content>
    <div *ngIf="formControl.invalid">
      
        <div *ngIf="formControl.errors.required && ( form.submitted || formControl.dirty)">
             <span class="label text-danger float-left" >Required Field</span>
        </div>
        <div *ngIf="formControl.errors.email && (form.submitted|| formControl.dirty)">
             <span class="label text-danger float-left "> Invalid email</span>
        </div>
        <div *ngIf="formControl.errors.notstring && ( form.submitted|| formControl.dirty)">
             <span class="label text-danger float-left">Invalid name </span>
        </div>
        <div *ngIf="formControl.errors.minlength && ( form.submitted|| formControl.dirty)">
              <span class="label text-danger float-left"> minimum {{formControl.errors.minlength.requiredLength}} characters reuired. </span>
        </div>
        <div *ngIf="formControl.errors.maxlength && ( form.submitted|| formControl.dirty)">
              <span class="label text-danger float-left"> maximum {{formControl.errors.maxlength.requiredLength}} characters reuired. </span>
        </div>
        <div *ngIf="formControl.errors.validReg && ( form.submitted|| formControl.dirty)">
              <span class="label text-danger float-left"> {{formControl.errors.validReg}}</span>
        </div>

    </div>

`,
styles: [
  `
    img {
      width: 20px;
      height: 20px;
    }
    span{
      font-size:12px!important;
    }
  `
]})

export class ValidatorComponent implements OnInit {
   @ContentChild(FormControlName) formControl;
   constructor(public form: NgForm) {

   }

   ngOnInit() { }

}
