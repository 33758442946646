import { Injectable } from '@angular/core';
import { Subject, observable, Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import {Country, State, District, City, Locality,  School,InvAccSetting  } from '../model/index';
import { User } from '../model/user.model';


@Injectable({
    providedIn: 'root'
  })
  export class SharedDataService {
      public schoolList: School[] = [];
      public invaccsettingList: InvAccSetting[] = [];      
  }
