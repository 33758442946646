import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StudentUserView } from '../../shared/model/StudentUserView.Model';
import { StudentUserListView } from '../../shared/model/StudentUserListView.Model';

@Component({
  selector: 'app-studentuserlist',
  templateUrl: './studentuserlist.component.html',
  styleUrls: ['./studentuserlist.component.scss'],
  
})
export class StudentUserlistComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public UserId:number;
  public message: string = null;
  public studentuserListForm: FormGroup;
  public p: number = 1;
  public model :any;
  masterSelected: boolean;
  activemasterSelected: boolean;
  checkedList: any;
  public StudentUserList: StudentUserView;
  public StudentUserListView: StudentUserListView;
  public IsAllChecked: boolean = false;
  
  public UserID: string = null;

  public usermodel: {
    stAdmissionNo: any,
    stClass: any,
    stClassName: any,
    stDOB: any,
    stEmail: any,
    stFatherName: any,
    stHouse: any,
    stHouseName: any,
    stId: any,
    stMobileNo: any,
    stMotherName: any,
    stName: any,
    stRegistrationNo: any,
    stRollNo: any,
    stSchoolName: any,
    stSection: any,
    stSectionName: any,
    stSession: any,
    stSessionName: any,
    stpass: any,
    stprevamount: any,
    strole: any,
    stuser: any,
    stuseractive:any

  }
  constructor(private router: Router, private stService: StudentService,
      private formBuilder: FormBuilder,private toast:ToastrService) {
        this.stService.getMasterList();
    this.UserId = JSON.parse(localStorage.getItem('currentUser'))["userId"];
    this.StudentUserList = new StudentUserView();
    this.StudentUserListView = new StudentUserListView();
    this.StudentUserList.StudentUserListView = [];
    
    this.usermodel= {
    stAdmissionNo: '',
    stClass: "",
    stClassName: "",
    stDOB: "",
    stEmail: "",
    stFatherName: "",
    stHouse: "",
    stHouseName: "",
    stId: "",
    stMobileNo: "",
    stMotherName: "",
    stName: "",
    stRegistrationNo: "",
    stRollNo: "",
    stSchoolName: "",
    stSection: "",
    stSectionName: "",
    stSession: "",
    stSessionName: "",
    stpass: "",
    stprevamount: "",
    strole: "",
    stuser: "",
    stuseractive:""

  }
      }

  ngOnInit() {
    this.studentuserListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: ['']
    });
    
    
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
   // this.loadStudent(true);
  }

  loadStudent(chk) {
    const data = { stSession: this.studentuserListForm.controls['stSession'].value?
                    this.studentuserListForm.controls['stSession'].value : 0,

                    stClass: this.studentuserListForm.controls['stClass'].value?
                  this.studentuserListForm.controls['stClass'].value : 0,

                  stSection: this.studentuserListForm.controls['stSection'].value?
                  this.studentuserListForm.controls['stSection'].value : 0,

                  UserId: this.UserId

                  };
    this.stService.GetStudentUserList(data.stClass, data.stSection, data.stSession).
          subscribe( m => {
            this.stList = m;
            console.log(this.stList);            
            this.stOrgList = m;
            this.p = 1;
            if (chk && m && m.length > 0 ) {
              this.studentuserListForm.controls['stSession'].setValue(m[0].stSession);
            }
          });
  }
  searchStudent() {
    if(this.studentuserListForm.controls['stSection'].value .length > 0 &&
    this.studentuserListForm.controls['stClass'].value.length === 0 ){
        this.toast.error("Please select class then section");

    } else {
      if (this.studentuserListForm.controls['stClass'].value && this.studentuserListForm.controls['stClass'].value .length > 0 ) {
        if (this.studentuserListForm.controls['stSection'].value &&
            this.studentuserListForm.controls['stSection'].value .length > 0)  {
              this.stList = this.stOrgList.filter(m => m.stClass == this.studentuserListForm.controls['stClass'].value
                            && m.stSection == this.studentuserListForm.controls['stSection'].value );
              this.orgList = this.stList;
        } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.studentuserListForm.controls['stClass'].value );
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }


   generateUserIDAllStudent(): Promise<any> {
   // var lastSuid = "";
    return new Promise((resolve) => {
      this.stList.forEach((data, index) => {
         if (data.stMobileNo != null && data.stEmail != null) {
          // this.stService.GetStudentUser(lastSuid).subscribe((response: any) => {
          this.stService.GetStudentUser(data.stAdmissionNo).subscribe((response: any) => {            
            if (response) {             
              this.stList[index].stuser = response[0]['UserID'];              
            }
          });
         }
        if (index == this.stList - 1) { resolve(); }
      })

       
    }).then(() => { 
           
    });
 
   }
  generatePasswordAllStudent() {
    return new Promise((resolve) => {
      this.stList.forEach((data, index) => {
        if (data.stpass == null && data.stMobileNo != null && data.stEmail != null) {
          this.stService.GetStudentPass(data.stAdmissionNo).subscribe((response: any) => {           
            if (response) {
              this.stList[index].stpass = response[0]['Pass'];            
            }
          });
        }
       if (index == this.stList - 1) { resolve(); }
    })

  }).then(() => {

  });
 
  }

  resetPassword(itm, i) {
        this.stService.GetStudentPass(itm.stAdmissionNo).subscribe((response: any) => {
          if (response) {
            itm.stpass = response[0]['Pass'];
            this.stList[i] = itm;
            this.toast.success('Password has been reset but save for future');
          }
      });
  }

  saveAll() {
    this.StudentUserList.StudentUserListView=[];
      this.stList.forEach((data) => {
        if (data.stuseractive == true  && data.is_edit==false) {
        this.StudentUserList.StudentUserListView.push({
          suu_suid: data.stId,
          suu_userid: data.stuser,
          suu_password: data.stpass,
          suu_active: data.stuseractive
        });
      }
      });
    console.log(this.StudentUserList);

    this.stService.SaveStudentUserPass(this.StudentUserList).subscribe((response: any) => {
            if (response) {
            this.toast.success(response.message);
            }
    });

  }
  save(itm,i) {   
    debugger;
    if(itm.is_edit==false){
    if (itm.stuseractive == true) { 
      this.StudentUserList.StudentUserListView.push({
        suu_suid: itm.stId,
        suu_userid: itm.stuser,
        suu_password: itm.stpass,
        suu_active: itm.stuseractive
      });
    }
    console.log(this.StudentUserList);

    this.stService.SaveStudentUserPass(this.StudentUserList).subscribe((response: any) => {
      if (response) {
       
        this.toast.success(response.message);
      }
    });
  }
  else
  {
    this.toast.error("Please select check box first");
    // (response.message);
  }

  }

  sentSMS(itm, i) {
    if (itm.stId > 0) {
      if (itm.is_edit == false) {
        this.stService.GetUserPassSMS(itm.stAdmissionNo, itm.stuser, itm.stpass).subscribe((response:any)=>{
        if (response) {
          itm.is_Updated = true;
          itm.Action = response;
          this.stList[i] = itm;
          this.toast.success(response);
        } else {
          itm.is_Updated = false;
          itm.Action = response;          
        }
      });           
    }
    }
  }
  sentSMSAll() {
    return new Promise((resolve) => {
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.stService.GetUserPassSMS(itm.stAdmissionNo, itm.stuser, itm.stpass).subscribe((response:any)=>{
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            // this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;            
          }
        });
       
      }
    }
    }).then(() => {

    });
  }



  sentEmail(itm, i) {
    if (itm.stId > 0) {
      if (itm.is_edit == false) {
        this.stService.GetUserPassEmail(itm.stAdmissionNo, itm.stuser, itm.stpass).subscribe((response: any) => {
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;
          }
        });
      }
    }
  }
  sentEmailAll() {
    return new Promise((resolve) => {
      for (let itm of this.stList) {
        if (itm.is_edit == false) {
          this.stService.GetUserPassEmail(itm.stAdmissionNo, itm.stuser, itm.stpass).subscribe((response: any) => {
            if (response) {
              itm.is_Updated = true;
              itm.Action = response;
              this.toast.success(response);
            } else {
              itm.is_Updated = false;
              itm.Action = response;
            }
          });

        }
      }
    }).then(() => {

    });
  }


  isAllSelected(id: number, property: boolean, event: any, data) {
    this.masterSelected = false;
    this.masterSelected = this.stList.every(function (item: any) {
      return item.isSelected === true;
    })
    if (property === false) {
      const editField = event.target.textContent;
      data.is_edit = true;
      this.stList[id] = data;
    } else {
      const editField = event.target.textContent;
      data.is_edit = false;
      this.stList[id] = data;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.stList.length; i++) {
      if (this.stList[i].isSelected) {
        this.checkedList.push(this.stList[i]);
      }
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }

  getactiveCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.stList.length; i++) {
      if (this.stList[i].stuseractive) {
        this.checkedList.push(this.stList[i]);
      }
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }



  checkUncheckAll() {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected; 
    }
  }

  checkChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected;
      if (property === true){
        this.stList[i].is_edit = false ;
      } else {
        this.stList[i].is_edit = true;
      }
    }
    this.getCheckedItemList();

  }

  checkactiveChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].stuseractive = this.activemasterSelected;
      if (property === true){
        this.stList[i].is_edit = false ;
      } else {
        this.stList[i].is_edit = true;
      }
    }
    this.getactiveCheckedItemList();

  }

  checkCancelBox(itm, i, value) {        
    itm.stuseractive = value.target.checked;
    itm.IsChecked = false;
    this.stList[i] = itm;

  }

}
