import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../shared/services/master.service';

@Component({
  selector: 'app-studentattendencelist',
  templateUrl: './studentattendencelist.component.html',
  styleUrls: ['./studentattendencelist.component.scss'],
})
export class StudentAttendencelistComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public studentattendenceListForm: FormGroup;
  public p:number =1;

  public userclassList: any = [];
  public usersectionList: any = [];
  public sessionList: any =[];

  constructor(private router: Router, private stService: StudentService, private masterService: MasterService,
      private formBuilder: FormBuilder,private toast:ToastrService) {
        this.stService.getMasterList();
      }

  ngOnInit() {
    const dt = new Date();
    this.studentattendenceListForm = this.formBuilder.group({
      date: [{year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()}],
      stClass: [''],
      stSection: [''],
      stSession: ['']
    });    
    
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
    this.masterService.getSessionListCurrent().subscribe(lst => { this.sessionList = lst });
  }
  loadclass() {
    var classId = 0;
    var sectionId = 0;
    this.masterService.getUserWiseClassList(classId,sectionId).subscribe(lst => {this.userclassList = lst; });
  }
  loadsection() {
    var classId = 0;
    var sectionId = 0;
    var classId = Number(this.studentattendenceListForm.controls['stClass'].value);
    var sectionId = Number(this.studentattendenceListForm.controls['stSection'].value);
    this.masterService.getUserWiseSectionList(classId,sectionId).subscribe(lst => {this.usersectionList = lst; });
  }
  loadStudent() {
    let Date = this.studentattendenceListForm.controls['date'].value;
        if (Date !=null && Date !== undefined){
        let CurrentDate = Date.year + '/' + Date.month + '/' + Date.day;
        this.studentattendenceListForm.controls['date'].setValue(CurrentDate);
        } else {
          this.studentattendenceListForm.controls['date'].setValue(null);
        }
    const data = { stSession: this.studentattendenceListForm.controls['stSession'].value ?
                    this.studentattendenceListForm.controls['stSession'].value : 0,

                    stClass: this.studentattendenceListForm.controls['stClass'].value ?
                  this.studentattendenceListForm.controls['stClass'].value : 0,

                  stSection: this.studentattendenceListForm.controls['stSection'].value ?
                  this.studentattendenceListForm.controls['stSection'].value : 0,

                  date: this.studentattendenceListForm.controls['date'].value ?
                  this.studentattendenceListForm.controls['date'].value : null,
                  

                  };
                  if(data.stSession != null && data.stClass != null && data.stSection != null && data.date != null){
                    this.stService.GetStudentAttendenceList(data.stSession,data.stClass, data.stSection, data.date).
                    subscribe( m => {
                      this.stList = m;
                      this.stOrgList = m;
                      // this.p=1;
                      // if (chk && m && m.length > 0 ) {
                      //   this.studentattendenceListForm.controls['stSession'].setValue(m[0].stSession);
                      // }1
                    });
                  }
    
  }
  searchStudent() {
    if(this.studentattendenceListForm.controls['stSection'].value .length > 0 && 
    this.studentattendenceListForm.controls['stClass'].value.length ==0 ){
        this.toast.error("Please select class then section");
        
    }else{
      if (this.studentattendenceListForm.controls['stClass'].value && 
      this.studentattendenceListForm.controls['stClass'].value .length > 0 ) {
        if (this.studentattendenceListForm.controls['stSection'].value &&
            this.studentattendenceListForm.controls['stSection'].value .length > 0)  {
              this.stList = this.stOrgList.filter(m => m.stClass == this.studentattendenceListForm.controls['stClass'].value
                            && m.stSection == this.studentattendenceListForm.controls['stSection'].value );
              this.orgList = this.stList;
        } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.studentattendenceListForm.controls['stClass'].value );
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
  addStudent() {
    this.router.navigate(['/studentattendence']);
  }
  editStudent(itm) {
    const data = { stSession: this.studentattendenceListForm.controls['stSession'].value ?
                    this.studentattendenceListForm.controls['stSession'].value : 0,

                    stClass: this.studentattendenceListForm.controls['stClass'].value ?
                  this.studentattendenceListForm.controls['stClass'].value : 0,

                  stSection: this.studentattendenceListForm.controls['stSection'].value ?
                  this.studentattendenceListForm.controls['stSection'].value : 0
                  };
                  if (data.stSession > 0 && data.stClass > 0 && data.stSection > 0){              
                      if (itm.statId > 0) {
                        this.router.navigate(['/studentattendence',
                          {
                            id: itm.statId,
                            ssid: this.studentattendenceListForm.controls['stSession'].value,
                            sdid: this.studentattendenceListForm.controls['stClass'].value,
                            csdate: itm.statDate,
                            csid: this.studentattendenceListForm.controls['stSection'].value}]);
                      }
                    }
  }

}
