export class StudentFeeReceiptDetailList {
     frd_MonthName :  string  
     frd_Year : string 
     frd_fhsid : number 
     frd_fname :  string 
     frd_newstudentdiscount : number 
     frd_dayscholordiscount : number
     frd_dayboardingdiscount : number 
     frd_hosteldiscount : number 
     frd_ewsdiscount : number
     frd_staffdiscount : number
     frd_scholorshipdiscount : number
     frd_girldiscount : number
     frd_siblingdiscount : number
     frd_individualdiscount : number
     frd_totalval : number 
     frd_totaldiscount :number
     frd_remainingval : number 
     paidval : number
     pendingval : number
     frd_paidval : number
     frd_status :  string 
}
