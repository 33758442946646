import { Directive, ElementRef, HostListener, Input } from '@angular/core';
    @Directive({
        selector: '[appInputRestriction]'
    })
    export class InputRestrictionDirective {
        inputElement: ElementRef;
        arabicRegex = '[\u0600-\u06FF]';
        constructor(el: ElementRef) {
            this.inputElement = el;
        }

        @HostListener('keypress', ['$event']) onKeyPress(event) {
            const e = <KeyboardEvent>event;
            if (e.key === 'Tab' || e.key === 'TAB') {
                return;
            }
            let k;
            k = event.keyCode;  // k = event.charCode;  (Both can be used)
            if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
                return;
            }
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.arabicRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        @HostListener('paste', ['$event']) onPaste(event) {
            const regex = /[a-zA-Z0-9\u0600-\u06FF]/g;
            const e = <ClipboardEvent>event;
            const pasteData = e.clipboardData.getData('text/plain');
            let m;
            let matches = 0;
            while ((m = regex.exec(pasteData)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    matches++;
                });
            }
            if (matches === pasteData.length) {
                return;
            } else {
                e.preventDefault();
            }
        }

    }
