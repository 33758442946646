
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { first } from 'rxjs/operators';
import { User } from '../shared/model/user.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'nurseryloginall.component.html'
})
export class NurseryLoginAllComponent implements OnInit {
    nurseryloginallForm: FormGroup;
    showError: Boolean = false;
    errorMsg: string = null;
    returnUrl: string;
    returnUrlregistration: string;
    public role: string;
    public schoolId: number;
    user: string = null;
    pass: string = null;
    constructor(public router: Router, private formBuilder: FormBuilder,
        private loginService: LoginService, private route: ActivatedRoute,private toast:ToastrService
    ) { }

    ngOnInit() {
        this.nurseryloginallForm = this.formBuilder.group({
           SchId: ['', [Validators.required]]

        });
        // if (this.loginService.currentUserValue) {
        //     this.router.navigate(['/dashboard']);
        // }

       // if (this.role !== 'Nursery' ||  'Registration') {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
      //  } else {
      //       this.returnUrlregistration = this.route.snapshot.queryParams['returnUrlregistration'] || '/dashboardregistration';
      //  }

    }
    onSubmit() {
        if (this.nurseryloginallForm.invalid) {
            return;
        }
         this.schoolId = this.nurseryloginallForm.controls['SchId'].value;        

        if (this.nurseryloginallForm.controls['SchId'].value === "1" ) {
            this.user ="demonurseryonline"
            this.pass = "2021"           
        }
        if (this.nurseryloginallForm.controls['SchId'].value === "2" ) {
            this.user ="ramjasonlinenurseryregistration"
            this.pass = "110022"
           
        }
        
        // this.loginService.login(this.nurseryloginallForm.controls.email.value, this.nurseryloginallForm.controls.password.value)
        this.loginService.login(this.user, this.pass)
        .pipe(first())
            .subscribe(
                (data: User) => {
                    if (data.access_token) {
                        localStorage.setItem('isLoggedin', 'true');
                        localStorage.setItem('authToken', data.access_token);

                        this.role = this.loginService.currentUserValue.roles;
                        if (this.role === 'OnlineNursery') {
                            this.router.navigate(['/nurseryonlineregistration']);
                        }
                        // if (this.role !== 'Registration') {
                        //     this.router.navigate(['/onlineregistration']);

                        // } else {
                        //     this.router.navigate([this.returnUrl]);
                        // }

                    } else {
                        this.toast.error('Nursery Registration Is Over',"Error");
                    }
                },
                error => {
                    this.toast.error('Nursery Registration Is Over',"Error");
                });
    }
}
