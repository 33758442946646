
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { first } from 'rxjs/operators';
import { User } from '../shared/model/user.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    showError: Boolean = false;
    errorMsg: string = null;
    returnUrl: string;
    public role: string;
    constructor(public router: Router, private formBuilder: FormBuilder,
        private loginService: LoginService, private route: ActivatedRoute,private toast:ToastrService
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(3)]],
            email: ['', [Validators.required, Validators.minLength(4)]]
        });
        // if (this.loginService.currentUserValue) {
        //     this.router.navigate(['/dashboard']);
        // }

       if (this.role === 'Registration') {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardregistration';
        } else if (this.role === 'Teacher' || this.role === 'Teacher1To2' || this.role === 'Teacher3To10' || this.role === 'Teacher11To12') {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardteacher';
        } else if (this.role === 'ClassTeacher' || this.role === 'ClassTeacher1To2' || this.role === 'ClassTeacher3To10' || this.role === 'ClassTeacher11To12' || this.role === 'ExamIncharge' || this.role === 'ExamIncharge1') {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardclassteacher';
        } else if (this.role === 'Student') {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardstudent';
        } else {
             this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
       }

    }
    onSubmit() {
        if (this.loginForm.invalid) {
            return;
        }
        this.loginService.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value)
            .pipe(first())
            .subscribe(
                (data: User) => {
                    if (data.access_token) {
                        localStorage.setItem('isLoggedin', 'true');
                        localStorage.setItem('authToken', data.access_token);

                        this.role = this.loginService.currentUserValue.roles;
                        if (this.role === 'Registration') {
                             this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardregistration';
                        } else if (this.role === 'ClassTeacher' || this.role === 'ClassTeacher1To2' || this.role === 'ClassTeacher3To10' || this.role === 'ClassTeacher11To12' || this.role === 'ExamIncharge' || this.role === 'ExamIncharge1') {
                                this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardclassteacher';
                        } else if (this.role === 'Teacher' || this.role === 'Teacher1To2' || this.role === 'Teacher3To10' || this.role === 'Teacher11To12') {
                            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardteacher';
                        } else if (this.role === 'Student') {
                            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardstudent';
                        } else {
                              this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
                        }

                        this.router.navigate([this.returnUrl]);

                    } else {
                        this.toast.error('Invalid User name/Password',"Error");
                    }
                },
                error => {
                    this.toast.error('Invalid User name/Password',"Error");
                });
    }
}
