import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy,CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './login/login.component';
import { RegistrationLoginAllComponent } from './registrationloginall/registrationloginall.component';
import { NurseryLoginAllComponent } from './nurseryloginall/nurseryloginall.component';
import { RamjasNurseryLoginComponent } from './ramjasnurserylogin/ramjasnurserylogin.component';
import { RamjasRegistrationLoginComponent } from './ramjasregistrationlogin/ramjasregistrationlogin.component';
import { DPSKaluahiRegistrationLoginComponent } from './dpskaluahiregistrationlogin/dpskaluahiregistrationlogin.component';
import { ForgotpwdComponent } from './views/forgot/forgotpwd.component';
import { StudentForgotpwdComponent } from './studentforgot/studentforgotpwd.component';
import { StudentlistComponent } from './views/student/studentlist.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { RegistrationlistComponent } from './views/registration/registrationlist.component';
import { ReportsComponent } from './views/reports/reports.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SharedModule } from './shared/sharedmodule/shared.module';
import { NgHttpLoaderModule } from 'ng-http-loader';

//import { UiSwitchModule } from 'ngx-ui-switch';
//import { NgbDateFRParserFormatter } from './shared/ngb-date-parser-formatter';
// import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import {LoginService} from './shared/services/login.service';
import {StudentService} from './shared/services/student.service';
import {RegistrationService} from './shared/services/registration.service';
import {SharedDataService} from './shared/services/shareddata.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { AuthGuard } from './shared/guard/auth.guard';
import { HttpErrorInterceptor } from './shared/interceptor/error.interceptor';
import { GroupByPipe } from './shared/pipes/group.pipe';
// import { SearchPipe } from './shared/pipes/search.pipe';
import { NgxPaginationModule} from 'ngx-pagination';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { GrdFilterPipe } from './shared/pipes/grid.pipe';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrModule } from 'ngx-toastr';

import { UpdateStudentComponent } from './views/updatestudent/updatestudent.component';
import { UpdatePrevAmtComponent } from './views/updateprevamt/updateprevamt.component';
import { UpdateStudentMonthFeeComponent } from './views/updatetransport/updatestudentmonthfee.component';
import { FeeReceiptService } from './shared/services/feereceipt.service';
import { FeeReceiptComponent } from './views/fee-receipt/fee-receipt.component';
import { FeeReceiptOnlineComponent } from './views/fee-receipt-online/fee-receipt-online.component';
import { PaymentHistoryComponent } from './views/fee-receipt/payment-history/payment-history.component';
import { ModalService } from './_modal';
import { ModalModule } from './_modal';
import { FeeReceiptStatusComponent } from './views/fee-receipt-status/fee-receipt-status.component';
import { PendingStudentlistComponent } from './views/pendingstudentlist/pendingstudentlist.component';
import { GeneralSMSComponent } from './views/generalsms/generalsms.component';
import { GeneralSMSMAILComponent } from './views/generalsmsmail/generalsmsmail.component';
import { StudentAttendencelistComponent } from './views/studentattendence/studentattendencelist.component';
import { StudentattendenceComponent } from './views/studentattendence/studentattendence.component';
// import { OnlineRegistrationComponent } from './views/nurseryonlineregistration/onlineregistration.component';
import { RegistrationlistPointWiseComponent } from './views/registrationlistpointwise/registrationlistpointwise.component';
import { RegistrationlistExportComponent } from './views/registrationlistexport/registrationlistexport.component';
import { RegistrationSMSComponent } from './views/registrationsms/registrationsms.component';
import { StudentUserlistComponent } from './views/studentuserlist/studentuserlist.component';
import { UpdateStudentSelfComponent } from './views/updatestudentself/updatestudentself.component';
import { StudentFeeReceiptComponent} from './views/student-fee-receipt/student-fee-receipt.component';
import { SubSubjectComponent } from './views/subsubject/subsubject.component';
import { EmployeeAttendencelistComponent } from './views/employeeattendence/employeeattendencelist.component';
import { EmployeeattendenceComponent } from './views/employeeattendence/employeeattendence.component';
import { UserattendenceComponent } from './views/userattendence/userattendence.component';
import { PrintStudentComponent } from './views/printstudent/printstudent.component';
import { StudentExportComponent } from './views/studentexport/studentexport.component';
import { PrintExam1To2ResultComponent } from './views/printexam1To2result/printexam1To2result.component';
import { PrintExam3To10ResultComponent } from './views/printexam3To10result/printexam3To10result.component';
import { PrintExam11To12ResultComponent } from './views/printexam11To12result/printexam11To12result.component';
import { StudentExportConsolidatedReportCardComponent } from './views/studentexportconsolidatedreportcard/studentexportconsolidatedreportcard.component';
import { StudentExportAverageReportCardComponent } from './views/studentexportaveragereportcard/studentexportaveragereportcard.component';
import { PrintAdmitCardComponent } from './views/printadmitcard/printadmitcard.component';
import { InventoryService } from './shared/services/inventory.service';
import { RegistrationfeelistComponent } from './views/registrationfeelist/registrationfeelist.component';
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule ,
    NgHttpLoaderModule.forRoot(),
    LanguageTranslationModule,
    HttpClientModule,
    UiSwitchModule,
    NgbDropdownModule,
    NgxPaginationModule,
    NgbModule,
    ModalModule,
    ToastrModule.forRoot({enableHtml: true})
    // ChartsModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegistrationLoginAllComponent,
    NurseryLoginAllComponent,
    RamjasNurseryLoginComponent,
    RamjasRegistrationLoginComponent,
    DPSKaluahiRegistrationLoginComponent,
    StudentForgotpwdComponent,
    StudentlistComponent,
    ChangePasswordComponent,
    RegistrationlistComponent,
    ReportsComponent,
    GroupByPipe, GrdFilterPipe, UpdateStudentComponent, UpdatePrevAmtComponent,
    UpdateStudentMonthFeeComponent, FeeReceiptComponent, FeeReceiptOnlineComponent, PaymentHistoryComponent, FeeReceiptStatusComponent,
    PendingStudentlistComponent, GeneralSMSComponent, GeneralSMSMAILComponent, StudentAttendencelistComponent, StudentattendenceComponent,
    RegistrationlistPointWiseComponent,RegistrationlistExportComponent, RegistrationSMSComponent, StudentUserlistComponent, ForgotpwdComponent, UpdateStudentSelfComponent,
    StudentFeeReceiptComponent, SubSubjectComponent, EmployeeAttendencelistComponent, EmployeeattendenceComponent, UserattendenceComponent,
    PrintStudentComponent,StudentExportComponent,PrintExam1To2ResultComponent,PrintExam3To10ResultComponent,PrintExam11To12ResultComponent,
    StudentExportConsolidatedReportCardComponent,StudentExportAverageReportCardComponent,PrintAdmitCardComponent,RegistrationfeelistComponent
    
    
    // OnlineRegistrationComponent
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
     AuthGuard, StudentService, LoginService, SharedDataService, RegistrationService, FeeReceiptService,
     ModalService,InventoryService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
