import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../../shared/services/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.scss'],
  
})
export class ForgotpwdComponent implements OnInit {
  forgotForm: FormGroup;

  constructor(private loginService: LoginService, private formBuilder: FormBuilder,private toastr:ToastrService) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(4)]]
  });
  }

  onSubmit() {
    if (this.forgotForm.invalid) {
        return;
    }

      this.loginService.forgotpassword(this.forgotForm.controls.email.value).subscribe((response:any)=>{
        if (response === true) {
          this.toastr.success("New Password has been sent on your registered mail id.");
        } else {
          alert('User does not exist');
        }
      });

  }
}
