export class StudentAttendenceWithDetails {
  AttendenceId: number;
  AttendenceDate: string;
  SessionId: number;
  Sdid: number;
  Csid: number;
  StudentAttendenceDetails: StudentAttendenceDetails[];
  StudentAttendence: StudentAttendence;
}

export class StudentAttendenceDetails{
  atd_suid: number;
  atd_present: boolean;
}

export class StudentAttendence {
  at_atid: number;
}
