import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-generalsms',
  templateUrl: './generalsms.component.html',
  styleUrls: ['./generalsms.component.scss'],
  
})
export class GeneralSMSComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public UserId:number;
  public message: string = null;
  public generalsmsForm: FormGroup;
  public p:number =1;
  masterSelected: boolean;
  checkedList: any;
  public IsAllChecked: boolean = false;
  constructor(private router: Router, private stService: StudentService,
      private formBuilder: FormBuilder,private toast:ToastrService) {
        this.stService.getMasterList();
        this.UserId = JSON.parse(localStorage.getItem('currentUser'))["userId"];
      }

  ngOnInit() {
    this.generalsmsForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: [''],
      remarks: [''],
    });
    
    
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
   // this.loadStudent(true);
  }

  loadStudent(chk) {
    const data = { stSession: this.generalsmsForm.controls['stSession'].value?
                    this.generalsmsForm.controls['stSession'].value : 0,

                    stClass: this.generalsmsForm.controls['stClass'].value?
                  this.generalsmsForm.controls['stClass'].value : 0,

                  stSection: this.generalsmsForm.controls['stSection'].value?
                  this.generalsmsForm.controls['stSection'].value : 0,

                  UserId: this.UserId,

                  remarks: this.generalsmsForm.controls['remarks'].value?
                  this.generalsmsForm.controls['remarks'].value : ''
                  };
    this.stService.getStudentList(data.stClass, data.stSection, data.stSession).
          subscribe( m => {
            this.stList = m;
            this.stOrgList = m;
            this.p = 1;
            if (chk && m && m.length > 0 ) {
              this.generalsmsForm.controls['stSession'].setValue(m[0].stSession);
            }
          });
  }
  searchStudent() {
    if(this.generalsmsForm.controls['stSection'].value .length > 0 &&
    this.generalsmsForm.controls['stClass'].value.length === 0 ){
        this.toast.error("Please select class then section");

    } else {
      if (this.generalsmsForm.controls['stClass'].value && this.generalsmsForm.controls['stClass'].value .length > 0 ) {
        if (this.generalsmsForm.controls['stSection'].value &&
            this.generalsmsForm.controls['stSection'].value .length > 0)  {
              this.stList = this.stOrgList.filter(m => m.stClass == this.generalsmsForm.controls['stClass'].value
                            && m.stSection == this.generalsmsForm.controls['stSection'].value );
              this.orgList = this.stList;
        } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.generalsmsForm.controls['stClass'].value );
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
  // addStudent() {
  //   this.router.navigate(['/student']);
  // }
  editStudent(itm, i) {
    const data = { remarks: this.generalsmsForm.controls['remarks'].value?
    this.generalsmsForm.controls['remarks'].value : ''
    };
    if (itm.stId > 0) {
      if (itm.is_edit == false) {
        this.stService.GetGeneralSMS(itm.stMobileNo, data.remarks).subscribe((response:any)=>{
        if (response) {
          itm.is_Updated = true;
          itm.Action = response;
          this.stList[i] = itm;
          this.toast.success(response);
        } else {
          itm.is_Updated = false;
          itm.Action = response;
          // alert('Message not sent');
        }
      });        
    
    }
    }
  }
  editAllStudent(i) {
    const data = { remarks: this.generalsmsForm.controls['remarks'].value?
    this.generalsmsForm.controls['remarks'].value : ''
    };
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.stService.GetGeneralSMS(itm.stMobileNo, data.remarks).subscribe((response:any)=>{
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;
            // alert('Message not sent');
          }
        });
      
      }
    }
  }

  isAllSelected(id: number, property: boolean, event: any, data) {
    this.masterSelected = false;
    this.masterSelected = this.stList.every(function (item: any) {
      return item.isSelected === true;
    })
    if (property === false) {
      const editField = event.target.textContent;
      data.is_edit = true;
      this.stList[id] = data;
    } else {
      const editField = event.target.textContent;
      data.is_edit = false;
      this.stList[id] = data;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.stList.length; i++) {
      if (this.stList[i].isSelected) {
        this.checkedList.push(this.stList[i]);
      }
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }
  checkUncheckAll() {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected; 
    }
  }

  checkChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected;
      if (property === true){
        this.stList[i].is_edit = false ;
      } else {
        this.stList[i].is_edit = true;
      }
    }
    this.getCheckedItemList();

  }

}
