import { DecimalPipe } from '@angular/common'

export class StudentFeeReceipt {
     fr_suid : number; 
     fr_modeofpayment :  string;  
     fr_chqtranno :  number;  
     fr_chqtrandt :  string; 
     fr_bkid : number; 
     fr_Totpaidamt : string; 
     fr_rmrks :  string; 
     fr_chqbounced : boolean = false; 
     fr_chqcleared :boolean = false ;
     fr_active : boolean = true;
}
