import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../shared/services/master.service';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-employeeattendencelist',
  templateUrl: './employeeattendencelist.component.html',
  styleUrls: ['./employeeattendencelist.component.scss'],
})
export class EmployeeAttendencelistComponent implements OnInit {
  public usersList: any[] = [];
  public stOrgList: any = [];
  public stList: any = []; 
  public employeeattendenceListForm: FormGroup;
  
  public imgList: any = []; 
  public pdfSrc: any ;
  public imgSrc: any ;  
  public downloadData: any = null;
  public fileName: string = null;
  @ViewChild('content') content: ElementRef;
  constructor(private modalService: NgbModal,private router: Router, private stService: StudentService,private usersService: MasterService,
      private formBuilder: FormBuilder,private toast:ToastrService,public http:HttpClient) {
       // this.stService.getMasterList();
      }

  ngOnInit() {
    const dt = new Date();
    this.employeeattendenceListForm = this.formBuilder.group({
      date: [{year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()}],      
      stUser: ['']
    });    
    this.usersService.getSchoolUsersList().subscribe(ust => { this.usersList = ust; });  
    
  }

  loadAttendence() {
    let Date = this.employeeattendenceListForm.controls['date'].value;
        if (Date !=null && Date !== undefined){
        let CurrentDate = Date.year + '/' + Date.month + '/' + Date.day;
        this.employeeattendenceListForm.controls['date'].setValue(CurrentDate);
        } else {
          this.employeeattendenceListForm.controls['date'].setValue(null);
        }
    const data = { stUser: this.employeeattendenceListForm.controls['stUser'].value ?
                  this.employeeattendenceListForm.controls['stUser'].value : 0,

                  date: this.employeeattendenceListForm.controls['date'].value ?
                  this.employeeattendenceListForm.controls['date'].value : null,
                  

                  };
                  if(data.stUser != 0 && data.date != null){
                    this.stService.getUserAttendence(data.stUser, data.date).
                    subscribe( m => {
                      this.stList = m;
                      this.stOrgList = m;                     
                    });
                  }else if(data.stUser==0 && data.date != null){  
                    this.stService.getAllUserAttendence(0, data.date).
                    subscribe( m => {
                      this.stList = m;
                      this.stOrgList = m;                     
                    });
                  }
    
  }
  
  addAttendence() {
    this.router.navigate(['/employeeattendence']);
  }
  editAttendence(itm) {
    if (itm.us_atid > 0) {
      this.router.navigate(['/employeeattendence',
        {
          id: itm.us_atid,
          usid: itm.us_usid,
          csdate: itm.us_atdt}]);
    }
  }

  uploadPreview(index) {
    const dataUrl = this.imgList[index];
    if (dataUrl && dataUrl.length > 0) {
      const ext = dataUrl.split('.').pop();
      this.fileName = dataUrl.split('/').pop();
      if (ext == 'pdf') {     
        this.imgSrc = null;
        this.http.get(environment.appUrl + '/api/Registration/GetFile?fileName=' + dataUrl, { responseType : 'arraybuffer' as 'json'})
           .subscribe((res: any ) => {
            const ur = URL.createObjectURL(new Blob([res], {
              type: 'application/pdf'
            }));
            this.pdfSrc = ur; 
            this.imgSrc = null;
            this.downloadData = ur;        
          });
      } else {
        this.pdfSrc = null;
        this.imgSrc = environment.appUrl + dataUrl;
        this.http.get(environment.appUrl + '/api/Registration/GetFile?fileName=' + dataUrl, { responseType : 'arraybuffer' as 'json'})
        .subscribe((res: any ) => {
         const ur = URL.createObjectURL(new Blob([res],
          {type: 'image/'+ this.fileName.split('.').pop()}));
         this.downloadData = ur;     
       });
      }
      this.open();
    }
  }

  editLogin(itm) {
    if (itm.us_atid > 0) {
      this.imgList[0] = itm.us_photo;    
      this.uploadPreview(0); 
    }
  }

  editLogout(itm) {
    if (itm.us_atid > 0) {
      this.imgList[1] = itm.us_logoutphoto;   
      this.uploadPreview(1);   
    }
  }

  open() {
    this.modalService.open(this.content).result.then((result) => {
        
    }, (reason) => {
       
    });
  }
}
