import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterService } from '../../shared/services/master.service';
import { LoginService } from 'app/shared/services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public changepasswordForm: FormGroup;
  public role: string;
  constructor(private router: Router, private formBuilder: FormBuilder, 
    private passwordService: MasterService, private loginService: LoginService) {
    this.role = this.loginService.currentUserValue.roles;
    this.changepasswordForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      oldpassword: ['', [Validators.required]],
      newpassword: ['', [Validators.required]]
    });
   }

  ngOnInit() {
  }
  onSubmit() {
    if (this.changepasswordForm.invalid) {
      return;
    }
    const data: any = { 
                username: this.changepasswordForm.controls['username'].value,
                oldpassword: this.changepasswordForm.controls['oldpassword'].value,
                newpassword: this.changepasswordForm.controls['newpassword'].value,

    };
      if (this.role === 'Student') {
        this.passwordService.ChangePasswordStudent(data).subscribe(res => {
          if (res) {
            alert('Password changed');

          } else {
            alert('Record not updated');
          }
        });
      } else  if (this.role !== 'Registration' || 'Student') {
        this.passwordService.ChangePassword(data).subscribe(res => {
          if (res) {
            alert('Password changed');

          } else {
            alert('Record not updated');
          }
        });
      }
    
  }

  cancel(){
    this.router.navigate(['/login']);
  }
}
