import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {LoginService} from './login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Country, State, District, City, Locality, FeeHeadAll, SchoolClass, SchoolClassSection, SchoolHouse, School,
        Bus, BusRoute, BusStop, Bank, Role, Group, Menu, Mail, Message, PointDescription, Holiday, Users,
        PointType, Report, ReportDetail, SchoolMonthQuarWithDetail, NotificationType, Notification, ExamArea,Attendencelocation,Alert,
        AlertType, Designation, Employee, ExamType, ExamSubType, ExamRemarks, ExamPointGrade, 
        ExamGrade,ExamGradeScale, classteacher, Leave, LeaveType, LeaveApprove } from '../../shared/model/index';
import { SharedDataService } from './shareddata.service';
import { SubSubject } from '../model/index';
import { User } from '../model/user.model';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})

};
const apiUrl = environment.appUrl;

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  public schoolId: number;
  public currentUserId = (<User>this.loginService.currentUserSubject.value ).userId;
  
  constructor(private loginService: LoginService, private httpClient: HttpClient,
    public sharedData: SharedDataService) {
    this.loginService.currentUserSubject.subscribe(m=>{
      this.schoolId = m?m.schoolId:0;
    })
  }
  public getCountryList = function( ) {
    return this.httpClient.get(apiUrl + '/api/general/GetCountryList');
  };
  public manageCountry = function (country: Country) {
    country.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/general/ManageCountry', country, httpOptions);
  };
  public getStateList = function( ) {
    return this.httpClient.get(apiUrl + '/api/general/GetStateList');
  };
  public manageState = function( state: State) {
    state.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/general/ManageState', state, httpOptions);
  };
  public getDistrictList = function( ) {
    return this.httpClient.get(apiUrl + '/api/general/GetDistrictList');
  };
  public manageDistrict = function (district: District) {
    district.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/general/ManageDistrict', district, httpOptions);
  }; 
  public getCityList = function( ) {
    return this.httpClient.get(apiUrl + '/api/general/GetCityList');
  };
  public getCityListMasterPage = function( ) {
    return this.httpClient.get(apiUrl + '/api/general/GetCityList?mode=' + 1);
  };
  public manageCity = function (city: City) {
    city.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/general/ManageCity', city, httpOptions);
  };
  public getLocalityList = function() {

    return this.httpClient.get(apiUrl + '/api/general/GetLocalityList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getLocalityListMasterPage = function() {
    return this.httpClient.get(apiUrl + '/api/general/GetLocalityList?schId=' + this.schoolId.toString()+'&mode=' + 1, httpOptions);
  };
  public manageLocality = function (locality: Locality) {
    locality.userId = this.loginService.currentUserSubject.value.userId;
    locality.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/general/ManageLocality', locality, httpOptions);
  };

  public getSchoolClassList = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetSchoolClassList?schId=' + this.schoolId.toString(), httpOptions);
  };

  public getSchoolClassListMasterPage = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetSchoolClassList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
    
  public manageSchoolClass = function (schoolclass: SchoolClass) {
    schoolclass.userId = this.loginService.currentUserSubject.value.userId;
    schoolclass.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Master/ManageSchoolClass', schoolclass, httpOptions);
  };
  public getSchoolClassSectionList = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetSchoolClassSectionList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getSchoolClassSectionListMasterPage = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetSchoolClassSectionList?schId=' + this.schoolId.toString()+'&mode='+ 1, httpOptions);
  };
  public manageSchoolClassSection = function (schoolclasssection: SchoolClassSection) {
    schoolclasssection.userId = this.loginService.currentUserSubject.value.userId;
    schoolclasssection.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Master/ManageSchoolClassSection', schoolclasssection, httpOptions);
  };
  public getSchoolHouseList = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetSchoolHouseList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getSchoolHouseListMasterPage = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetSchoolHouseList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageSchoolHouse = function (schoolhouse: SchoolHouse) {
    schoolhouse.userId = this.loginService.currentUserSubject.value.userId;
    schoolhouse.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Master/ManageSchoolHouse', schoolhouse, httpOptions);
  };
  public getBusList = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetBusList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getBusListMasterPage = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetBusList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageBus = function (bus: Bus) {
    bus.userId = this.loginService.currentUserSubject.value.userId;
    bus.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Master/ManageBus', bus, httpOptions);
  };
  public getBusRouteList = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetBusRouteList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getBusRouteListMasterPage = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetBusRouteList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageBusRoute = function (busroute: BusRoute) {
    busroute.userId = this.loginService.currentUserSubject.value.userId;
    busroute.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Master/ManageBusRoute', busroute, httpOptions);
  };
  public getBusStopList = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetBusStopList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getBusStopListMasterPage = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetBusStopList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageBusStop = function (busstop: BusStop) {
    busstop.userId = this.loginService.currentUserSubject.value.userId;
    busstop.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Master/ManageBusStop', busstop, httpOptions);
  };
  public getBankList = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetBankList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getBankListMasterPage = function() {
    return this.httpClient.get(apiUrl + '/api/Master/GetBankList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageBank = function (bank: Bank) {
    bank.userId = this.loginService.currentUserSubject.value.userId;
    bank.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Master/ManageBank', bank, httpOptions);
  };

  public getFeeHeadAllList = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetFeeHeadAllList');
  };
  public manageFeeHeadAll = function (feeheadall: FeeHeadAll) {
    feeheadall.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/systemsetting/ManageFeeHeadAll', feeheadall, httpOptions);
  };
  public getRoleList = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetRoleList');
  };
  public getSchoolRoleList = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetSchoolRoleList');
  };
  public manageRole = function (role: Role) {
    role.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/systemsetting/ManageRole', role, httpOptions);
  };
  public getGroupList = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetGroupList');
  };
  public manageGroup = function (group: Group) {
    group.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/systemsetting/ManageGroup', group, httpOptions);
  };
  public getMasterMenuList = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetMasterMenuList');
  };
  public getMasterMenuListMasterPage = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetMasterMenuList?mode=' + 1);
  };
  public manageMasterMenu = function( menu: Menu) {
    return this.httpClient.post(apiUrl + '/api/systemsetting/ManageMasterMenu', menu, httpOptions);
  };
  public getSchoolList = function(): Observable<any> {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetSchoolList')
    .pipe(map((scList: any) => {
     this.sharedData.schoolList = scList ;
     return scList;
    }));
  };
  public manageSchool = function (school: School) {
    // school.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/systemsetting/AddSchool', school, httpOptions);
  };

  public getPointDescriptionList = function( ) {
    return this.httpClient.get(apiUrl + '/api/setting/GetPointDescriptionList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getPointDescriptionListMasterPage = function( ) {
    return this.httpClient.get(apiUrl + '/api/setting/GetPointDescriptionList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public managePointDescription = function( pointdescription: PointDescription) {
    pointdescription.userId = this.loginService.currentUserSubject.value.userId;
    pointdescription.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/setting/ManagePointDescription', pointdescription, httpOptions);
  };

  public getMailList = function( ) {
    return this.httpClient.get(apiUrl + '/api/setting/GetMailList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getMailListMasterPage = function( ) {
    return this.httpClient.get(apiUrl + '/api/setting/GetMailList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageMail = function( mail: Mail) {
    mail.userId = this.loginService.currentUserSubject.value.userId;
    mail.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/setting/ManageMail', mail, httpOptions);
  };
  public getMessageList = function( ) {
    return this.httpClient.get(apiUrl + '/api/setting/GetMessageList?schId=' + this.schoolId.toString(), httpOptions);
  };
  public getMessageListMasterPage = function( ) {
    return this.httpClient.get(apiUrl + '/api/setting/GetMessageList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageMessage = function( message: Message) {
    message.userId = this.loginService.currentUserSubject.value.userId;
    message.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/setting/ManageMessage', message, httpOptions);
  };
  public getSessionListCurrent = function( ) {
    return this.httpClient.get(apiUrl + '/api/SystemSetting/GetSessionListCurrent?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public getSessionList = function( ) {
    return this.httpClient.get(apiUrl + '/api/SystemSetting/GetSessionList?schId=' + this.schoolId.toString()+'&mode='+0, httpOptions);
  };
  public getSessionListMasterPage = function( ) {
    // debugger;
     if (this.schoolId == '') {
      this.schoolId = 0;
    }
    return this.httpClient.get(apiUrl + '/api/SystemSetting/GetSessionList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };

  public manageHoliday = function(holiday : Holiday){
    holiday.userId = this.loginService.currentUserSubject.value.userId;
    holiday.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Master/ManageHoliday', holiday, httpOptions);
  };

  public getHolidaytList = function(){
    return this.httpClient.get(apiUrl + '/api/Master/GetHolidayList?schId=' + this.schoolId.toString()
    // + '&stSession=' + this.schoolId.toString(), httpOptions);
    , httpOptions);
  };
  public getHolidaytListMasterPage = function(){
    return this.httpClient.get(apiUrl + '/api/Master/GetHolidayList?schId=' + this.schoolId.toString()
    // + '&stSession=' + this.schoolId.toString()+'&mode='+ 1, httpOptions);
    + '&mode='+ 1, httpOptions);
  };
  public getUsersList = function () {
    return this.httpClient.get(apiUrl + '/api/User/GetUserList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };

  public getSchoolUsersList = function () {
    return this.httpClient.get(apiUrl + '/api/User/GetSchoolUserList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };

  public getSchoolUsersListForPermission = function () {
    return this.httpClient.get(apiUrl + '/api/User/GetSchoolUserList?schId=' + this.schoolId.toString()+'&mode='+2, httpOptions);
  };

  public ManageUser = function(users: Users){
    users.userId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/User/ManageUser', users, httpOptions);
  };
  // public getUsersList = function () {
  //   return this.httpClient.get(apiUrl + '/api/User/GetUserList?schId=' + this.schoolId.toString(), httpOptions);
  // };

  // public ManageUser = function(users: Users){
  //   users.userId = this.loginService.currentUserSubject.value.userId;
  //   return this.httpClient.post(apiUrl + '/api/User/ManageUser', users, httpOptions);
  // };

  public getEmployeeList = function(schId){
    return this.httpClient.get(apiUrl + '/api/Master/GetEmployeeList?schId=' + schId.toString(), httpOptions);
  };
  public getEmployeeListMasterPage = function(){
    return this.httpClient.get(apiUrl + '/api/Master/GetEmployeeList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageSession = function(sessionData : any){
    sessionData.userId = this.loginService.currentUserSubject.value.userId;
    sessionData.schId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/SystemSetting/SaveSession', sessionData, httpOptions);
  };

  public manageFeeHead = function(feeheadData: any) {
    feeheadData.userId = this.loginService.currentUserSubject.value.userId;
    feeheadData.SchoolId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Fee/SaveSchoolFeeHead', feeheadData, httpOptions);
  };

  public getFeeHeadListBySchool = function(){    
    return this.httpClient.get(apiUrl + '/api/Fee/GetSchoolFeeHeadList?schId=' + this.schoolId.toString()
    , httpOptions);
  };
  public getFeeHeadListBySchoolMasterPage = function(){
    return this.httpClient.get(apiUrl + '/api/Fee/GetSchoolFeeHeadList?schId=' + this.schoolId.toString()
    +'&mode=' + 1, httpOptions);
  };

  public getFeeHeadDetailBySchool = function(headid, sessionId){
    return this.httpClient.get(apiUrl + '/api/Fee/GetSchoolFeeHeadListDetail?headId='+headid+'&schId=' + 
    this.schoolId+'&stSession='+sessionId, httpOptions);
  };

  public getFeeGroupListBySchool = function(sessionid){
    return this.httpClient.get(apiUrl + '/api/Fee/GetSchoolFeeGroupList?schId=' + this.schoolId.toString() 
    +'&stSession='+sessionid, httpOptions);
  };
  public getFeeGroupListBySchoolMasterPage = function(sessionid){
    return this.httpClient.get(apiUrl + '/api/Fee/GetSchoolFeeGroupList?schId=' + this.schoolId.toString() 
    +'&stSession='+sessionid+'&mode='+1, httpOptions);
  };
  public getfeeGroupDetailBySchool = function(groupid){
    return this.httpClient.get(apiUrl + '/api/Fee/GetSchoolFeeGroupListDetail?groupId=' +
    groupid + '&schId=' + this.schoolId.toString(), httpOptions);
  };

  public manageFeeGroup = function(feegroupData: any) {
    feegroupData.userId = this.loginService.currentUserSubject.value.userId;
    feegroupData.SchoolId = this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Fee/SaveSchoolFeeGroup', feegroupData, httpOptions);
  };

  public ChangePassword = function(data: any) {
    return this.httpClient.post(apiUrl + '/api/User/ChangePassword?usrname='+ data.username 
    +'&oldPassword='+ data.oldpassword +'&newPassword='+ data.newpassword, data, httpOptions);
  };
  public ChangePasswordStudent = function(data: any) {
    return this.httpClient.post(apiUrl + '/api/User/ChangePasswordStudent?usrname='+ data.username 
    +'&oldPassword='+ data.oldpassword +'&newPassword='+ data.newpassword, data, httpOptions);
  };
  public GetDistrictCityLocalityList = function(mode, stateid,districtid, cityid){
    return this.httpClient.get(apiUrl + '/api/Master/GetDistrictCityLocalityList?mode=' + mode
    + '&StateId=' + stateid+'&DistrictId='+districtid +'&CityId='+cityid +'&schId='+this.schoolId, httpOptions);
  };
  public getMasterList = function( ) {
    return this.httpClient.get(apiUrl + '/api/Student/GetMaster?schId=' + this.schoolId);
  };
  public getPointTypeList = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetPointTypeList');
  };
  public managePointType = function (pointtype: PointType) {    
    return this.httpClient.post(apiUrl + '/api/systemsetting/ManagePointType', pointtype, httpOptions);
  };
  public getMasterReportList = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetReportList');
  };
  public manageMasterReport = function (report: Report) {    
    return this.httpClient.post(apiUrl + '/api/systemsetting/ManageReport', report, httpOptions);
  };
  public getMasterReportDetailList = function( ) {
    return this.httpClient.get(apiUrl + '/api/systemsetting/GetReportDetailsMasterList');
  };
  public manageMasterReportDetail = function (reportdetail: ReportDetail) {    
    return this.httpClient.post(apiUrl + '/api/systemsetting/ManageReportDetails', reportdetail, httpOptions);
  };
  public getSchoolMonthQuarterList = function (Mode,schoolId) {

    return this.httpClient.get(apiUrl + '/api/fee/GetSchoolMonthQuarterList?schId=' + schoolId +
      '&mode=' + Mode, httpOptions);
  };
  public SaveMonthQuarterList = function (stReg: SchoolMonthQuarWithDetail) {

    console.log(this.loginService.currentUserSubject);


    return this.httpClient.post(apiUrl + '/api/fee/SaveSchoolMonthQuarter', stReg, httpOptions);
  };
 public getNotificationTypeList = function( ) {
  return this.httpClient.get(apiUrl + '/api/setting/GetNotificationType');
};
public getNotificationTypeGeneralList = function( ) {
  return this.httpClient.get(apiUrl + '/api/setting/GetNotificationTypeGeneral');
};

public manageNotificationType = function (notificationtype: NotificationType) {
  return this.httpClient.post(apiUrl + '/api/setting/ManageNotificationType', notificationtype, httpOptions);
};
public getNotificationList = function() {
  return this.httpClient.get(apiUrl + '/api/Setting/GetMessageMailNotification?schId=' + this.schoolId.toString(), httpOptions);
};

public manageNotification = function (notification: Notification) {
  notification.userId = this.loginService.currentUserSubject.value.userId;
  notification.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Setting/ManageNotification', notification, httpOptions);
};

public CheckUser = function(username) {
     return this.httpClient.get(apiUrl + '/api/User/CheckUser?usid=' + username);
};



public getSubjectList = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetSubjectlist?schId=' + this.schoolId.toString(), httpOptions);
};

public ManageSubject = function (subject: SubSubject) {
  subject.userId = this.loginService.currentUserSubject.value.userId;
  subject.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageSubject', subject, httpOptions);
};

public getUserWiseClassList = function(classId: number = 0, sectionId: number = 0) {
  classId = classId ? classId : 0;
  sectionId = sectionId ? sectionId : 0;
  return this.httpClient.get(apiUrl + '/api/Master/GetClassSectionSubjectList?schId=' + this.schoolId.toString() +
   '&UserId=' + this.currentUserId +'&sdid=' + classId +'&secid=' + sectionId + '&mode=' + 1, httpOptions);
};

public getUserWiseSectionList = function(classId: number = 0, sectionId: number = 0) {
  classId = classId ? classId : 0;
  sectionId = sectionId ? sectionId : 0;
  return this.httpClient.get(apiUrl + '/api/Master/GetClassSectionSubjectList?schId=' + this.schoolId.toString() +
  '&UserId=' + this.currentUserId +'&sdid=' + classId +'&secid=' + sectionId + '&mode=' + 2, httpOptions);
};

public getUserWiseSubjectList = function(classId: number = 0, sectionId: number = 0) {
  classId = classId ? classId : 0;
  sectionId = sectionId ? sectionId : 0;
  return this.httpClient.get(apiUrl + '/api/Master/GetClassSectionSubjectList?schId=' + this.schoolId.toString() +
   '&UserId=' + this.currentUserId +'&sdid=' + classId +'&secid=' + sectionId + '&mode=' + 3, httpOptions);
};

public getAttendencelocationList = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetusAtLocationMasterList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};

public manageAttendencelocation = function (attendencelocation: Attendencelocation) {  
  attendencelocation.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageusAtLocationMaster', attendencelocation, httpOptions);
};
public getAlertTypeList = function( ) {
  return this.httpClient.get(apiUrl + '/api/Master/GetAlertTypeList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public getAlertTypeListForAlert = function( ) {
  return this.httpClient.get(apiUrl + '/api/Master/GetAlertTypeList?schId=' + this.schoolId.toString()+'&mode='+0, httpOptions);
};

public ManageAlertType = function (alerttype: AlertType) {  
  alerttype.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageAlertType', alerttype, httpOptions);
};
public getAlertList = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetAlertList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);  
};

public getAlertListForLogin = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetAlertList?schId=' + this.schoolId.toString()+'&mode='+3, httpOptions);  
};
public manageAlert = function(alert : Alert){
  alert.userId = this.loginService.currentUserSubject.value.userId;
  alert.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageAlert', alert, httpOptions);
};
public getDesignationList = function(){
  return this.httpClient.get(apiUrl + '/api/General/GetDesignationList?mode='+0, httpOptions);  
};

public manageDesignation = function (designation: Designation) {
    return this.httpClient.post(apiUrl + '/api/general/ManageDesignation', designation, httpOptions);
};

public manageEmployee = function(employee : Employee){
  employee.userId = this.loginService.currentUserSubject.value.userId;
  employee.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageEmployee', employee, httpOptions);
};

public GetSubjectlistClassWise = function(classid){
  return this.httpClient.get(apiUrl + '/api/Master/GetSubjectlistClassWise?schId=' + this.schoolId.toString()+ '&sdId=' + classid, httpOptions);
};
public getExamAreaTypeList = function( ) {
  return this.httpClient.get(apiUrl + '/api/systemsetting/GetExamAreaTypeList');
};
public getExamAreaListMaster = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamArealist?mode='+ 0 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public getExamAreaList = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamArealist?mode='+ 0 +'&schId=' + this.schoolId.toString(), httpOptions);
};

public getExamAreaListForUnit = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamArealist?mode='+ 1 +'&schId=' + this.schoolId.toString(), httpOptions);
};

public getExamAreaListForSCHOLASTICA = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamArealist?mode='+ 2 +'&schId=' + this.schoolId.toString(), httpOptions);
};

public getExamAreaListForSCHOLASTICB = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamArealist?mode='+ 3 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public getExamAreaListForCOSCHOLASTICA = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamArealist?mode='+ 4 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public getExamAreaListForCOSCHOLASTICB = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamArealist?mode='+ 5 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public ManageExamArea = function (examarea: ExamArea) {
  examarea.userId = this.loginService.currentUserSubject.value.userId;
  examarea.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageExamArea', examarea, httpOptions);
};

public GetExamTypelist = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamTypelist?schId=' + this.schoolId.toString(), httpOptions);
};

public ManageExamType = function (examtype: ExamType) {
  examtype.userId = this.loginService.currentUserSubject.value.userId;
  examtype.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageExamType', examtype, httpOptions);
};
public GetExamSubTypelistMaster = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubTypelist?mode='+ 4 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public GetExamSubTypelist = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubTypelist?mode='+ 1 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public GetExamSubTypelist_Unit = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubTypelist?mode='+ 2 +'&schId=' + this.schoolId.toString(), httpOptions);
};

public GetExamSubTypelist_PB1 = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubTypelist?mode='+ 6 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public GetExamSubTypelist_PB2 = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubTypelist?mode='+ 7 +'&schId=' + this.schoolId.toString(), httpOptions);
};

public GetExamSubTypelist_Remarks = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubTypelist?mode='+ 3 +'&schId=' + this.schoolId.toString(), httpOptions);
};

public GetExamSubTypelist_Reports = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubTypelist?mode='+ 0 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public GetExamSubTypelistTerm2 = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubTypelist?mode='+ 5 +'&schId=' + this.schoolId.toString(), httpOptions);
};
public GetExamSubTypeSequenceNo_Reports = function(exstid) {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamSubSequenceNo?exstid='+ exstid +'&schId=' + this.schoolId.toString(), httpOptions);
};
public ManageExamSubType = function (examsubtype: ExamSubType) {
  examsubtype.userId = this.loginService.currentUserSubject.value.userId;
  examsubtype.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageExamSubType', examsubtype, httpOptions);
};
public GetExamRemarkslist = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamRemarkslist?schId=' + this.schoolId.toString()+'&UserId=' + this.currentUserId, httpOptions);
};

public ManageExamRemarks = function (examremarks: ExamRemarks) {
  examremarks.userId = this.loginService.currentUserSubject.value.userId;
  examremarks.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageExamRemarks', examremarks, httpOptions);
};

public GetExamPointGradelist = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamPointGradelist?schId=' + this.schoolId.toString(), httpOptions);
};

public ManageExamPointGrade = function (exampointgrade: ExamPointGrade) {
  exampointgrade.userId = this.loginService.currentUserSubject.value.userId;
  exampointgrade.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageExamPointGrade', exampointgrade, httpOptions);
};

public GetExamGradeScalelist = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamGradeScalelist?schId=' + this.schoolId.toString(), httpOptions);
};

public ManageExamGradeScale = function (examgradescale: ExamGradeScale) {
  examgradescale.userId = this.loginService.currentUserSubject.value.userId;
  examgradescale.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageExamGradeScale', examgradescale, httpOptions);
};

public GetExamGradelist = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetExamGradelist?schId=' + this.schoolId.toString(), httpOptions);
};

public ManageExamGrade = function (examgrade: ExamGrade) {
  examgrade.userId = this.loginService.currentUserSubject.value.userId;
  examgrade.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageExamGrade', examgrade, httpOptions);
};

public GetExamWtageMappingList = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamWtageMappingList?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&mode='+1, httpOptions);
};

public GetExamWtageMappingListDetail = function(exhid,classid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamWtageMappingListDetail?exhid=' +
  exhid + '&schId=' + this.schoolId.toString()+ '&classId=' + classid, httpOptions);
};

public SaveExamWtageMapping = function(ExamWtageWithDetail: any) {
  ExamWtageWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  ExamWtageWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamWtageMapping', ExamWtageWithDetail, httpOptions);
};

public getUserWiseSubjectExamAreaWiseList = function(classId: number = 0, sectionId: number = 0,examareaId:number =0) {
  classId = classId ? classId : 0;
  sectionId = sectionId ? sectionId : 0;
  return this.httpClient.get(apiUrl + '/api/Master/GetClassSectionSubjectExamAreaWiseList?schId=' + this.schoolId.toString() +
   '&UserId=' + this.currentUserId +'&sdid=' + classId +'&secid=' + sectionId +'&examareaid=' + examareaId + '&mode=' + 4, httpOptions);
};

public GetExamMarksList_Unit1 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+1, httpOptions);
};
public GetExamMarksList_Unit2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+6, httpOptions);
};
public GetExamMarksList_PB1 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+11, httpOptions);
};
public GetExamMarksList_Term1 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+2, httpOptions);
};
public GetExamMarksList_PB1ForElevenTwelve = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+12, httpOptions);
};
public GetExamMarksList_PB2ForElevenTwelve = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+14, httpOptions);
};
public GetExamMarksList_PB2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+14, httpOptions);
};
public GetExamMarksList_Term1SCO2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+3, httpOptions);
};
public GetExamMarksList_PB1SCO2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+13, httpOptions);
};
public GetExamMarksList_PB2SCO2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+15, httpOptions);
};
public GetExamMarksList_Term1COSCO1 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+4, httpOptions);
};
public GetExamMarksList_Term1COSCO2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+5, httpOptions);
};
public GetExamFullMarksList = function(stSession,stClass,stSubject){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamFullMarksList?schId=' + this.schoolId.toString()+ '&sessionId=' + stSession + '&sdId=' + stClass
  + '&subjectId=' + stSubject+'&mode='+1, httpOptions);
};
public GetExamFullMarksListForElevenTwelve = function(stSession,stClass,stSubject){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamFullMarksList?schId=' + this.schoolId.toString()+ '&sessionId=' + stSession + '&sdId=' + stClass
  + '&subjectId=' + stSubject+'&mode='+2, httpOptions);
};
public GetExamMarksListForSessionClassSectionSubject = function(exmid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksListForSessionClassSectionSubject?exmid=' +
  exmid +'&mode='+1, httpOptions);
};
public CheckExamMarksListDetail = function(sdId,sectId,sessionId,subjectId){
  return this.httpClient.get(apiUrl + '/api/Master/CheckExamMarksListDetail?schId=' + this.schoolId.toString()
  + '&sdId=' + sdId+ '&sectId=' + sectId + '&sessionId=' + sessionId + '&subjectId=' + subjectId, httpOptions);
};
public CheckExamMarksListDetailForSCO = function(sdId,sectId,sessionId,subjectId){
  return this.httpClient.get(apiUrl + '/api/Master/CheckExamMarksListDetail?schId=' + this.schoolId.toString()
  + '&sdId=' + sdId+ '&sectId=' + sectId + '&sessionId=' + sessionId + '&subjectId=' + subjectId+'&mode='+1, httpOptions);
};
public GetExamMarksListDetail = function(exmid,sdId,sectId,sessionId,subjectId){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksListDetail?exmid=' +
  exmid + '&schId=' + this.schoolId.toString()+ '&sdId=' + sdId
  + '&sectId=' + sectId + '&sessionId=' + sessionId + '&subjectId=' + subjectId, httpOptions);
};

public GetExamMarksListDetailNewStudent = function(exmid,sdId,sectId,sessionId,subjectId){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksListDetailNewStudent?exmid=' +
  exmid + '&schId=' + this.schoolId.toString()+ '&sdId=' + sdId
  + '&sectId=' + sectId + '&sessionId=' + sessionId + '&subjectId=' + subjectId, httpOptions);
};

public SaveExamMarks_Unit1 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Unit1', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Unit1_NewStudent = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Unit1_NewStudent', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Unit2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Unit2', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_PB1 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_PB1', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Term1 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Term1onetotwo = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1onetotwo', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Term1eleventotwelve = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1eleventotwelve', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_PB1eleventotwelve = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_PB1eleventotwelve', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_PB2eleventotwelve = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_PB2eleventotwelve', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_Term1Co1 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1Co1', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_Term1Co1_NewStudent = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1Co1_NewStudent', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_Term1Co2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1Co2', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Term1Co2_NewStudent = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1Co2_NewStudent', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Term1sco2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1sco2', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_Term1sco2_NewStudent = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term1sco2_NewStudent', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_PB1sco2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_PB1sco2', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_PB2sco2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_PB2sco2', ExamMarksWithDetail, httpOptions);
};

public GetExamMarksRemarksList = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksRemarksList?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+1, httpOptions);
};

public GetExamMarksRemarksListDetail = function(exmid,sdId,sectId,sessionId,exmr_exstid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksRemarksListDetail?exmid=' +
  exmid + '&schId=' + this.schoolId.toString()+ '&sdId=' + sdId
  + '&sectId=' + sectId + '&sessionId=' + sessionId + '&exmr_exstid=' + exmr_exstid, httpOptions);
};

public GetExamMarksRemarksListDetailNewStudent = function(exmid,sdId,sectId,sessionId,exmr_exstid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksRemarksListDetailNewStudent?exmid=' +
  exmid + '&schId=' + this.schoolId.toString()+ '&sdId=' + sdId
  + '&sectId=' + sectId + '&sessionId=' + sessionId + '&exmr_exstid=' + exmr_exstid, httpOptions);
};

public SaveExamMarksRemarks = function(ExamMarksRemarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarksRemarks', ExamMarksRemarksWithDetail, httpOptions);
};
public SaveExamMarksRemarksNewStudent = function(ExamMarksRemarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarksRemarksNewStudent', ExamMarksRemarksWithDetail, httpOptions);
};

public CheckExamMarksRemarksListDetail = function(sdId,sectId,sessionId,exstid){
  return this.httpClient.get(apiUrl + '/api/Master/CheckExamMarksRemarksListDetail?schId=' + this.schoolId.toString()
  + '&sdId=' + sdId+ '&sectId=' + sectId + '&sessionId=' + sessionId + '&exstid=' + exstid, httpOptions);
};
public GetExamMarksRemarksListForSessionClassSectionSubject = function(exmid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksRemarksListForSessionClassSectionSubject?exmid=' +
  exmid +'&mode='+1, httpOptions);
};

public GetEmployeeNo = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetEmployeeNo?schId=' + this.schoolId.toString() 
  +'&mode='+1 +'&userid=' + this.currentUserId, httpOptions);
};

public ClassTeacherForSessionList = function(){
  return this.httpClient.get(apiUrl + '/api/Master/ClassTeacherForSessionList?clsid=' +
  0 + '&schId=' + this.schoolId.toString()+ '&userId=' + 0 + '&sessionId=' + 0 + '&sdId=' + 0
  + '&sectId=' + 0  + '&mode=' + 1, httpOptions);
};

public ClassTeacherForSessionEditList = function(Clsid){
  return this.httpClient.get(apiUrl + '/api/Master/ClassTeacherForSessionList?clsid=' +
  Clsid + '&schId=' + this.schoolId.toString()+ '&userId=' + 0 + '&sessionId=' + 0+ '&sdId=' + 0
  + '&sectId=' + 0  + '&mode=' + 2, httpOptions);
};

public ClassTeacherForSession = function(userId,sessionId,Sdid,secid){
  return this.httpClient.get(apiUrl + '/api/Master/ClassTeacherForSessionList?clsid=' +
  0 + '&schId=' + this.schoolId.toString()+ '&userId=' + userId + '&sessionId=' + sessionId+ '&sdId=' + Sdid
  + '&sectId=' + secid  + '&mode=' + 3, httpOptions);
};
public CheckClassTeacherForSession = function(userId,sessionId,Sdid,secid){
  return this.httpClient.get(apiUrl + '/api/Master/ClassTeacherForSessionList?clsid=' +
  0 + '&schId=' + this.schoolId.toString()+ '&userId=' + userId + '&sessionId=' + sessionId+ '&sdId=' + Sdid
  + '&sectId=' + secid  + '&mode=' + 4, httpOptions);
};
public CheckClassTeacherForOnlySession = function(userId,sessionId,Sdid,secid){
  return this.httpClient.get(apiUrl + '/api/Master/ClassTeacherForSessionList?clsid=' +
  0 + '&schId=' + this.schoolId.toString()+ '&userId=' + userId + '&sessionId=' + sessionId+ '&sdId=' + Sdid
  + '&sectId=' + secid  + '&mode=' + 5, httpOptions);
};
public manageclassteacher = function(classteacher : classteacher){ 
  classteacher.SchoolId = this.schoolId.toString();
  return this.httpClient.post(apiUrl + '/api/Master/ManageClassTeacher', classteacher, httpOptions);
};

public manageLeave = function(leave : Leave){ 
  leave.userId = this.loginService.currentUserSubject.value.userId;
 // leave.approvedby = this.loginService.currentUserSubject.value.userId;
  leave.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageLeave', leave, httpOptions);
};
public manageLeaveApprove = function(leaveapprove : LeaveApprove){ 
 // leave.userId = this.loginService.currentUserSubject.value.userId;
  leaveapprove.approvedby = this.loginService.currentUserSubject.value.userId;
  leaveapprove.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageLeaveApprove', leaveapprove, httpOptions);
};
public manageLeaveType = function(leavetype : LeaveType){
  leavetype.userId = this.loginService.currentUserSubject.value.userId;
  leavetype.schId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/ManageLeaveType', leavetype, httpOptions);
};
public getLeaveTypeList = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetLeaveTypeList?schId=' + this.schoolId.toString()  
  + '&mode=' + 1, httpOptions);
};
public getLeaveList = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetLeaveList?schId=' + this.schoolId.toString()
  + '&userid=' + 0 + '&mode=' + 1, httpOptions);  
};
public getLeaveListUserWise = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetLeaveList?schId=' + this.schoolId.toString()
  + '&userid=' + this.currentUserId + '&mode=' + 2, httpOptions);  
};
public getApprovedLeaveListUserWise = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetLeaveList?schId=' + this.schoolId.toString()
  + '&userid=' + this.currentUserId + '&mode=' + 4, httpOptions);  
};
public getLeaveListByApprovalUser = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetLeaveList?schId=' + this.schoolId.toString()
  + '&userid=' + this.currentUserId + '&mode=' + 5, httpOptions);  
};
public getApprovedLeaveListByApprovalUser = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetLeaveList?schId=' + this.schoolId.toString()
  + '&userid=' + this.currentUserId + '&mode=' + 6, httpOptions);  
};

public getLeavetListForApproval = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetLeaveList?schId=' + this.schoolId.toString() 
  + '&mode='+ 3, httpOptions);
};

public GetExamMarksList_Term2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+7, httpOptions);
};
public GetExamMarksList_Term2COSCO1 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+8, httpOptions);
};
public GetExamMarksList_Term2COSCO2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+9, httpOptions);
};
public GetExamMarksList_Term2SCO2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+10, httpOptions);
};
public CheckExamMarksListDetailForTerm2SCO = function(sdId,sectId,sessionId,subjectId){
  return this.httpClient.get(apiUrl + '/api/Master/CheckExamMarksListDetail?schId=' + this.schoolId.toString()
  + '&sdId=' + sdId+ '&sectId=' + sectId + '&sessionId=' + sessionId + '&subjectId=' + subjectId+'&mode='+2, httpOptions);
};
public SaveExamMarks_Term2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term2', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Term2onetotwo = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term2onetotwo', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Term2eleventotwelve = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term2eleventotwelve', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_Term2Co1 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term2Co1', ExamMarksWithDetail, httpOptions);
};
public SaveExamMarks_Term2Co2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term2Co2', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_Term2sco2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_Term2sco2', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_PB2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_PB2', ExamMarksWithDetail, httpOptions);
};

public GetExamMarksList_PB2COSCO1 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+16, httpOptions);
};

public GetExamMarksList_PB2COSCO2 = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetExamMarksList_Unit1?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&UserId=' + this.currentUserId+'&mode='+17, httpOptions);
};

public SaveExamMarks_PB2Co1 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_PB2Co1', ExamMarksWithDetail, httpOptions);
};

public SaveExamMarks_PB2Co2 = function(ExamMarksWithDetail: any) {
  // ExamMarksWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  // ExamMarksWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveExamMarks_PB2Co2', ExamMarksWithDetail, httpOptions);
};

public GetAdmitCardList = function(sessionid){
  return this.httpClient.get(apiUrl + '/api/Master/GetAdmitCardList?schId=' + this.schoolId.toString() 
  +'&stSession='+sessionid+'&mode='+1, httpOptions);
};
public GetAdmitCardListDetail = function(adId,classid){
  return this.httpClient.get(apiUrl + '/api/Master/GetAdmitCardListDetail?adId=' +
  adId + '&schId=' + this.schoolId.toString()+ '&classId=' + classid, httpOptions);
};
public SaveAdmitCard = function(AdmitCardWithDetail: any) {
  AdmitCardWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  AdmitCardWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveAdmitCard', AdmitCardWithDetail, httpOptions);
};
//HR
public GetHRSettingList = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetHRSettingList?schId=' + this.schoolId
  + '&mode=' + 1, httpOptions);
};
public GetHRSettingDetailList = function(hscode: number= 0) {
  return this.httpClient.get(apiUrl + '/api/Master/GetHRSettingDetailList?schId=' + this.schoolId
  + '&hscode=' + hscode, httpOptions);
};
public GetHRSettingListForEffectiveDate = function(effectivedate: string) {
  return this.httpClient.get(apiUrl + '/api/Master/GetHRSettingListForEffectiveDate?schId=' + this.schoolId
  + '&effectivedate=' + effectivedate + '&mode=' + 1, httpOptions);
};

public manageHRSetting = function(hsData : any, ){   
  return this.httpClient.post(apiUrl + '/api/Master/SaveHRSetting', hsData, httpOptions);
};
public GetEmpLeaveTypeList = function(empId,leavetypeid){
  return this.httpClient.get(apiUrl + '/api/Master/GetEmpLeaveTypeWiseList?empid=' +
  empId + '&schId=' + this.schoolId.toString()+ '&leavetypeid=' + leavetypeid+ '&mode=' + 1, httpOptions);
};
public GetEmpBalanceLeaveTypeList = function(empId,leavetypeid){
  return this.httpClient.get(apiUrl + '/api/Master/GetEmpLeaveTypeWiseList?empid=' +
  empId + '&schId=' + this.schoolId.toString()+ '&leavetypeid=' + leavetypeid+ '&mode=' + 2, httpOptions);
};
public getLeaveTypeWiseList = function(leavetypeid){
  return this.httpClient.get(apiUrl + '/api/Master/GetLeaveTypeWiseList?schId=' + this.schoolId.toString()  
  + '&LeaveTypeId=' + leavetypeid + '&mode=' + 1, httpOptions);
};
public GetEmpLeaveOpeningListDetail = function(empId){
  return this.httpClient.get(apiUrl + '/api/Master/GetEmpLeaveOpeningListDetail?empid=' +
  empId + '&schId=' + this.schoolId.toString()+ '&mode=' + 1, httpOptions);
};
public GetEmpLeaveCreditedListDetail = function(empId){
  return this.httpClient.get(apiUrl + '/api/Master/GetEmpLeaveCreditedListDetail?empid=' +
  empId + '&schId=' + this.schoolId.toString()+ '&mode=' + 1, httpOptions);
};
public SaveEmpLeaveCredited = function(EmpLeaveCreditedView: any) {
  EmpLeaveCreditedView.userId = this.loginService.currentUserSubject.value.userId;
  EmpLeaveCreditedView.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveEmpLeaveCredited', EmpLeaveCreditedView, httpOptions);
};
public SaveEmpLeaveOpening = function(EmpLeaveCreditedViewWithDetail: any) {
  EmpLeaveCreditedViewWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  EmpLeaveCreditedViewWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveEmpLeaveOpening', EmpLeaveCreditedViewWithDetail, httpOptions);
};
public SaveEmpLeaveCarryForward = function(EmpLeaveCarryForwardView: any) {
  EmpLeaveCarryForwardView.userId = this.loginService.currentUserSubject.value.userId;
  EmpLeaveCarryForwardView.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveEmpLeaveCarryForward', EmpLeaveCarryForwardView, httpOptions);
};
public GetSalaryHeadsList = function() {
  return this.httpClient.get(apiUrl + '/api/Master/GetSalaryHeadList?shid=' + 0
  + '&schId=' + this.schoolId  + '&mode=' + 1, httpOptions);
};
public GetSalaryHeadsDetailList = function(shid: number= 0) {
  return this.httpClient.get(apiUrl + '/api/Master/GetSalaryHeadList?shid=' + shid
  + '&schId=' + this.schoolId + '&mode=' + 2, httpOptions);
};
public manageSalaryHeads = function(shData : any, ){   
  return this.httpClient.post(apiUrl + '/api/Master/SaveSalaryHead', shData, httpOptions);
};

public GetEmployeeDetailsList = function(emid: number= 0) {
  return this.httpClient.get(apiUrl + '/api/Master/GetEmployeeDetails?emid=' + emid
  + '&schId=' + this.schoolId + '&mode=' + 2, httpOptions);
};
public manageEmployeeDetails = function(emData : any, ){   
  return this.httpClient.post(apiUrl + '/api/Master/SaveEmployeeDetails', emData, httpOptions);
};
public GetEmpSalaryHeadListDetail = function(empId){
  return this.httpClient.get(apiUrl + '/api/Master/GetEmpSalaryHeadListDetail?empid=' +
  empId + '&schId=' + this.schoolId.toString()+ '&mode=' + 1, httpOptions);
};
public SaveEmpSalaryHead = function(EmpSalaryHeadViewList: any) {
  EmpSalaryHeadViewList.userId = this.loginService.currentUserSubject.value.userId;
  EmpSalaryHeadViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveEmpSalaryHead', EmpSalaryHeadViewList, httpOptions);
};
public GetMonthlyAttendanceForSalaryDetail = function(startdate,enddate){
  return this.httpClient.get(apiUrl + '/api/Master/GetMonthlyAttendanceForSalaryDetail?startdate=' +
  startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};
public SaveMonthlyAttendanceForSalary = function(MonthlyAttendanceForSalaryDetailViewList: any) {
  MonthlyAttendanceForSalaryDetailViewList.userId = this.loginService.currentUserSubject.value.userId;
  MonthlyAttendanceForSalaryDetailViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveMonthlyAttendanceForSalary', MonthlyAttendanceForSalaryDetailViewList, httpOptions);
};
public SaveHRPaySalary = function(enddate){
  return this.httpClient.get(apiUrl + '/api/Master/SaveHRPaySalary?enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId, httpOptions);
};
public SaveHRPayEmpSalary = function(empid,enddate){
  return this.httpClient.get(apiUrl + '/api/Master/SaveHRPayEmpSalary?empid=' +
  empid + '&enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId, httpOptions);
};
public GetHRPaySalary = function(){
  return this.httpClient.get(apiUrl + '/api/Master/GetHRPaySalary?mode=' +
  1 + '&schId=' + this.schoolId.toString(), httpOptions);
};
public GetTDSDetail = function(startdate,enddate){
  return this.httpClient.get(apiUrl + '/api/Master/GetTDSDetail?startdate=' +
  startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};
public SaveTDS = function(TDSDetailViewList: any) {
  TDSDetailViewList.userId = this.loginService.currentUserSubject.value.userId;
  TDSDetailViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveTDS', TDSDetailViewList, httpOptions);
};
public GetLoanOtherDeductionDetail = function(startdate,enddate){
  return this.httpClient.get(apiUrl + '/api/Master/GetLoanOtherDeductionDetail?startdate=' +
  startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};
public SaveLoanOtherDeduction = function(LoanOtherDeductionDetailViewList: any) {
  LoanOtherDeductionDetailViewList.userId = this.loginService.currentUserSubject.value.userId;
  LoanOtherDeductionDetailViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveLoanOtherDeduction', LoanOtherDeductionDetailViewList, httpOptions);
};
public GetAdvanceDetail = function(startdate,enddate){
  return this.httpClient.get(apiUrl + '/api/Master/GetAdvanceDetail?startdate=' +
  startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};
public SaveAdvance = function(AdvanceDetailViewList: any) {
  AdvanceDetailViewList.userId = this.loginService.currentUserSubject.value.userId;
  AdvanceDetailViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveAdvance', AdvanceDetailViewList, httpOptions);
};
public GetIncentiveDetail = function(startdate,enddate){
  return this.httpClient.get(apiUrl + '/api/Master/GetIncentiveDetail?startdate=' +
  startdate + '&enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};
public SaveIncentive = function(IncentiveDetailViewList: any) {
  IncentiveDetailViewList.userId = this.loginService.currentUserSubject.value.userId;
  IncentiveDetailViewList.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Master/SaveIncentive', IncentiveDetailViewList, httpOptions);
};
public GetConsolidatedMonthlySalaryExport = function(monthstartDate){
  return this.httpClient.get(apiUrl + '/api/Master/GetConsolidatedMonthlySalaryExport?mode=' +
  1 + '&schId=' + this.schoolId.toString()+ '&monthstartDate=' + monthstartDate, httpOptions);  
};
public GetEmpSalarySlip = function(startdate){
  return this.httpClient.get(apiUrl + '/api/Master/GetEmpSalarySlip?startdate=' +
  startdate + '&schId=' + this.schoolId.toString() + '&mode=' + 1, httpOptions);
};
//Salary Slip
PrintEmpSalarySlipPDF(empid,month,yr, domainName){
  let SchoolId =  this.schoolId;
  
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintEmpSlarySlipPDF?mode=1&empid=' +
  empid+'&schId='+ SchoolId+'&month='+ month +'&yr='+ yr +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

GetEmpSalarySlipMailDetail(empid,month,yr, domainName){
  let SchoolId =  this.schoolId;
 
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetEmpSalarySlipMailDetail?mode=1&empid=' +
  empid+'&schId='+ SchoolId+'&month='+ month +'&yr='+ yr +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
}


