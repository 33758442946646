import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterService } from '../../shared/services/master.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subsubject',
  templateUrl: './subsubject.component.html',
  styleUrls: ['./subsubject.component.scss'],

})
export class SubSubjectComponent implements OnInit {
  public classList: any = [];
  public subjectList: any[] = [];
  public examareaList: any[] = [];

  public stOrgList: any = [];
  public subsubjectForm: FormGroup;

  public streamList : any[] = [{name: 'General',value:'General'},{name: 'Science', value: 'Science'}, 
  {name: 'Commerce',value:'Commerce'}, {name: 'Art', value: 'Art'}];
  showError: Boolean = false;
  closeResult: string;
  public p:number =1;
  @ViewChild('content') tModal: ElementRef;
  constructor(public router: Router, private formBuilder: FormBuilder, private subjectService: MasterService,
               private modalService: NgbModal,private toast: ToastrService) {
    this.subsubjectForm = this.formBuilder.group({     
      sbid: [''],
      sbname: ['', [Validators.required]],
      shortnm: ['', [Validators.required]],
      strm: ['', [Validators.required]],
      isprct: [''],
      isprctsbid: [''],
      printseq: ['', [Validators.required]],
      examareaid: ['', [Validators.required]],
      active: [''],
      sdid: ['', [Validators.required]],
      sbcode: [''],
      part: [''],
      iswtage: [''],
      analysis:['']
    });
  }

  ngOnInit() {
    this.subjectService.getSubjectList().subscribe(lst => { this.subjectList = lst; this.stOrgList = lst ; });
    this.subjectService.getSchoolClassList().subscribe(scl => { this.classList = scl; });
      this.subjectService.getExamAreaListMaster().subscribe(lst => { this.examareaList = lst; });      
  }
  resetForm() {
    this.subsubjectForm.reset();
  }
  editSubject(subsubject: any, content) {
    if (subsubject.sb_sbid > 0 )  {
      this.subsubjectForm.controls['sbid'].setValue(subsubject.sb_sbid );     
      this.subsubjectForm.controls['sbname'].setValue( subsubject.sb_sbname);
      this.subsubjectForm.controls['shortnm'].setValue( subsubject.sb_shortnm);
      this.subsubjectForm.controls['strm'].setValue( subsubject.sb_strm);
      this.subsubjectForm.controls['isprct'].setValue( subsubject.sb_isprct);
      this.subsubjectForm.controls['isprctsbid'].setValue(subsubject.sb_isprctsbid) ;
      this.subsubjectForm.controls['printseq'].setValue(subsubject.sb_printseq) ;
      this.subsubjectForm.controls['examareaid'].setValue(subsubject.sb_examareaid) ;
      this.subsubjectForm.controls['iswtage'].setValue(subsubject.sb_iswtage) ;
      this.subsubjectForm.controls['active'] .setValue( subsubject.sb_active) ;
      this.subsubjectForm.controls['sdid'].setValue(subsubject.sb_sdid );
      this.subsubjectForm.controls['sbcode'].setValue(subsubject.sb_code );
      this.subsubjectForm.controls['part'].setValue(subsubject.part );
      this.subsubjectForm.controls['analysis'].setValue(subsubject.sb_analysis );
      this.open(content);
    }
  }
  addSubject(content) {
    this.resetForm();
    this.subsubjectForm.controls['sbid'].setValue(0);
    this.subsubjectForm.controls['sdid'].setValue('');
    this.subsubjectForm.controls['strm'].setValue('');
    this.subsubjectForm.controls['isprctsbid'].setValue('');
    this.subsubjectForm.controls['examareaid'].setValue('');
    this.subsubjectForm.controls['iswtage'].setValue('');
    this.subsubjectForm.controls['sbcode'].setValue('');
    this.subsubjectForm.controls['part'].setValue('');
    this.subsubjectForm.controls['analysis'].setValue('');
    this.open(content);
  }
  onSubmit() {
    if (this.subsubjectForm.invalid) {
      return;
    }  
    const data: any = { sbid: this.subsubjectForm.controls['sbid'].value,                
                sbname: this.subsubjectForm.controls['sbname'].value,
                shortnm: this.subsubjectForm.controls['shortnm'].value,
                strm: this.subsubjectForm.controls['strm'].value,
                isprct: this.subsubjectForm.controls['isprct'].value,
                isprctsbid: this.subsubjectForm.controls['isprctsbid'].value,
                printseq: this.subsubjectForm.controls['printseq'].value,
                examareaid: this.subsubjectForm.controls['examareaid'].value,
                iswtage: this.subsubjectForm.controls['iswtage'].value,
                active: this.subsubjectForm.controls['active'].value,
                sdid: this.subsubjectForm.controls['sdid'].value,
                sbcode: this.subsubjectForm.controls['sbcode'].value,
                part: this.subsubjectForm.controls['part'].value,
                analysis: this.subsubjectForm.controls['analysis'].value                
    };

    this.subjectService.ManageSubject(data).subscribe(res => {
      if (res) {
        this.subjectService.getSubjectList().subscribe(lst => { this.subjectList = lst; });
        this.modalService.dismissAll();
        alert('Record updated');
      } else {
        alert('Record not updated');
      }
    });

  }
  open(content) {
    this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return  `with: ${reason}`;
    }
  }

  // searchClass() {
  //   if(this.subsubjectForm.controls['sdidfilter'].value.length ==0 ){
  //       this.toast.error("Please select class");
        
  //   }else{
  //     if (this.subsubjectForm.controls['sdidfilter'].value && this.subsubjectForm.controls['sdidfilter'].value .length > 0 ) {
      
  //             this.subjectList = this.stOrgList.filter(m => m.stClass == this.subsubjectForm.controls['sdidfilter'].value );
       
  //     } else {
  //       this.subjectList = this.stOrgList;
  //     }
  //   }
  // }

  // searchByClass() {
  //   this.subjectList = this.stOrgList;
  //   this.subjectList = this.stOrgList.filter(obj => obj.sd_sdname == this.subsubjectForm.controls['drpClassfilter'].value);
  // }

}

