import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { MasterService } from '../../shared/services/master.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-generalsmsmail',
  templateUrl: './generalsmsmail.component.html',
  styleUrls: ['./generalsmsmail.component.scss'],
  
})
export class GeneralSMSMAILComponent implements OnInit {
  public notificationtypeList: any[] = [];
  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public UserId:number;
  public message: string = null;
  public generalsmsmailForm: FormGroup;
  public p:number =1;
  masterSelected: boolean;
  checkedList: any;
  public IsAllChecked: boolean = false;
  selectedType: string = '';
  constructor(private router: Router, private stService: StudentService,private notificationService: MasterService,
      private formBuilder: FormBuilder,private toast:ToastrService) {
        this.stService.getMasterList();
        this.UserId = JSON.parse(localStorage.getItem('currentUser'))["userId"];
      }

  ngOnInit() {
    this.notificationService.getNotificationTypeGeneralList().subscribe(lst => { this.notificationtypeList = lst; });
    this.generalsmsmailForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: [''],
      nmtid: [''],
    });
    
    
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
   // this.loadStudent(true);
  }

  
  loadStudent(chk) {
    const data = { stSession: this.generalsmsmailForm.controls['stSession'].value?
                    this.generalsmsmailForm.controls['stSession'].value : 0,

                    stClass: this.generalsmsmailForm.controls['stClass'].value?
                  this.generalsmsmailForm.controls['stClass'].value : 0,

                  stSection: this.generalsmsmailForm.controls['stSection'].value?
                  this.generalsmsmailForm.controls['stSection'].value : 0,

                  UserId: this.UserId,

                  nmtid: this.generalsmsmailForm.controls['nmtid'].value?
                  this.generalsmsmailForm.controls['nmtid'].value : 0,
                  };
    this.stService.getStudentList(data.stClass, data.stSection, data.stSession).
          subscribe( m => {
            this.stList = m;
            this.stOrgList = m;
            this.p = 1;
            if (chk && m && m.length > 0 ) {
              this.generalsmsmailForm.controls['stSession'].setValue(m[0].stSession);
            }
          });
  }
  searchStudent() {
    if(this.generalsmsmailForm.controls['stSection'].value .length > 0 &&
    this.generalsmsmailForm.controls['stClass'].value.length === 0 ){
        this.toast.error("Please select class then section");

    } else {
      if (this.generalsmsmailForm.controls['stClass'].value && this.generalsmsmailForm.controls['stClass'].value .length > 0 ) {
        if (this.generalsmsmailForm.controls['stSection'].value &&
            this.generalsmsmailForm.controls['stSection'].value .length > 0)  {
              this.stList = this.stOrgList.filter(m => m.stClass == this.generalsmsmailForm.controls['stClass'].value
                            && m.stSection == this.generalsmsmailForm.controls['stSection'].value );
              this.orgList = this.stList;
        } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.generalsmsmailForm.controls['stClass'].value );
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
 
    onChangeTypeDDL(event) {   
    this.selectedType = event.target.options[event.target.selectedIndex].text;

  }
  

  editStudent(itm, i) {
  
    if (itm.stId > 0) {
      if (itm.is_edit == false) {
        this.stService.GetGeneralMasterSMS(this.selectedType, itm.stAdmissionNo).subscribe((response:any)=>{
        if (response) {
          itm.is_Updated = true;
          itm.Action = response;
          this.stList[i] = itm;
          this.toast.success(response);
        } else {
          itm.is_Updated = false;
          itm.Action = response;
          // alert('Message not sent');
        }
      });        
    
    }
    }
  }
  editStudentMail(itm, i) {   
    if (itm.stId > 0) {
      if (itm.is_edit == false) {
        this.stService.GetGeneralEMail(this.selectedType, itm.stAdmissionNo).subscribe((response:any)=>{
        if (response) {
          itm.is_Updated = true;
          itm.Action = response;
          this.stList[i] = itm;
          this.toast.success(response);
        } else {
          itm.is_Updated = false;
          itm.Action = response;
          // alert('Message not sent');
        }
      });        
    
    }
    }
  }
  editAllStudent(i) {   
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.stService.GetGeneralMasterSMS(this.selectedType, itm.stAdmissionNo).subscribe((response:any)=>{
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;
            // alert('Message not sent');
          }
        });
      
      }
    }
  }
  editAllStudentMail(i) {  
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.stService.GetGeneralEMail(this.selectedType, itm.stAdmissionNo).subscribe((response:any)=>{
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;
            // alert('Message not sent');
          }
        });
      
      }
    }
  }
  isAllSelected(id: number, property: boolean, event: any, data) {
    this.masterSelected = false;
    this.masterSelected = this.stList.every(function (item: any) {
      return item.isSelected === true;
    })
    if (property === false) {
      const editField = event.target.textContent;
      data.is_edit = true;
      this.stList[id] = data;
    } else {
      const editField = event.target.textContent;
      data.is_edit = false;
      this.stList[id] = data;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.stList.length; i++) {
      if (this.stList[i].isSelected) {
        this.checkedList.push(this.stList[i]);
      }
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }
  checkUncheckAll() {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected; 
    }
  }

  checkChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected;
      if (property === true){
        this.stList[i].is_edit = false ;
      } else {
        this.stList[i].is_edit = true;
      }
    }
    this.getCheckedItemList();

  }

}
