import { Component, OnInit,Inject } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService  } from '../../shared/services/registration.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {LoginService} from '../../shared/services/login.service';
import { User } from '../../shared/model/user.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-registrationfeelist',
  templateUrl: './registrationfeelist.component.html',
  styleUrls: ['./registrationfeelist.component.scss']
  
})
export class RegistrationfeelistComponent implements OnInit {
  public domain: string = null;
  studentInfo: any;
  public stRegistraionList: any = [];
  public staticList: any = {};
  public registrationfeeListForm: FormGroup;
  public schid:number;
  public txtFilter:  any = '';
  public userFilter: any ;
  public p:any;
  public message: string = null;
  constructor(private router: Router, 
             private stService: RegistrationService,  
             private registrationService:RegistrationService,
      private formBuilder: FormBuilder,private loginService:LoginService,private toast:ToastrService,@Inject(DOCUMENT) private document: any) {
        this.stService.getMasterList();
      }

  ngOnInit() {
    this.registrationService.stid = 0;
    this.schid = (<User>this.loginService.currentUserSubject.value ).schoolId;
    this.registrationService.currentMessage.subscribe(message => this.studentInfo = message)
    this.registrationfeeListForm = this.formBuilder.group({
      stClass: [''],     
      stSession: ['']     
    });
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
    this.loadRegistrationList();
   
  }
  loadRegistrationList() {      

      this.registrationService.getRegistrationList(this.registrationfeeListForm.controls['stClass'].value, this.registrationfeeListForm.controls['stSession'].value,this.schid).
            subscribe( m => this.stRegistraionList = m);           
               // this.stRegistraionList.rf_dob=moment(this.stRegistraionList.rf_dob,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
          
  }
 
  editStudent(itm) {
    if (itm.rfId > 0) {     
      if (itm.rfregamount > 0) { 
      const data: any = { 
        Mode:2,
        rf_rfid: itm.rfId,                
        rf_modeofpayment: itm.rfmode,
        rf_chqtranno: itm.rftransactionno,
        rf_regamount: itm.rfregamount, 
        schId:this.schid               
      };    
      this.stService.UpdateRegistrationAmount(data).subscribe(m => {         
        this.message = m.message;        
        this.toast.success(this.message);
      });
    }else{
      const data: any = { 
        Mode:1,
        rf_rfid: itm.rfId,                
        rf_modeofpayment: itm.rfmode,
        rf_chqtranno: itm.rftransactionno,
        rf_regamount: itm.rfregamount, 
        schId:this.schid               
      };    
      this.stService.UpdateRegistrationAmount(data).subscribe(m => {         
        // this.message = m.message;        
        // this.toast.success(m.message, "Success")
        this.message = m.message;        
        this.toast.success(this.message);
      });
    }

    }
    itm.isEditable = false;
  }

  PrintReceipt(itm) {
    this.domain = this.document.location.hostname;
    if (itm.rfId > 0) {   
      this.registrationService.RegistrationFeeReceipt(itm.rfId,this.domain).subscribe((response:any)=>{
          let pdfWindow = window.open("")
      pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(response) + "'></iframe>");
      
    });    
    }    
  }

  mail() {
    this.domain = this.document.location.hostname;
    this.registrationService.Mail(this.registrationService.stid,this.domain).subscribe((response:any)=>{
      if (response) {
        this.toast.success('e-mail has sent kindly check residential mail id.');
        // this.SMS();
      } else {
        alert('Mail not sent');
      }
    });
  }
  
  MailPrintReceipt(itm) {
    this.domain = this.document.location.hostname;
    if (itm.rfId > 0) {   
      this.registrationService.MailRegistrationFeeReceipt(itm.rfId,this.domain).subscribe((response:any)=>{
        if (response) {
          this.toast.success('e-mail has sent kindly check residential mail id.');
          // this.SMS();
        } else {
          alert('Mail not sent');
        }
      });
    }    
  }
  
}
