import { Injectable } from '@angular/core';
import { Subject, observable, Observable } from 'rxjs';
import {LoginService} from './login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})

};
const apiUrl = environment.appUrl;

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  public links = new Array<{ Menuid: number, MenuName: string, PageURL: string , ParentName: string}>();
  public menuItems: Observable<any[]>;
  private isLoggedIn = new Subject<boolean>();

  public role: string;
  constructor(private loginService: LoginService, private httpClient: HttpClient) {
      this.isLoggedIn.next(false);
  }

  public getMenu = function( ) {
    this.httpClient.get(apiUrl + '/api/user/GetMenu')
    .subscribe(menu => {
      this.links = menu;
    });

    
  
    /* this.links.push({id:1,text:"home",path:"home",parent:"A"})

    this.links.push({id:3,text:"home2",path:"home2",parent:"Admin"})
    this.links.push({id:4,text:"home3",path:"home",parent:"Admin"})
    this.links.push({id:2,text:"home1",path:"home1",parent:"B"})
    this.links.push({id:5,text:"home4",path:"home",parent:"Register"})
    this.links.push({id:6,text:"home5",path:"home",parent:"Register"}) */
    // this.menuItems=  this.httpClient.post(this.loginService.apiUrl + "api/Login/GetMenu")
  };

  public getStudentMenu = function( ) {
    this.httpClient.get(apiUrl + '/api/user/GetStudentMenu')
    .subscribe(menu => {
      this.links = menu;
    });


    /* this.links.push({id:1,text:"home",path:"home",parent:"A"})

    this.links.push({id:3,text:"home2",path:"home2",parent:"Admin"})
    this.links.push({id:4,text:"home3",path:"home",parent:"Admin"})
    this.links.push({id:2,text:"home1",path:"home1",parent:"B"})
    this.links.push({id:5,text:"home4",path:"home",parent:"Register"})
    this.links.push({id:6,text:"home5",path:"home",parent:"Register"}) */
    // this.menuItems=  this.httpClient.post(this.loginService.apiUrl + "api/Login/GetMenu")
  };

  public getNavItems = function( ) : Observable<any> {
    this.role = this.loginService.currentUserValue.roles;
    if (this.role === 'Student') {
      return this.httpClient.get(apiUrl + '/api/user/GetstudentMenu');
  } else {
    return this.httpClient.get(apiUrl + '/api/user/GetMenu');
  }
  };

  getLoginStatus() {
    return this.isLoggedIn;
  }

  updateLoginStatus(status: boolean) {
    this.isLoggedIn.next(status);
    if (status) {
      this.getMenu();
    } else if (status) {
      this.getStudentMenu();
    } else {
      this.menuItems = null;
    }

  }

  clearAllItems() {
    this.links.length = 0;
  }
}
