import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StudentService } from '../../shared/services/student.service';
import { Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-updatestudentmonthfee',
  templateUrl: './updatestudentmonthfee.component.html',
  styleUrls: ['./updatestudentmonthfee.component.scss'],
  providers: [DateFormatPipe, DatePipe]
})
export class UpdateStudentMonthFeeComponent implements OnInit {
  public stOrgList: any = [];
  public stList: any = [];
  public scMonthList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public BusStop = [];
  public BusStops = [];
  public studentListForm: FormGroup;
  public p: number = 1;
  public message: string = null;
  public IsAllChecked: boolean = false;
  masterSelected: boolean;
   public checklist: any;
  checkedList: any;
  is_edit: boolean;
  public busRoute: number = 0;
  public busStop: number = 0;
  Amount: any;
  isRouteSelected: boolean;
 
  public saveUsername: boolean;
  public feeModel = [({
    suf_mthseq: 0,
    suf_MonthName: '',
    suf_Year: 0,
    suf_btid: 0,
    suf_bsid:0,
    suf_TransFare: 0,
    IsCheked:true
  })]

  public DiscountfeeModel = ({
    suf_mthseq: 0,
    suf_MonthName: '',
    suf_Year: 0,
    suf_btid: 0,
    suf_bsid: 0,
    suf_TransFare: 0,
    IsCheked: true
  })

  public updateModel = ({
    stRollNo: 0,
    stMobileNo: '',
    stEmail: '',

  })

  public studentUpdateModel = ({
    StudentId: 0,
    SchoolId: 0,
    SessionId: 0,
    IsUpdated: false,
    UserId:0,
    UpdateStudentInfoView: ({ stRollNo: 0, stMobileNo: '', stEmail: '' }),
    UpdateStudentPrevAmountView: ({
      stprevamount: 0
    }),
    UpdateStudentMonthFeeDiscountView: [({
      suf_mthseq: 0,
      suf_MonthName: '',
      suf_Year: 0,
      suf_btid: 0,
      suf_bsid:0,
      suf_TransFare: 0
    })]

  })
 
  public StudentList = [({
    stAdmissionNo: 0,
    stClass: 0,
    stClassName: '',
    stDOB: '',
    stEmail: '',
    stFatherName: null,
    stHouse: '',
    stHouseName: '',
    stId: 0,
    stMobileNo: '',
    stMotherName: null,
    stName: '',
    stRegistrationNo: null,
    stRollNo: 0,
    stSchoolName: '',
    stSection: 0,
    stSectionName: '',
    stSession: 0,
    stSessionName: '',
    stprevamount: 0,
    isSelected: false,
    is_edit: false,
    suf_btid: 0,
    suf_bsid: 0,
    is_Updated: false

  })];
  constructor(private router: Router, private stService: StudentService,
    private formBuilder: FormBuilder, private toast: ToastrService) {
    this.stService.getMasterList();
    this.masterSelected = false;
    this.is_edit = true;
    this.isRouteSelected = false;
    let that = this;
    this.StudentList.forEach((val, indx) => {
      that.ChangeRoute(val.suf_btid, indx);
    })

   
  }
  ChangeRoute(val, indx) {
    if (val && val > 0) {
      this.BusStop[indx] = this.staticList.BusStop.filter(b => b.btid == val);
    } else {
      this.BusStop[indx] = [];
    }
  }
  UpdateFare(bsid, stval) {
    let bsval = this.staticList.BusStop.filter(b => b.value == bsid);
    if (bsval && bsval.length > 0)
      stval.stprevamount = bsval[0].fare;
  }
  SelectAll(key, val) {
    let that = this;
    if (key == 'suf_bsid') {
      for (let index = 0; index < this.StudentList.length; index++) {
        this.BusStop[index] = this.BusStops;
      }
    }

    this.StudentList.forEach(function (itm, indx) {
      itm[key] = val;
    })
    if (key == 'suf_btid' || key == 'suf_bsid') {
      this.StudentList.forEach(function (itm, indx) {
        itm["stprevamount"] = 0;
        if (key == 'suf_btid') {
          itm["suf_bsid"] = 0;

        }
        if (key == 'suf_bsid') {
          itm["suf_btid"] = that.busRoute;
        }

      });
      if (key == 'suf_btid') {
        this.BusStops = this.staticList.BusStop.filter(b => b.btid == val);
        for (let index = 0; index < that.StudentList.length; index++) {
          this.BusStop[index] = this.BusStops;
        }

      }

      if (key == 'suf_bsid' && val > 0) {
        let bsval = this.staticList.BusStop.filter(b => b.value == that.busStop);
        if (bsval && bsval.length > 0)
          this.SelectAll("stprevamount", bsval[0].fare)
      }
    }

  }
  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.StudentList.length; i++) {
      if (this.StudentList[i].isSelected)
        this.checkedList.push(this.StudentList[i]);
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }
  checkUncheckAll() {
    for (var i = 0; i < this.scMonthList.length; i++) {
      this.scMonthList[i].isSelected = this.masterSelected;
 
    }    
  }

  checkChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.StudentList.length; i++) {
      this.StudentList[i].isSelected = this.masterSelected;
      if (property==true)
        this.StudentList[i].is_edit = false ;
      else
        this.StudentList[i].is_edit = true;
    }
    this.getCheckedItemList();

  }


  isAllSelected(id: number, property: boolean, event: any, data) {
    debugger;
    this.masterSelected = false;
    this.masterSelected = this.StudentList.every(function (item: any) {
      return item.isSelected == true;
    })
    if (property == false) {
      const editField = event.target.textContent;
      data.is_edit = true;
      this.StudentList[id] = data;
    }
    else {
      const editField = event.target.textContent;
      data.is_edit = false;
      this.StudentList[id] = data;
    }
    
    this.getCheckedItemList();
  }
  ngOnInit() {   
    this.studentListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: ['']
    });
     
    this.DiscountfeeModel = ({
      suf_mthseq: 0,
      suf_MonthName: '',
      suf_Year: 0,
      suf_btid: 0,
      suf_bsid:0,
      suf_TransFare: 0,
      IsCheked: true
    })
   
    /* this.stList.push({'id': 3, 'name': 'sujit', session: '2018-19'},
                    {'id': 4, 'name': 'sujit1', session: '2018-19'},
                    {'id': 5, 'name': 'sujit2', session: '2018-19'}); */


    this.stService.masterList.subscribe(m => {
      this.staticList = m;
      console.log(this.staticList);
    })
  
   // this.loadStudent(true);
    

  }





  onFilterChange(data:any,i: number,eve: any) {
    debugger;
    if (eve == true) {
      this.DiscountfeeModel = data;
      this.DiscountfeeModel.IsCheked = true;
      this.feeModel[i] = this.DiscountfeeModel;
      
    }
    else {
      this.DiscountfeeModel = data;
      this.DiscountfeeModel.IsCheked = false;
      this.DiscountfeeModel.suf_TransFare = 0;
      this.feeModel[i] = this.DiscountfeeModel;
    }
   
  }
 
  loadStudentMonth(chk) {
    debugger
    this.feeModel = [];
    this.scMonthList = [];
    var monthname = true;
   // console.log(this.studentListForm.controls['stSession'].value);
    this.stService.getSchoolMonthYearList(0, 0, this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        debugger;
        this.scMonthList = m;
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var d = new Date();
        var monthName = months[d.getMonth()];
        for (let data of this.scMonthList) {
         
          this.feeModel.push({
            suf_mthseq: data.mn_mthseq, suf_MonthName: data.fhds_MonthName, suf_Year: data.fhds_Year,
            suf_btid: data.fhds_schid, suf_bsid: data.suf_bsid, suf_TransFare: 0.0, IsCheked: monthname== true?false:true});

          if (data.fhds_MonthName == monthName) {
            monthname = false;
          }
        }
      
       // this.feeModel.shift();
        //var foo_object // Item to remove       
        //foo_object = this.feeModel.splice(0, 1);
        //console.log(foo_object);
        //this.feeModel = this.feeModel.filter(obj => obj !== foo_object);
        //console.log(this.feeModel);
        this.stOrgList = m;
        this.p = 1;
      //  if (chk && m && m.length > 0) {
       //   this.studentListForm.controls['stSession'].setValue(m[0].stSession);
       // }
      });
  }
  loadStudent() {
   
    this.masterSelected = false;
    // this.stService.getStudentList(this.studentListForm.controls['stClass'].value,this.studentListForm.controls['stSection'].value, this.studentListForm.controls['stSession'].value).
    this.stService.GetActiveStudentList(this.studentListForm.controls['stClass'].value,this.studentListForm.controls['stSection'].value, this.studentListForm.controls['stSession'].value).
      subscribe(m => {
       
        this.stList = m;
        this.StudentList = [];
        for (let sts of this.stList) {

          this.StudentList.push({
            stAdmissionNo: sts.stAdmissionNo,
            stClass: sts.stClass,
            stClassName: sts.stClassName,
            stDOB: sts.stDOB,
            stEmail: sts.stEmail,
            stFatherName: sts.stFatherName,
            stHouse: sts.stHouse,
            stHouseName: sts.stHouseName,
            stId: sts.stId,
            stMobileNo: sts.stMobileNo,
            stMotherName: sts.stMotherName,
            stName: sts.stName,
            stRegistrationNo: sts.stRegistrationNo,
            stRollNo: sts.stRollNo,
            stSchoolName: sts.stSchoolName,
            stSection: sts.stSection,
            stSectionName: sts.stSectionName,
            stSession: sts.stSession,
            stSessionName: sts.stSessionName,
            stprevamount: sts.stprevamount,
            isSelected: this.masterSelected,
            is_edit: true,
            suf_btid:0,
            suf_bsid: 0,
            is_Updated:false
          });
        }

        // var foo_object // Item to remove       
        // foo_object = this.StudentList.splice(0, 1);
        // console.log(foo_object);
        // this.StudentList = this.StudentList.filter(obj => obj !== foo_object);

        console.log(this.StudentList);
        this.stOrgList = m;
      //  this.p = 1;
      //  if (chk && m && m.length > 0) {
      //   this.studentListForm.controls['stSession'].setValue(m[0].stSession);
      //  }
        this.loadStudentMonth(true);
      });
  }
  searchStudent() {
   
    if (this.studentListForm.controls['stSection'].value.length > 0 && this.studentListForm.controls['stClass'].value.length == 0) {
      this.toast.error("Please select class then section");

    } else {
      if (this.studentListForm.controls['stClass'].value && this.studentListForm.controls['stClass'].value.length > 0) {
        if (this.studentListForm.controls['stSection'].value &&
          this.studentListForm.controls['stSection'].value.length > 0) {
          // this.StudentList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value
          this.StudentList = this.stList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value 
          && m.stSection == this.studentListForm.controls['stSection'].value);
          this.orgList = this.stList;
        } else {
          // this.StudentList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value);
          this.StudentList = this.stList.filter(m => m.stClass == Number(this.studentListForm.controls['stClass'].value));
        }
      } else {
        //this.StudentList = this.stOrgList;
        this.StudentList = this.stList;
       
      }
    }

    let m = [];
    m = this.StudentList;
    this.StudentList = [];
    for (let sts of m) {

      this.StudentList.push({
        stAdmissionNo: sts.stAdmissionNo,
        stClass: sts.stClass,
        stClassName: sts.stClassName,
        stDOB: sts.stDOB,
        stEmail: sts.stEmail,
        stFatherName: sts.stFatherName,
        stHouse: sts.stHouse,
        stHouseName: sts.stHouseName,
        stId: sts.stId,
        stMobileNo: sts.stMobileNo,
        stMotherName: sts.stMotherName,
        stName: sts.stName,
        stRegistrationNo: sts.stRegistrationNo,
        stRollNo: sts.stRollNo,
        stSchoolName: sts.stSchoolName,
        stSection: sts.stSection,
        stSectionName: sts.stSectionName,
        stSession: sts.stSession,
        stSessionName: sts.stSessionName,
        stprevamount: 0, // sts.stprevamount,
        isSelected: this.masterSelected,
        is_edit: true,
        suf_btid: 0,
        suf_bsid: 0,
        is_Updated: false
      });
    }
  }
 
  //ShowStudentMonthFeeDiscount(itm:any) {
  
  //  this.stService.getStudentMonthFeeDiscount(String(itm.stId), itm.stClass, itm.stSession).subscribe(m => {
  //    this.message = m.message;
  //    this.toast.success(m.message, "Success")
  //    //this.open();
  //  });

  //}
  editStudent(itm,i) {
    debugger;
    if (itm.is_edit == false) {
      var fd = this.feeModel;
      this.studentUpdateModel.StudentId = itm.stId;
      this.studentUpdateModel.SchoolId = 0;
      this.studentUpdateModel.SessionId = itm.stSession;
      this.updateModel.stEmail = itm.stEmail;
      this.updateModel.stRollNo = itm.stRollNo;
      this.updateModel.stMobileNo = itm.stMobileNo;
      this.studentUpdateModel.UpdateStudentInfoView = this.updateModel;
      this.studentUpdateModel.UpdateStudentPrevAmountView.stprevamount = itm.stprevamount;
      this.studentUpdateModel.UpdateStudentMonthFeeDiscountView = [];
      for (let datas of this.feeModel) {
        if (datas.IsCheked == true) {
          this.studentUpdateModel.UpdateStudentMonthFeeDiscountView.push({
            suf_mthseq: datas.suf_mthseq, suf_MonthName: datas.suf_MonthName, suf_Year: datas.suf_Year,
            suf_btid: Number(itm.suf_btid), suf_bsid: Number(itm.suf_bsid), suf_TransFare: itm.stprevamount
          });

        }
        //else {
        //  this.studentUpdateModel.UpdateStudentMonthFeeDiscountView.push({
        //    suf_mthseq: datas.suf_mthseq, suf_MonthName: datas.suf_MonthName, suf_Year: datas.suf_Year,
        //    suf_btid: datas.suf_btid, suf_bsid: datas.suf_bsid, suf_TransFare: 0.0
        //  });
       // }
      }
      this.stService.updateStudentMonthFeeDiscount(this.studentUpdateModel).subscribe(m => {
        if (m.id != null) {
          itm.is_Updated = true;
        }
        this.StudentList[i] = itm;
        this.message = m.message;
        this.toast.success(m.message, "Success")
        //this.open();
      });
    }
    
  }

  editAllStudent() {
    for (let itm of this.StudentList) {  
      if (itm.is_edit == false) {  
        var fd = this.feeModel;
        this.studentUpdateModel.StudentId = Number(itm.stId);
        this.studentUpdateModel.SchoolId = 0;
        this.studentUpdateModel.SessionId = Number(itm.stSession);
        this.updateModel.stEmail = itm.stEmail;
        this.updateModel.stRollNo = Number(itm.stRollNo);
        this.updateModel.stMobileNo = itm.stMobileNo;
        this.studentUpdateModel.UpdateStudentInfoView = this.updateModel;       
        this.studentUpdateModel.UpdateStudentPrevAmountView.stprevamount = itm.stprevamount;
        
        this.studentUpdateModel.UpdateStudentMonthFeeDiscountView = [];
        for (let datas of this.feeModel) {          
          if (datas.IsCheked == true) {
            this.studentUpdateModel.UpdateStudentMonthFeeDiscountView.push({
              suf_mthseq: datas.suf_mthseq, suf_MonthName: datas.suf_MonthName, suf_Year: datas.suf_Year,
              suf_btid: Number(itm.suf_btid), suf_bsid: Number(itm.suf_bsid), suf_TransFare: itm.stprevamount
            });
          }        
        }
        this.stService.updateStudentMonthFeeDiscount(this.studentUpdateModel).subscribe(m => {
          var index = this.StudentList.findIndex(x => x.stId === itm.stId);
          index = index;
          if (m.id != null) {
            itm.is_Updated = true;
          }
          this.StudentList[index] = itm;
          this.message = m.message;
          this.toast.success(m.message, "Success");
        
          //this.open();
        });
      }
    }
  }
    
}
