import { Component, OnInit,Input } from '@angular/core';
import { ModalService } from '../../../_modal';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  @Input() paymentHistory: any;
  
  constructor(private modalService : ModalService) {
   }

  ngOnInit() {
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
