import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StudentAttendenceWithDetails } from '../../shared/model/StudentAttendenceWithDetails.model';
import { MasterService } from '../../shared/services/master.service';

@Component({
  selector: 'app-studentattendece',
  templateUrl: './studentattendence.component.html',
  styleUrls: ['./studentattendence.component.scss'],

})
export class StudentattendenceComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public studentAttendenceForm: FormGroup;
  public p:number =1;
  private sub: any;
  public id = 0;
  public ssid = 0;
  public sdid = 0;
  public csid = 0;
  public csdate='';
  public masterSelected: boolean;
  public checklist: any;
  public checkedList: any;
  public  is_edit: boolean;
  public attendencearray : any = [];
  public attendenceDetailList: any = [];
  public attendence: any = {};
  public IsValidClass: boolean;
  public present: boolean;
  public StudentAttendanceDetails: StudentAttendenceWithDetails;
  public attendencecount : number;
  public userclassList: any = [];
  public usersectionList: any = [];
  public sessionList: any =[];
  public empsdid = 0;
  public empcsid = 0;
  public empclsteacher = false;
  public empallsection = false;
  public checkclsteacher : number;
 
  constructor(private router: Router, private stService: StudentService,private route: ActivatedRoute,
    private formBuilder: FormBuilder, private toast: ToastrService, private masterService: MasterService) {
    debugger;
    this.stService.getMasterList();
     this.StudentAttendanceDetails = new StudentAttendenceWithDetails();
    this.masterSelected = false;
    this.IsValidClass = false;
    this.attendencearray = [];
    this.sub = this.route.params.subscribe(params => {
      debugger;
          this.id =   +params['id']; // (+) converts string 'id' to a number
          this.ssid = +params['ssid'];
          this.sdid = +params['sdid'];
          this.csid = +params['csid'];
          this.csdate =params['csdate'];     
        });
      }

  ngOnInit() {
    const dt = new Date();
   
    this.studentAttendenceForm = this.formBuilder.group({
      atd_atid: [''],
      date: [{year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()}],
      stClass: [''],
      stSection: [''],
      stSession: [''],
      stId: [''],
      stpresent: [''],

    });
    // if (this.csdate != undefined) {
      if (this.id > 0 ) {
      // debugger;
    
      this.loadclass();      
      this.studentAttendenceForm.controls['stClass'].setValue(this.sdid);
      this.loadsection();
      this.studentAttendenceForm.controls['stSection'].setValue(this.csid);
     
      this.stService.GetStudentAttendenceListDetail(this.id, this.ssid, this.sdid, this.csid).
      subscribe(fgl => {this.attendenceDetailList = fgl; this.editAttendence();})
      // this.studentAttendenceForm.controls['atd_atid'].setValue(this.id);
      // const dt = new Date(this.csdate);
      // this.studentAttendenceForm.controls['date'].setValue({ year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() });

      //  // this.studentAttendenceForm.controls['date'].setValue(this.csdate);
           
      // this.studentAttendenceForm.controls['stSession'].setValue(this.ssid);
      // this.studentAttendenceForm.controls['stClass'].setValue(this.sdid);
      // this.studentAttendenceForm.controls['stSection'].setValue(this.csid);
      // this.IsValidClass == false;
      // this.id = this.studentAttendenceForm.controls['atd_atid'].value;
      // this.ssid = this.studentAttendenceForm.controls['stSession'].value;
      // this.sdid = this.studentAttendenceForm.controls['stClass'].value;
      // this.csid = this.studentAttendenceForm.controls['stSection'].value;
      
      //   this.stService.GetStudentAttendenceListDetail(this.id, this.ssid, this.sdid, this.csid).subscribe(fgl => {
      //     debugger;
      //     this.attendenceDetailList = [];
      //     this.attendenceDetailList = fgl;
      //     this.stList = fgl;
      //     console.log(fgl);
      //     for (var i = 0; i < this.stList.length; i++) {
      //       if (this.stList[i].stpresent == true) {
      //         this.stList[i].isSelected = true;
      //       }
      //       else {
      //         this.stList[i].isSelected = false;
      //       }
      //     }
      //     if (this.attendenceDetailList!=null) {
      //       this.editAttendence();
      //     }
      //   });

     
    }
    this.stService.masterList.subscribe(m=>{this.staticList=m;})   
    this.masterService.getSessionListCurrent().subscribe(lst => { this.sessionList = lst });
    // this.masterService.GetEmployeeNo().subscribe(lst=>{
    //   this.empsdid = lst[0]['em_sdid'];
    //   this.empcsid = lst[0]['em_csid'];
    //   this.empclsteacher = lst[0]['em_clsteacher'];
    //   this.empallsection = lst[0]['em_allsection'];           
    
    // }); 
   
  }

  loadclass() {
    var classId = 0;
    var sectionId = 0;
    this.masterService.getUserWiseClassList(classId,sectionId).subscribe(lst => {this.userclassList = lst; });
  }
  loadsection() {
    var classId = 0;
    var sectionId = 0;
    var classId = Number(this.studentAttendenceForm.controls['stClass'].value);
    var sectionId = Number(this.studentAttendenceForm.controls['stSection'].value);
    this.masterService.getUserWiseSectionList(classId,sectionId).subscribe(lst => {this.usersectionList = lst; });
  }

  loadStudent() {
    
    if (this.IsValidClass == false) {

      const data = {
        stSession: this.studentAttendenceForm.controls['stSession'].value ?
          this.studentAttendenceForm.controls['stSession'].value : 0,

        stClass: this.studentAttendenceForm.controls['stClass'].value ?
          this.studentAttendenceForm.controls['stClass'].value : 0,

        stSection: this.studentAttendenceForm.controls['stSection'].value ?
          this.studentAttendenceForm.controls['stSection'].value : 0,

        date: this.studentAttendenceForm.controls['date'].value ?
          this.studentAttendenceForm.controls['date'].value : null,

      };
      this.masterSelected = false;
      // this.stService.getStudentList(data.stClass, data.stSection, data.stSession).
      this.stService.GetActiveStudentList(data.stClass, data.stSection, data.stSession).
        subscribe(m => {
          debugger;
          this.stList = m;
          this.stOrgList = m;
          console.log(this.stList);
          // this.p = 1;

        });
    }
  }
  checkclassteacher(){
   
    const chlclsdata = {
          // SchoolId: (this.loginService.currentUserSubject.value ).schoolId,
      SessionId : Number(this.studentAttendenceForm.controls['stSession'].value),  
      Sdid : Number(this.studentAttendenceForm.controls['stClass'].value),
      secid : Number(this.studentAttendenceForm.controls['stSection'].value),     
      UserId : JSON.parse(localStorage.getItem('currentUser'))["userId"],  
      clsid:0,    
    };
    this.masterService.CheckClassTeacherForOnlySession(chlclsdata.UserId,chlclsdata.SessionId,0,
      0).subscribe(lst1=>{     
      this.checkclsteacher = lst1[0]['cls_id'];        
      this.empclsteacher = lst1[0]['cls_clsteacher'];
      this.empallsection = lst1[0]['cls_allsection'];    
      if (this.checkclsteacher >0 && this.empclsteacher==true)  {  
        this.masterService.ClassTeacherForSession(chlclsdata.UserId,chlclsdata.SessionId,chlclsdata.Sdid,
          chlclsdata.secid).subscribe(lst=>{
          this.empsdid = lst[0]['cls_sdid'];
          this.empcsid = lst[0]['cls_secid'];
          //  this.empclsteacher = lst[0]['cls_clsteacher'];
          //  this.empallsection = lst[0]['cls_allsection'];    
          // this.empclsteacher=true;
          // this.empallsection=true;     
          
        });
      }
      //else{
        // this.empclsteacher=false;
        // this.empallsection=false;
        
       // this.toast.error("You do not have rights of this section");        
      //}
    });
    // if (this.empclsteacher == false) {      
    //     this.toast.error("Only Class teacher can give remarks");
    // } 
  }

  CheckStudentAttendence() {
    debugger;
    var stSession = Number(this.studentAttendenceForm.controls['stSession'].value);
    var stClass = Number(this.studentAttendenceForm.controls['stClass'].value);
    var stSection = Number(this.studentAttendenceForm.controls['stSection'].value);
    var stDate = this.studentAttendenceForm.controls['date'].value;
    // let stDate = this.studentAttendenceForm.controls['date'].value;
    
    this.checkclassteacher();

    if(stDate != null){
       // let sstDate = stDate.year + '/' + stDate.month + '/' + stDate.day;
        var sstDate = stDate.year + '/' + stDate.month + '/' + stDate.day;
    }
   
    // var sstDate = (stDate.month+"-"+stDate.day+"-"+stDate.year).toString();
    this.stService.CheckStudentAttendence(stClass, stSection, stSession, sstDate).
      subscribe(m => {      
        debugger;
        this.attendencecount = m[0]['statId'];
        if (this.attendencecount > 0) {
          this.IsValidClass = true;

          this.toast.error("Already exist attendence on this date");

        }
        else {
          this.IsValidClass = false;

          this.loadStudent();
        }
       
      });
  }



  searchStudent() {
    if (this.studentAttendenceForm.controls['stSection'].value .length > 0 && 
    this.studentAttendenceForm.controls['stClass'].value.length ==0 ){
        this.toast.error("Please select class then section");
        
    }else{
      if (this.studentAttendenceForm.controls['stClass'].value && this.studentAttendenceForm.controls['stClass'].value .length > 0 ) {
        if (this.studentAttendenceForm.controls['stSection'].value &&
            this.studentAttendenceForm.controls['stSection'].value .length > 0)  {
              this.stList = this.stOrgList.filter(m => m.stClass == this.studentAttendenceForm.controls['stClass'].value
                            && m.stSection == this.studentAttendenceForm.controls['stSection'].value );
              this.orgList = this.stList;
        } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.studentAttendenceForm.controls['stClass'].value );
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
  cancel() {
    this.router.navigate(['/studentattendencelist']);
  }

  isAllSelected(id: number, property: boolean, event: any, data) {
    this.masterSelected = false;
    this.masterSelected = this.stList.every(function (item: any) {
      return item.isSelected === true;
    })
    if (property === false) {
      const editField = event.target.textContent;
      data.is_edit = true;
      this.stList[id] = data;
    } else {
      const editField = event.target.textContent;
      data.is_edit = false;
      this.stList[id] = data;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.stList.length; i++) {
      if (this.stList[i].isSelected) {
        this.checkedList.push(this.stList[i]);
      }
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }
  checkUncheckAll() {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected; 
    }
  }

  checkChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected;
      if (property === true){
        this.stList[i].is_edit = false ;
      } else {
        this.stList[i].is_edit = true;
      }
    }
    this.getCheckedItemList();

  }

  editAttendence() {
    debugger;
    
    // console.log(this.attendencearray);
    // this.attendencearray = this.attendenceDetailList;
    // this.studentAttendenceForm.controls['atd_atid'].setValue(this.attendencearray[0].atd_atid);
    // //this.studentAttendenceForm.controls['date'].setValue(this.attendencearray[0].date);
    // this.studentAttendenceForm.controls['stSession'].setValue(this.attendencearray[0].atd_sessionid);
    // this.studentAttendenceForm.controls['stClass'].setValue(this.attendencearray[0].atd_sdid);
    // this.studentAttendenceForm.controls['stSection'].setValue(this.attendencearray[0].atd_csid);
    this.attendencearray = [];
    console.log(this.attendencearray);
    this.attendencearray = this.attendenceDetailList;
    console.log(this.attendencearray);
    this.stList = this.attendencearray;
    for (var i = 0; i < this.stList.length; i++) {
            if (this.stList[i].stpresent === true) {
              this.stList[i].isSelected = true;
            } else {
              this.stList[i].isSelected = false;
            }
    }
    this.studentAttendenceForm.controls['atd_atid'].setValue(this.attendencearray[0].atd_atid);
    this.studentAttendenceForm.controls['date'].setValue(this.attendencearray[0].date);
    const dt = new Date(this.csdate);
    this.studentAttendenceForm.controls['date'].setValue({ year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() });
   // this.studentAttendenceForm.controls['date'].setValue(this.csdate);
    this.studentAttendenceForm.controls['stSession'].setValue(this.attendencearray[0].atd_sessionid);
    this.studentAttendenceForm.controls['stClass'].setValue(this.attendencearray[0].atd_sdid);
    this.studentAttendenceForm.controls['stSection'].setValue(this.attendencearray[0].atd_csid);  
    this.checkclassteacher();   
  }
  Save() {
    debugger;
    const data = {      
      SessionId : Number(this.studentAttendenceForm.controls['stSession'].value),  
      Sdid : Number(this.studentAttendenceForm.controls['stClass'].value),
      secid : Number(this.studentAttendenceForm.controls['stSection'].value),     
    };

    this.StudentAttendanceDetails = new StudentAttendenceWithDetails();
    this.StudentAttendanceDetails.AttendenceId = Number(this.studentAttendenceForm.controls['atd_atid'].value);
    var attdate = this.studentAttendenceForm.controls['date'].value;
    this.StudentAttendanceDetails.AttendenceDate = (attdate.month+"-"+attdate.day+"-"+attdate.year).toString();
    this.StudentAttendanceDetails.Sdid = Number(this.studentAttendenceForm.controls['stClass'].value);
    this.StudentAttendanceDetails.Csid = Number(this.studentAttendenceForm.controls['stSection'].value);
    this.StudentAttendanceDetails.SessionId = Number(this.studentAttendenceForm.controls['stSession'].value);
    this.attendence.at_atid = Number(this.studentAttendenceForm.controls['atd_atid'].value) ;
    this.StudentAttendanceDetails.StudentAttendence = this.attendence;
    
    this.attendencearray = [];
    for (var i = 0; i < this.stList.length; i++) {
      // if (this.stList[i].isSelected == true) {
        this.attendencearray.push({
          atd_suid: Number(this.stList[i].stId),
          atd_present: this.stList[i].isSelected,
        });
      // }
    }
    this.StudentAttendanceDetails.StudentAttendenceDetails = this.attendencearray;
    // this.stService.SaveStudentAttendence(this.StudentAttendanceDetails).subscribe(m => {
    //   this.toast.success(m.message, "Success");

    //   this.SendSMStoAbsentStudent(i);
    //   this.SendEmailtoAbsentStudent(i);
    // });
    if (this.empclsteacher == true) {     
      if (this.empsdid == data.Sdid && this.empcsid == data.secid){
            this.stService.SaveStudentAttendence(this.StudentAttendanceDetails).subscribe(m => {
            this.toast.success(m.message, "Success");

            this.SendSMStoAbsentStudent(i);
            this.SendEmailtoAbsentStudent(i);
          });          
      }else {
        this.toast.error("Only Class teacher can take attendance");
      }               

    } else {
        if (this.empallsection == true){
              this.stService.SaveStudentAttendence(this.StudentAttendanceDetails).subscribe(m => {
              this.toast.success(m.message, "Success");

              this.SendSMStoAbsentStudent(i);
              this.SendEmailtoAbsentStudent(i);
              });

        } 
        // else {
        //   this.toast.error("Only Class teacher can take attendance");
        // }      
      }
  }
  SendSMStoAbsentStudent(i) {
    for (let itm of this.stList) {  
      if (itm.isSelected == false) {  
        this.stService.GetAbsentSMS(itm.stId).subscribe((response:any)=>{
          if (response) {
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            alert('Message not sent');
          }
        });
      }
    }
  }

  SendEmailtoAbsentStudent(i) {
    for (let itm of this.stList) {  
      if (itm.isSelected == false) {  
        if(itm.stEmail != ''){
        this.stService.GetAbsentEmail(itm.stId).subscribe((response:any)=>{
          if (response) {
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            alert('Mail not sent');
          }
        });
      }
      }
    }
  }
  // SendSMStoAbscentStudent() {    
  //   for (var i = 0; i < this.stList.length; i++) {
  //     // this.present = m[0]['statId'];
  //      if (this.stList[i].isSelected == true) {
  //       this.stService.GetAbscentSMS(this.stList[i].stId).subscribe((response: any) => {
  //                if (response) {                   
  //                  this.toast.success(response);
  //                } else {                   
  //                  alert('Message not sent');
  //                }
  //       });
  //     }
  //   }
  // }

  // SendEmailtoAbscentStudent() {   
  //   for (var i = 0; i < this.stList.length; i++) {
  //     if (this.stList[i].isSelected == true) {
  //      this.stService.GetAbscentEmail(this.stList[i].stId).subscribe((response: any) => {
  //               if (response) {                   
  //                 this.toast.success(response);
  //               } else {                   
  //                 alert('Mail not sent');
  //               }
  //      });
  //    } 
  //   }
  // }
  
 
  
}
