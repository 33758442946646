import { StudentFeeReceipt } from './student-fee-receipt.model'
import { StudentFeeReceiptDetailList } from './student-fee-receipt-detail-List.model'
export class FeeReceipt {
    Type:string;
    ReceiptDate: string;
    SchoolId: number;
    SessionId: number;
    StudentFeeReceipt: StudentFeeReceipt;
    StudentFeeReceiptDetail: Array<StudentFeeReceiptDetailList> = [];
    UserId:number;
}
