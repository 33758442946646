import { Component, OnInit,Input   } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StudentService } from '../../shared/services/student.service';
import { Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../shared/services/master.service';
import { LoginService} from '../../shared/services/login.service';

@Component({
  selector: 'app-updatestudent',
  templateUrl: './updatestudent.component.html',
  styleUrls: ['./updatestudent.component.scss'],
  providers: [DateFormatPipe, DatePipe]
})
export class UpdateStudentComponent implements OnInit {
  public userclassList: any = [];
  public usersectionList: any = [];
  public usersubjectList: any[] = [];
  public checkclassteacherList: any = [];
  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public studentListForm: FormGroup;
  public p: number = 1;
  public  HighlightRow: Number; 
  public isupdate = false;
  public message: string = null;
  
  // public empsdid: any;
  // public empcsid: any;
  // public empclsteacher: any;
  // public empallsection: any;
  // public checkclsteacher : number;


  // public sdid = 0;
  // public csid = 0;
  // public clsteacher = false;
  // public allsection = false;

     public UpdateListData = ({
       stId: 0,
     
      
      IsUpdate:false
       
     });
  public UpdateMobileList = [({
    stId: 0,


    IsUpdate: false

  })];
  public studentUpdateModel = ({
    StudentId: 0,
    SchoolId: 0,
    SessionId: 0,
    IsUpdated:false,
    UpdateStudentInfoView: ({ stRollNo: 0, stMobileNo: '', stEmail: '' }),
    UpdateStudentPrevAmountView:({
      stprevamount:0
    }),
    UpdateStudentMonthFeeDiscountView: [],
    UserId:0
  })


  public updateModel = ({
    stRollNo: 0,
    stMobileNo: '',
    stEmail: '',
  
  })

  public updateModelList = ({
    stRollNo: 0,
    stMobileNo: '',
    stEmail: '',

  })

  constructor(private router: Router, private stService: StudentService, private masterService: MasterService,
    private formBuilder: FormBuilder, private toast: ToastrService, private loginService: LoginService) {
    this.stService.getMasterList();
  }

  ngOnInit() {
    
    this.studentListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: ['']
    });
    this.stService.masterList.subscribe(m => { this.staticList = m; })    
  }

  checkclassteacher(){
    const chlclsdata = {
      // SchoolId: (this.loginService.currentUserSubject.value ).schoolId,
      SessionId : Number(this.studentListForm.controls['stSession'].value),  
      Sdid : Number(this.studentListForm.controls['stClass'].value),
      secid : Number(this.studentListForm.controls['stSection'].value),     
      UserId : JSON.parse(localStorage.getItem('currentUser'))["userId"],  
      clsid:0,    
    };
     
    this.masterService.CheckClassTeacherForOnlySession(chlclsdata.UserId,chlclsdata.SessionId,0,
      0).subscribe(lst1=>{this.checkclassteacherList = lst1;   
                
      // this.checkclsteacher = lst1[0]['cls_id'];        
      // this.empclsteacher = lst1[0]['cls_clsteacher'];
      // this.empallsection = lst1[0]['cls_allsection']; 

     
      var checkclsteacher = lst1[0]['cls_id'];        
      var empclsteacher = lst1[0]['cls_clsteacher'];
      var empallsection = lst1[0]['cls_allsection']; 

      // if (this.checkclsteacher >0 && this.empclsteacher==true)  { 
        if (checkclsteacher > 0 && empclsteacher==true)  { 
        this.masterService.ClassTeacherForSession(chlclsdata.UserId,chlclsdata.SessionId,0,
          0).subscribe(lst=>{
          var empsdid = lst[0]['cls_sdid'];
          var empcsid = lst[0]['cls_secid'];               
           
        });
      }
     
    });
  }

 
  loadStudent() {
    // this.checkclassteacher();
   
    this.stService.GetActiveStudentList(this.studentListForm.controls['stClass'].value, 
    this.studentListForm.controls['stSection'].value, this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
       
      });
     
  }

  loadclass() {
    this.masterService.getUserWiseClassList().subscribe(lst => {this.userclassList = lst; });
  }
  loadsection() {
    this.masterService.getUserWiseSectionList(this.studentListForm.controls['stClass'].value,0).subscribe(lst => {this.usersectionList = lst; });
  }

 
 

  searchStudent() {
    if (this.studentListForm.controls['stSection'].value.length > 0 && this.studentListForm.controls['stClass'].value.length == 0) {
      this.toast.error("Please select class then section");

    } else {
      if (this.studentListForm.controls['stClass'].value && this.studentListForm.controls['stClass'].value.length > 0) {
        if (this.studentListForm.controls['stSection'].value &&
          this.studentListForm.controls['stSection'].value.length > 0) {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value
            && m.stSection == this.studentListForm.controls['stSection'].value);
          this.orgList = this.stList;
        } else {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value);
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
  

 
 
  editStudent(itm, index) {
    const data = {      
      SessionId : Number(this.studentListForm.controls['stSession'].value),  
      Sdid : Number(this.studentListForm.controls['stClass'].value),
      secid : Number(this.studentListForm.controls['stSection'].value),         
      UserId : JSON.parse(localStorage.getItem('currentUser'))["userId"],  
      clsid:0,    
    };
      debugger;   

      this.masterService.CheckClassTeacherForOnlySession(data.UserId,data.SessionId,0,
        0).subscribe(lst1=>{this.checkclassteacherList = lst1;   
               
        var checkclsteacher = lst1[0]['cls_id'];        
        var empclsteacher = lst1[0]['cls_clsteacher'];
        var empallsection = lst1[0]['cls_allsection']; 
  
        // if (this.checkclsteacher >0 && this.empclsteacher==true)  { 
          if (checkclsteacher > 0 && empclsteacher==true)  { 
          this.masterService.ClassTeacherForSession(data.UserId,data.SessionId,0,
            0).subscribe(lst=>{
           var empsdid = lst[0]['cls_sdid'];
            var empcsid = lst[0]['cls_secid'];               
             
            this.studentUpdateModel.StudentId = itm.stId;
            this.studentUpdateModel.SchoolId = (this.loginService.currentUserSubject.value ).schoolId;
            this.studentUpdateModel.SessionId = itm.stSession;
            this.updateModel.stEmail = itm.stEmail;
            this.updateModel.stRollNo = itm.stRollNo;
            this.updateModel.stMobileNo = itm.stMobileNo;
            this.studentUpdateModel.UpdateStudentInfoView = this.updateModel; 
                
        
                  if (empclsteacher == true) {     
                    if (empsdid == data.Sdid && empcsid == data.secid){
                      if (itm.stId > 0) {
                        this.stService.updateStudentReg(this.studentUpdateModel).subscribe(m => {
                  
                          this.studentUpdateModel.IsUpdated = true;
                          this.message = m.message;
                          this.HighlightRow = index;
                          this.toast.success(m.message, "Success")
                        });
                        itm.isEditable = false;
                      }
                    }else {
                      this.toast.error("Only Class teacher can update");
                    }               
              
                  } else {
                      if (empallsection == true){
                        if (itm.stId > 0) {
                          this.stService.updateStudentReg(this.studentUpdateModel).subscribe(m => {
                    
                            this.studentUpdateModel.IsUpdated = true;
                            this.message = m.message;
                            this.HighlightRow = index;
                            this.toast.success(m.message, "Success")
                          });
                          itm.isEditable = false;
                        }
              
                      } 
                     
                    } 

          });
        }
       
      });   
  }
}
