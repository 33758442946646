import { Component, OnInit, Inject, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { userattendence  } from '../../shared/model/index';
import { userdocview  } from '../../shared/model/index';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from '../../shared/services/master.service';
import { StudentService } from '../../shared/services/student.service';
import { LoginService } from '../../shared/services/login.service';
import { DOCUMENT } from '@angular/common';
// import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-employeeattendence',
  templateUrl: './employeeattendence.component.html',
  styleUrls: ['./employeeattendence.component.scss']
})
export class EmployeeattendenceComponent  implements OnInit {
 public usersList: any[] = []; 
 public employeeAttendenceForm: FormGroup;
 public stList: any =[];
 public employeeattendenceDetailList: any[] = [];
 public id = 0;
 public usid = 0;  
  public csdate='';
 public schoolId: number;
 public wId: number;
//  private dateFormatPipe: DateFormatPipe,
 private sub: any;
 public domain = '';
 public attendencearray : any = [];
 public attendencecount : number;
 public IsValid: boolean;
 
 public us_photo: string;
  public us_logoutphoto: string;
  public lat;
  public lng;
  public Position: any;
  public PositionError: string;
  public imgList: any = [];
  public percentDone = 0;
  public pdfSrc: any ;
  public imgSrc: any ;
  public errorMsg: string = null;
  public folder: string = null;
  fileSize;
  file;
  public downloadData: any = null;
  public fileName: string = null;
  isBtnUpload: boolean = false;  
  @ViewChildren('inputFile') inputFiles: QueryList<any>; 
  @ViewChild('content') content: ElementRef;
  constructor(private modalService: NgbModal,private formBuilder: FormBuilder, private route: ActivatedRoute,private router: Router, public loginService: LoginService,
    private attendenceService: MasterService, private stService: StudentService,public http:HttpClient, @Inject(DOCUMENT) private document: any, 
    private toast: ToastrService) {
      this.sub = this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
        this.usid = +params['usid'];          
        this.csdate =params['csdate'];    
      });
  }

  ngOnInit() {
    this.attendenceService.getSchoolUsersList().subscribe(ust => { this.usersList = ust;
      const dt = new Date();
      this.employeeAttendenceForm.controls['AttendenceDate'].setValue({year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()});
      this.employeeAttendenceForm.controls['us_attime'].setValue(dt.getHours()+":"+dt.getMinutes()+":"+dt.getSeconds());
      this.employeeAttendenceForm.controls['us_logouttime'].setValue(dt.getHours()+":"+dt.getMinutes()+":"+dt.getSeconds());
    }); 
    
    if(this.id > 0){
    this.stService.getUserAttendence(this.usid, this.csdate).subscribe(wf => {this.employeeattendenceDetailList = wf; this.editemployeeattendence(); } )
    }
    // const user = JSON.stringify(localStorage.getItem("currentUser"));
    this.schoolId = this.loginService.currentUserSubject.value.schoolId;
    this.employeeAttendenceForm = this.formBuilder.group({
      AttendenceId: [''],
      AttendenceDate: ['', [Validators.required]],
      SchoolId: ['', [Validators.required]],
      UserId: ['', [Validators.required]],
      us_attime: ['', [Validators.required]],
      us_present: [true, [Validators.required]],    
      us_Description: [''],  
      us_Latitude: [''],      
      us_Longitude: [''],
      us_Address: [''],
      us_logouttime: [''],
      us_logoutDescription: [''],
      us_logoutLatitude: [''],
      us_logoutLongitude: [''],
      us_logoutAddress: [''],
      us_photo: [''],
      us_logoutphoto: [''],      
    });
  }

  editemployeeattendence(){
    console.log(this.attendencearray);
    this.attendencearray = this.employeeattendenceDetailList;
    this.employeeAttendenceForm.controls['AttendenceId'].setValue(this.attendencearray[0].us_atid);
    this.wId = this.attendencearray[0].us_atid;    
    // this.employeeAttendenceForm.controls['AttendenceDate'].setValue(this.dateFormatPipe.transform(this.attendencearray[0].us_atdt,'DD/MM/YYYY'));    
    this.employeeAttendenceForm.controls['AttendenceDate'].setValue(this.attendencearray[0].date);
    const dt = new Date(this.csdate);
    this.employeeAttendenceForm.controls['AttendenceDate'].setValue({ year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() });
   
  //   this.employeeAttendenceForm.controls['stUser'].setValue(this.attendencearray[0].us_usid);
    this.employeeAttendenceForm.controls['UserId'].setValue(this.attendencearray[0].us_usid);    
    this.employeeAttendenceForm.controls['us_attime'].setValue(this.attendencearray[0].us_attime);
    this.employeeAttendenceForm.controls['us_present'].setValue(this.attendencearray[0].us_present);
    this.employeeAttendenceForm.controls['us_Description'].setValue(this.attendencearray[0].us_Description);
    this.employeeAttendenceForm.controls['us_Latitude'].setValue(this.attendencearray[0].us_Latitude);
    this.employeeAttendenceForm.controls['us_Longitude'].setValue(this.attendencearray[0].us_Longitude);
    this.employeeAttendenceForm.controls['us_Address'].setValue(this.attendencearray[0].us_Address);
    this.employeeAttendenceForm.controls['us_logouttime'].setValue(this.attendencearray[0].us_logouttime);
    this.employeeAttendenceForm.controls['us_logoutDescription'].setValue(this.attendencearray[0].us_logoutDescription);
    this.employeeAttendenceForm.controls['us_logoutLatitude'].setValue(this.attendencearray[0].us_logoutLatitude);
    this.employeeAttendenceForm.controls['us_logoutLongitude'].setValue(this.attendencearray[0].us_logoutLongitude);
    this.employeeAttendenceForm.controls['us_logoutAddress'].setValue(this.attendencearray[0].us_logoutAddress);   
    
    this.employeeAttendenceForm.controls['us_photo'].setValue(this.attendencearray[0].us_photo);
    this.employeeAttendenceForm.controls['us_logoutphoto'].setValue(this.attendencearray[0].us_logoutphoto);   
    // this.us_photo= environment.appUrl + this.attendencearray[0].us_photo;
    // this.us_logoutphoto = environment.appUrl + this.attendencearray[0].us_logoutphoto;

    this.imgList[0] = this.attendencearray[0]['us_photo'];
    this.imgList[1] = this.attendencearray[0]['us_logoutphoto'];
  }
  
  cancel() {
    this.router.navigate(['/employeeattendencelist']);
  }

  onChangeSelect($event){
    this.usid = $event.target.value; 
    var stDate = this.employeeAttendenceForm.controls['AttendenceDate'].value;    
    if(stDate != null){       
        var sstDate = stDate.year + '/' + stDate.month + '/' + stDate.day;
    }
    this.stService.CheckUserAttendence(this.usid, sstDate).
      subscribe(m => {      
        debugger;
        this.attendencecount = m[0]['uatdid'];
        if (this.attendencecount > 0) {
          this.IsValid = true;
          this.toast.error("Already exist attendence on this date");
        }
        else {
          this.IsValid = false;          
        }       
      });    
  } 
 
  onSubmit() {    
        let attendenceData = {}
       
        const data: userattendence = {
          AttendenceId: this.employeeAttendenceForm.controls['AttendenceId'].value,
          
          AttendenceDate: (this.employeeAttendenceForm.controls['AttendenceDate'].value?
          this.employeeAttendenceForm.controls['AttendenceDate'].value.year+'-'+
          this.employeeAttendenceForm.controls['AttendenceDate'].value.month+'-'+
          this.employeeAttendenceForm.controls['AttendenceDate'].value.day:null),

          SchoolId: this.schoolId,
          UserId: this.employeeAttendenceForm.controls['UserId'].value,
          // UserId: this.loginService.currentUserSubject.value.userId
          us_attime: this.employeeAttendenceForm.controls['us_attime'].value,
          us_present: this.employeeAttendenceForm.controls['us_present'].value,    
          us_Description: this.employeeAttendenceForm.controls['us_Description'].value,
          us_Latitude: this.employeeAttendenceForm.controls['us_Latitude'].value,   
          us_Longitude: this.employeeAttendenceForm.controls['us_Longitude'].value,
          us_Address: this.employeeAttendenceForm.controls['us_Address'].value,
          us_logouttime: this.employeeAttendenceForm.controls['us_logouttime'].value,
          us_logoutDescription: this.employeeAttendenceForm.controls['us_logoutDescription'].value,
          us_logoutLatitude: this.employeeAttendenceForm.controls['us_logoutLatitude'].value,
          us_logoutLongitude:this.employeeAttendenceForm.controls['us_logoutLongitude'].value,
          us_logoutAddress: this.employeeAttendenceForm.controls['us_logoutAddress'].value       
                      
        };
         attendenceData = data;      

        this.stService.manageUserattendence(attendenceData).subscribe(res => {          
          if (res) {        
              this.wId = res;  

              //  dataDoc.AttendenceId=res;       
              //  this.updatePhoto(dataDoc);            
          this.toast.success(res.message, "Your Attendence has successfully done.");          ;
        } else {         
          this.toast.success(res.message, "Not done");
      }
    });
  }
  UploadFiles() {
    const dataDoc = {
      // AttendenceId: this.employeeAttendenceForm.controls['AttendenceId'].value,
      AttendenceId: this.wId,
      
      AttendenceDate: (this.employeeAttendenceForm.controls['AttendenceDate'].value?
      this.employeeAttendenceForm.controls['AttendenceDate'].value.year+'-'+
      this.employeeAttendenceForm.controls['AttendenceDate'].value.month+'-'+
      this.employeeAttendenceForm.controls['AttendenceDate'].value.day:null),

      SchoolId: this.schoolId,
      UserId: this.employeeAttendenceForm.controls['UserId'].value
    };
    
    this.folder = this.schoolId.toString() + '/' + this.employeeAttendenceForm.controls['UserId'].value+ '/' + this.wId;
      const filelist: any = [];
      this.inputFiles.forEach((item, index) => {
          if (item.nativeElement.files.length > 0) {
            switch (index) {
              case 0:
                   // filelist.push({key:"file"+ index, index: index, category: 'us_photo', 
                   filelist.push({key:"file"+ index, index: index,
                  type: item.nativeElement.files[0].type, folder: this.folder });
                  break;
              case 1:
                   // filelist.push({key:"file"+ index,index: index, category: 'us_logoutphoto', 
                   filelist.push({key:"file"+ index,index: index,
                  type: item.nativeElement.files[0].type, folder: this.folder });
                  break;              
            }
          }
      });
      console.log(JSON.stringify(filelist));
      const formData = new FormData();
      this.inputFiles.forEach((f,indx) =>{ 
        if(f.nativeElement.files.length > 0)
            formData.append('file'+indx, f.nativeElement.files[0] )
      });
    debugger;
      
      formData.append('FileJson', JSON.stringify(filelist));      
      // formData.append('AttendenceDate', dataDoc.AttendenceDate.toString());
      formData.append('SchoolId', dataDoc.SchoolId.toString());
      formData.append('AttendenceId', dataDoc.AttendenceId.toString());
      formData.append('UserId', dataDoc.UserId.toString());     
  
      console.log('FileJson');

      this.http.post(environment.appUrl + '/api/User/SaveUserDoc', formData, {reportProgress: true,
        observe: 'events'})
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.percentDone = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {          
            alert('Files uploaded Sucessfully.');
            return true;
          }
      });          
    }

    preview(index) {

      const file = this.inputFiles.toArray()[index].nativeElement.files[0];
      this.imgSrc = null;
      this.pdfSrc = null;
      this.errorMsg = null;
        if (typeof (FileReader) !== 'undefined') {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            if (file.type === 'application/pdf') {
              this.pdfSrc = e.target.result;
            } else if (file.type.match(/image\/*/) != null) {
              this.imgSrc = e.target.result;
            } else {
              this.errorMsg = 'File Type Not Supported' ;
            }
            this.open();
          };
          if (file.type === 'application/pdf') {
              reader.readAsArrayBuffer(file);
          } else {
            reader.readAsDataURL(file);
          }
        }
    }
    uploadPreview(index) {
    const dataUrl = this.imgList[index];
    if (dataUrl && dataUrl.length > 0) {
      const ext = dataUrl.split('.').pop();
      this.fileName = dataUrl.split('/').pop();
      if (ext == 'pdf') {     
        this.imgSrc = null;
        this.http.get(environment.appUrl + '/api/Registration/GetFile?fileName=' + dataUrl, { responseType : 'arraybuffer' as 'json'})
           .subscribe((res: any ) => {
            const ur = URL.createObjectURL(new Blob([res], {
              type: 'application/pdf'
            }));
            this.pdfSrc = ur; 
            this.imgSrc = null;
            this.downloadData = ur;        
          });
      } else {
        this.pdfSrc = null;
        this.imgSrc = environment.appUrl + dataUrl;
        this.http.get(environment.appUrl + '/api/Registration/GetFile?fileName=' + dataUrl, { responseType : 'arraybuffer' as 'json'})
        .subscribe((res: any ) => {
         const ur = URL.createObjectURL(new Blob([res],
          {type: 'image/'+ this.fileName.split('.').pop()}));
         this.downloadData = ur;     
       });
      }
      this.open();
    }
  }
  open() {
    this.modalService.open(this.content).result.then((result) => {
        
    }, (reason) => {
       
    });
}
  DownloadFile() {
    if(this.downloadData){
       const a = document.createElement('a');
        a.href = this.downloadData;
        a.download = this.fileName;
        // start download
        a.click(); 
        
    }
  }
 
  fileSelected($event) {
   let file = $event.target.files[0];
   this.file = file;
 
   // if (file > 0) {
   //  let fileSize = 0;
   if (file.size > 129000) {
     this.toast.success('Please Upload a file upto 125 KB.');
     alert('please select a file less than 125 kb');
      this.isBtnUpload = false;
      
   } else {
       this.isBtnUpload = true;
   }
 // }
 }
  
}
