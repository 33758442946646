import { Component, OnInit, Input  } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StudentService } from '../../shared/services/student.service';
import { Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-updateprevamt',
  templateUrl: './updateprevamt.component.html',
  styleUrls: ['./updateprevamt.component.scss'],
  providers: [DateFormatPipe, DatePipe]
})
export class UpdatePrevAmtComponent implements OnInit {
  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public studentListForm: FormGroup;
  public p: number = 1;
  public  HighlightRow: Number; 
  public isupdate = false;
  public message: string = null;

     public UpdateListData = ({
       stId: 0,
     
      
      IsUpdate:false
       
     });
  public UpdateList = [({
    stId: 0,


    IsUpdate: false

  })];
  public studentUpdateModel = ({
    StudentId: 0,
    SchoolId: 0,
    SessionId: 0,
    IsUpdated:false,
    UpdateStudentInfoView: ({ stRollNo: 0, stMobileNo: '', stEmail: '' }),
    UpdateStudentPrevAmountView:({
      stprevamount:0
    }),
    UpdateStudentMonthFeeDiscountView: [],
    UserId:0
  })


  public updateModel = ({
    stRollNo: 0,
    stMobileNo: '',
    stEmail: '',
  
  })

  public updateModelList = ({
    stRollNo: 0,
    stMobileNo: '',
    stEmail: '',

  })

  constructor(private router: Router, private stService: StudentService,
    private formBuilder: FormBuilder, private toast: ToastrService) {
    this.stService.getMasterList();
  }

  ngOnInit() {
    this.studentListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: ['']
    });

    this.stService.masterList.subscribe(m => { this.staticList = m; })
    this.loadStudent(true);
  }

  // updatePrevList(id: number, property: string, event: any, item) {
  //   const editField = event.target.textContent;
  //   item.stprevamount = editField;
  //   this.stList[id][property] = item;
  // }
  // changePrevValue(id: number, property: string, event: any) {
  //   debugger;
  //   this.editField = event.target.textContent;
  //   this.stList = event.target.textContent;
  // }
 
  loadStudent(chk) {
    this.stService.GetActiveStudentList(0, 0, this.studentListForm.controls['stSession'].value).
   // this.stService.getStudentList(0, 0, this.studentListForm.controls['stSession'].value).
      subscribe(m => {
        
        this.stList = m;
        this.stOrgList = m;
        this.p = 1;
        if (chk && m && m.length > 0) {
          this.studentListForm.controls['stSession'].setValue(m[0].stSession);
        }
      });
  }
  searchStudent() {
    if (this.studentListForm.controls['stSection'].value.length > 0 && this.studentListForm.controls['stClass'].value.length == 0) {
      this.toast.error("Please select class then section");

    } else {
      if (this.studentListForm.controls['stClass'].value && this.studentListForm.controls['stClass'].value.length > 0) {
        if (this.studentListForm.controls['stSection'].value &&
          this.studentListForm.controls['stSection'].value.length > 0) {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value
            && m.stSection == this.studentListForm.controls['stSection'].value);
          this.orgList = this.stList;
        } else {
          this.stList = this.stOrgList.filter(m => m.stClass == this.studentListForm.controls['stClass'].value);
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
  // addStudent() {
  //   this.router.navigate(['/student']);
  // }
 
  editStudent(itm, index) {    
    debugger;
    this.studentUpdateModel.StudentId = itm.stId;
    this.studentUpdateModel.SchoolId = itm.stClass;
    this.studentUpdateModel.SessionId = itm.stSession;
    this.updateModel.stEmail = itm.stEmail;
    this.updateModel.stRollNo = itm.stRollNo;
    this.updateModel.stMobileNo = itm.stMobileNo;
    this.studentUpdateModel.UpdateStudentInfoView = this.updateModel;
    
    this.studentUpdateModel.UpdateStudentPrevAmountView.stprevamount = itm.stprevamount;

    // if (itm.stId > 0) {
    //   this.stService.updateStudentReg(this.studentUpdateModel).subscribe(m => {

    //     this.studentUpdateModel.IsUpdated = true;
    //     this.message = m.message;
    //     this.HighlightRow = index;
       

    //     this.toast.success(m.message, "Success")
    //     //this.open();
    //   });
    // }
    if ((itm.stprevamount > 0) || (itm.stprevamount === "0")) {
      this.stService.updateStudentPrevAmountReg(this.studentUpdateModel).subscribe(m => {       
        this.toast.success(m.message, "Success")
      });
    }
    itm.isEditable = false;
  }
    
}
