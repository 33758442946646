
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { first } from 'rxjs/operators';
import { User } from '../shared/model/user.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'ramjasnurserylogin.component.html'
})
export class RamjasNurseryLoginComponent implements OnInit {
    ramjasnurseryloginForm: FormGroup;
    showError: Boolean = false;
    errorMsg: string = null;
    returnUrl: string;
    returnUrlregistration: string;    
    public role: string;
    user: string = null;
    pass: string = null;
    constructor(public router: Router, private formBuilder: FormBuilder,
        private loginService: LoginService, private route: ActivatedRoute,private toast:ToastrService
    ) { }

    ngOnInit() {
        this.ramjasnurseryloginForm = this.formBuilder.group({           
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboardregistration';
    }
    onSubmit() {
        if (this.ramjasnurseryloginForm.invalid) {
            return;
        }
            this.user ="ramjasonlinenurseryregistration"
            this.pass = "110022"

      //  this.loginService.login(this.ramjasnurseryloginForm.controls.email.value, this.ramjasnurseryloginForm.controls.password.value)
        this.loginService.login(this.user, this.pass)
        .pipe(first())
            .subscribe(
                (data: User) => {
                    if (data.access_token) {
                        localStorage.setItem('isLoggedin', 'true');
                        localStorage.setItem('authToken', data.access_token);

                        this.role = this.loginService.currentUserValue.roles;
                        if (this.role === 'OnlineNursery') {
                            this.router.navigate(['/nurseryonlineregistration']);
                        }

                    } else {
                        this.toast.error('System is under maintenance. Kindly check after 30 minutes',"Error");
                    }
                },
                error => {
                    this.toast.error('System is under maintenance. Kindly check after 30 minutes',"Error");
                });
    }
}
