import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-pendingstudentlist',
  templateUrl: './pendingstudentlist.component.html',
  styleUrls: ['./pendingstudentlist.component.scss'],
  
})
export class PendingStudentlistComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public UserId:number;
  public message: string = null;
  public pendingstudentListForm: FormGroup;
  public p:number =1;
  masterSelected: boolean;
  checkedList: any;
  public IsAllChecked: boolean = false;
  constructor(private router: Router, private stService: StudentService,
      private formBuilder: FormBuilder,private toast:ToastrService) {
        this.stService.getMasterList();
        this.UserId = JSON.parse(localStorage.getItem('currentUser'))["userId"];
      }

  ngOnInit() {
    this.pendingstudentListForm = this.formBuilder.group({
      stClass: [''],
      stSection: [''],
      stSession: ['']
    });
    
    
    this.stService.masterList.subscribe(m=>{this.staticList=m;})
   // this.loadStudent(true);
  }

  loadStudent(chk) {
    const data = { stSession: this.pendingstudentListForm.controls['stSession'].value?
                    this.pendingstudentListForm.controls['stSession'].value : 0,

                    stClass: this.pendingstudentListForm.controls['stClass'].value?
                  this.pendingstudentListForm.controls['stClass'].value : 0,

                  stSection: this.pendingstudentListForm.controls['stSection'].value?
                  this.pendingstudentListForm.controls['stSection'].value : 0,

                  UserId: this.UserId

                  };
    this.stService.getpendingStudentList(data.stClass, data.stSection, data.stSession, data.UserId).
          subscribe( m => {
            this.stList = m;
            this.stOrgList = m;
            this.p = 1;
            if (chk && m && m.length > 0 ) {
              this.pendingstudentListForm.controls['stSession'].setValue(m[0].stSession);
            }
          });
  }
  searchStudent() {
    if(this.pendingstudentListForm.controls['stSection'].value .length > 0 &&
    this.pendingstudentListForm.controls['stClass'].value.length === 0 ){
        this.toast.error("Please select class then section");

    } else {
      if (this.pendingstudentListForm.controls['stClass'].value && this.pendingstudentListForm.controls['stClass'].value .length > 0 ) {
        if (this.pendingstudentListForm.controls['stSection'].value &&
            this.pendingstudentListForm.controls['stSection'].value .length > 0)  {
              this.stList = this.stOrgList.filter(m => m.stClass == this.pendingstudentListForm.controls['stClass'].value
                            && m.stSection == this.pendingstudentListForm.controls['stSection'].value );
              this.orgList = this.stList;
        } else {
              this.stList = this.stOrgList.filter(m => m.stClass == this.pendingstudentListForm.controls['stClass'].value );
        }
      } else {
        this.stList = this.stOrgList;
      }
    }
  }
  // addStudent() {
  //   this.router.navigate(['/student']);
  // }
  editStudent(itm, i) {
    if (itm.stId > 0) {
      if (itm.is_edit == false) {
        this.stService.GetPendingSMS(itm.stId, itm.TotalAmount).subscribe((response:any)=>{
        if (response) {
          itm.is_Updated = true;
          itm.Action = response;
          this.stList[i] = itm;
          this.toast.success(response);
        } else {
          itm.is_Updated = false;
          itm.Action = response;
          // alert('Message not sent');
        }
      });
        
      // this.stService.GetPendingSMS(itm.stId, itm.TotalAmount).subscribe(m => {
      //   if (m.id != null) {
      //     itm.is_Updated = true;
      //   }
      //   this.stList[i] = itm;
      //   this.message = m.message;
      //   this.toast.success(m.message, "Success")
      // });

       // this.router.navigate(['/student', {id:itm.stId,ssid:this.pendingstudentListForm.controls['stSession'].value}]);
      // this.stService.GetPendingSMS(itm.stId, itm.TotalAmount).subscribe((response:any)=>{
      //   if (response) {
      //     this.toast.success(response);
      //   } else {
      //     alert('Message not sent');
      //   }
      // });
    }
    }
  }
  editStudentMail(itm, i) {
     if (itm.stId > 0) {
      if (itm.is_edit == false) {
        this.stService.GetPendingEmail(itm.stId, itm.TotalAmount).subscribe((response:any)=>{
        if (response) {
          itm.is_Updated = true;
          itm.Action = response;
          this.stList[i] = itm;
          this.toast.success(response);
        } else {
          itm.is_Updated = false;
          itm.Action = response;
          // alert('Message not sent');
        }
      });        
    
    }
    }
  }
  editAllStudent(i) {
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.stService.GetPendingSMS(itm.stId, itm.TotalAmount).subscribe((response:any)=>{
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;
            // alert('Message not sent');
          }
        });

        // this.stService.GetPendingSMS(itm.stId, itm.TotalAmount).subscribe(m => {
        //   var index = this.stList.findIndex(x => x.stId === itm.stId);
        //   index = index;
        //   if (m.id != null) {
        //     itm.is_Updated = true;
        //   }
        //   this.stList[index] = itm;
        //   this.message = m.message;
        //   this.toast.success(m.message, "Success");
        // });
      }
    }
  }
  editAllStudentMail(i) {
    for (let itm of this.stList) {  
      if (itm.is_edit == false) {  
        this.stService.GetPendingEmail(itm.stId, itm.TotalAmount).subscribe((response:any)=>{
          if (response) {
            itm.is_Updated = true;
            itm.Action = response;
            this.stList[i] = itm;
            this.toast.success(response);
          } else {
            itm.is_Updated = false;
            itm.Action = response;
            // alert('Message not sent');
          }
        });
      
      }
    }
  }
  isAllSelected(id: number, property: boolean, event: any, data) {
    this.masterSelected = false;
    this.masterSelected = this.stList.every(function (item: any) {
      return item.isSelected === true;
    })
    if (property === false) {
      const editField = event.target.textContent;
      data.is_edit = true;
      this.stList[id] = data;
    } else {
      const editField = event.target.textContent;
      data.is_edit = false;
      this.stList[id] = data;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.stList.length; i++) {
      if (this.stList[i].isSelected) {
        this.checkedList.push(this.stList[i]);
      }
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }
  checkUncheckAll() {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected; 
    }
  }

  checkChekedBoxAll(property: boolean) {
    for (var i = 0; i < this.stList.length; i++) {
      this.stList[i].isSelected = this.masterSelected;
      if (property === true){
        this.stList[i].is_edit = false ;
      } else {
        this.stList[i].is_edit = true;
      }
    }
    this.getCheckedItemList();

  }

}
